import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, extractErrors, notifySuccess } from '../../Utility'
import { cloneDeep } from 'lodash'
import * as actions from '../../../../actions'
import getText from '../../i18n/labels'

// FORM
import { BwmCheckbox } from '../BwmCheckboxB5'
import { BwmInput } from '../BwmInputB5'
import { BwmSelect } from '../BwmSelectB5'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'
import ErrorListAlert from '../ErrorListAlert'

// COMPONENTS
import { PopupError } from '../../popups/PopupError'
import { PopupSuccess } from '../../popups/PopupSuccess'
import { PageSpinner } from '../../spinner/PageSpinner'
import { InfoBox } from '../../InfoBox'
import { PopupAlert } from '../../popups/PopupAlert'

const onlyNumbers = new RegExp('^d+$')

export function FormPecParameters(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const coraSubjectSid = props.coraSubjectSid
  const subjectFiscalCode = props.subjectFiscalCode
  const user = useSelector((state) => state.auth.customer.user)

  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [initialValues, setInitialValues] = useState({ needsToAccept: false })

  const [mailPresets, setMailPresets] = useState([])
  const [comureiSubjectPec, setComureiSubjectPec] = useState(null)
  const [loading, setLoading] = useState(false)
  const [needsTest, setNeedsTest] = useState(true)

  useEffect(() => {
    actions.getMailPresets().then((res) => setMailPresets(res))
    if (subjectFiscalCode) {
      actions.getPecByFiscalCode(subjectFiscalCode).then((res) => setComureiSubjectPec(res))
    }
  }, [])

  useEffect(() => {
    const form = cloneDeep(initialValues)
    form.emailAddress = coraSubjectSid?.pecEmail || ''
    form.password = coraSubjectSid?.pecEmail || ''
    form.inFolder = coraSubjectSid?.incomingMailFolder || 'INBOX'
    form.contactEmail = coraSubjectSid?.contactEmail || user.email || ''
    form.recipientEmail = coraSubjectSid?.recipientEmail || 'SID1@PCERT.AGENZIAENTRATE.IT'
    form.outgoingMailHost = coraSubjectSid?.outgoingMailHost || ''
    form.outgoingMailPort = coraSubjectSid?.outgoingMailPort || ''
    form.incomingMailHost = coraSubjectSid?.incomingMailHost || ''
    form.incomingMailPort = coraSubjectSid?.incomingMailPort || ''
    form.automaticElaborationEnabled =
      coraSubjectSid?.automaticElaborationEnabled === false ? false : true
    setInitialValues(form)
  }, [coraSubjectSid])

  useEffect(() => {
    if (
      mailPresets.length > 0 &&
      comureiSubjectPec &&
      comureiSubjectPec.pecManager &&
      isEmpty(coraSubjectSid?.pecEmail)
    ) {
      const form = cloneDeep(initialValues)
      form.mailPresets = comureiSubjectPec.pecEmail ? comureiSubjectPec.pecEmail.trim() : ''
      setInitialValues(form)
    }
  }, [mailPresets, comureiSubjectPec])

  const validationSchema = Yup.object().shape({
    emailAddress: Yup.string()
      .required(labels.REQUIRED_FIELD)
      .email(labels.INCORRECT_EMAIL_ADDRESS),
    recipientEmail: Yup.string()
      .required(labels.REQUIRED_FIELD)
      .email(labels.INCORRECT_EMAIL_ADDRESS),
    password: Yup.string().required(labels.REQUIRED_FIELD),
    incomingMailHost: Yup.string().required(labels.REQUIRED_FIELD),
    incomingMailPort: Yup.number().required(labels.REQUIRED_FIELD),
    outgoingMailHost: Yup.string().required(labels.REQUIRED_FIELD),
    outgoingMailPort: Yup.number().required(labels.REQUIRED_FIELD),
    inFolder: Yup.string().required(labels.REQUIRED_FIELD),
    needsToAccept: Yup.boolean().oneOf([true], labels.YOU_MUST_ACCEPT_THE_CONDITIONS)
  })

  const formErrorsTabMapping = [
    {
      errorKey: 'emailAddress',
      errorLabel: labels.EMAIL_ADDRESS_TO_RECEIVE_GENERATED_FROM
    },
    {
      errorKey: 'password',
      errorLabel: labels.PASSWORD
    },
    {
      errorKey: 'inFolder',
      errorLabel: labels.INCOMING_MAIL_FOLDER
    },
    {
      errorKey: 'contactEmail',
      errorLabel: labels.CONTACT_EMAIL
    },
    {
      errorKey: 'recipientEmail',
      errorLabel: labels.PEC_RECIPIENT
    },
    {
      errorKey: 'outgoingMailHost',
      errorLabel: labels.OUTGOING_MAIL_HOST
    },
    {
      errorKey: 'outgoingMailPort',
      errorLabel: labels.OUTGOING_MAIL_PORT
    },
    {
      errorKey: 'incomingMailHost',
      errorLabel: labels.INCOMING_MAIL_HOST
    },
    {
      errorKey: 'incomingMailPort',
      errorLabel: labels.INCOMING_MAIL_PORT
    },
    {
      errorKey: 'automaticElaborationEnabled',
      errorLabel: labels.ELABORATE_AUTOMATICALLY_RECEIPTS
    },
    {
      errorKey: 'needsToAccept',
      errorLabel: labels.TERMS_AND_CONDITIONS
    },
    {
      errorKey: 'connection',
      errorLabel: labels.CHECK_CONNECTION
    }
  ]

  const getErrors = (errors) => {
    if (!errors) errors = {}
    if (needsTest && Object.keys(errors)?.length === 0) {
      errors.connection = labels.YOU_MUST_CHECK_THE_CONNECTION_BEFORE_SAVING
    }
    return extractErrors(errors, formErrorsTabMapping)
  }

  return (
    <div key={'pec-' + coraSubjectSid?.id}>
      {loading && <PageSpinner />}
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ errors }) => (
          <Form>
            {showErrorAlert && (
              <div>
                <ErrorListAlert errors={getErrors(errors)} hide={() => setShowErrorAlert(false)} />
              </div>
            )}
            <FormBody
              {...props}
              needsTest={needsTest}
              mailPresets={mailPresets}
              coraSubjectSid={coraSubjectSid}
              setNeedsTest={setNeedsTest}
              setLoading={setLoading}
              setShowErrorAlert={setShowErrorAlert}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const FormBody = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const { values, errors, setValues, validateForm } = useFormikContext()
  const [touched, setTouched] = useState(false)

  const [passwordFieldType, setPasswordFieldType] = useState('password')
  const [operatorSelect, setOperatorSelect] = useState()

  const handleFormVerify = async () => {
    setTouched(true)
    const errors = await validateForm(values) // Validazione dei valori
    if (Object.keys(errors).length == 0 || (Object.keys(errors).length == 1 && errors.connection)) {
      props.setShowErrorAlert(false) // Nascondi l'alert
      testMailParameters(values)
    } else {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    }
  }

  const handleChangeMailPreset = (selected) => {
    setOperatorSelect(selected)
    const preset = props.mailPresets.find((e) => e.id == selected)
    if (preset) {
      const form = cloneDeep(values)
      form.outgoingMailHost = preset.outgoingMailHost
        ? preset.outgoingMailHost.trim()
        : preset.outgoingMailHost
      form.outgoingMailPort = preset.outgoingMailPort
        ? preset.outgoingMailPort.trim()
        : preset.outgoingMailPort
      form.incomingMailHost = preset.incomingMailHost
        ? preset.incomingMailHost.trim()
        : preset.incomingMailHost
      form.incomingMailPort = preset.incomingMailPort
        ? preset.incomingMailPort.trim()
        : preset.incomingMailPort
      props.setNeedsTest(true)
      setValues(form)
    }
  }

  const handleAutoElaborationCheckBox = (value) => {
    const form = cloneDeep(values)
    form.automaticElaborationEnabled = value
    props.setNeedsTest(true)
    setValues(form)
  }

  const handleCheckbox = (value) => {
    const form = Object.assign({}, values)
    form.needsToAccept = value
    props.setNeedsTest(true)
    setValues(form)
  }

  const handleChangeTextField = (value, field, trim = true) => {
    let val = value
    if (trim) val = val.trim()
    const form = Object.assign({}, values)
    form[field] = val
    props.setNeedsTest(true)
    setValues(form)
  }

  const handleChangeNumericField = (value, field) => {
    const form = Object.assign({}, values)
    const val = value.replace(onlyNumbers)
    form[field] = val
    props.setNeedsTest(true)
    setValues(form)
  }

  const onClickPwdEye = () => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text')
    } else if (passwordFieldType === 'text') {
      setPasswordFieldType('password')
    }
  }

  const onClean = () => {
    PopupAlert({
      text: labels.DO_YOU_WANT_TO_DELETE_THE_PEC_CONFIGURATION,
      handleClickConfirm: () => {
        let toSave = props.coraSubjectSid

        toSave = {
          ...toSave,
          pecEmail: undefined,
          outgoingMailHost: undefined,
          outgoingMailPort: undefined,
          incomingMailHost: undefined,
          incomingMailPort: undefined,
          mailPassword: undefined,
          contactEmail: undefined,
          recipientEmail: undefined,
          incomingMailFolder: undefined,
          automaticElaborationEnabled: false
        }

        props.onSave(toSave)
      }
    })
  }

  const testMailParameters = () => {
    props.setLoading(true)
    actions
      .testMailParameters(
        values.emailAddress,
        values.password,
        values.incomingMailHost,
        values.incomingMailPort,
        values.inFolder,
        values.outgoingMailHost,
        values.outgoingMailPort
      )
      .then(
        (res) => {
          props.setNeedsTest(false)
          notifySuccess(labels.MAIL_PARAMETERS_VERIFIED_SUCCESSFULLY)
        },
        (err) => {
          PopupError({ text: err })
        }
      )
      .then(() => props.setLoading(false))
  }

  const onClickSave = () => {
    let toSave = props.coraSubjectSid

    toSave = {
      ...toSave,
      pecEmail: values.emailAddress,
      outgoingMailHost: values.outgoingMailHost,
      outgoingMailPort: values.outgoingMailPort,
      incomingMailHost: values.incomingMailHost,
      incomingMailPort: values.incomingMailPort,
      mailPassword: values.password,
      contactEmail: values.contactEmail,
      recipientEmail: values.recipientEmail,
      incomingMailFolder: values.inFolder,
      automaticElaborationEnabled: values.automaticElaborationEnabled
    }

    props.onSave(toSave)
  }

  const handleFormSubmit = async () => {
    setTouched(true)
    const errors = await validateForm(values) // Validazione dei valori
    if (Object.keys(errors).length > 0 || props.needsTest) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      props.setShowErrorAlert(false) // Nascondi l'alert
      onClickSave(values)
    }
  }

  const renderTipSection = () => {
    const tips = [
      {
        title: labels.OPERATOR,
        text: labels.SELECT_THE_MANAGER_OF_YOUR_PEC
      },
      {
        title: labels.PEC_SENDER_ADDRESS,
        text: labels.PEC_SENDER_ADDRESS_TEXT
      },
      {
        title: labels.PEC_RECIPIENT,
        text: labels.PEC_RECIPIENT_TEXT
      },
      {
        title: labels.INCOMING_MAIL_HOST,
        text: labels.INCOMING_MAIL_HOST_TEXT
      },
      {
        title: labels.INCOMING_MAIL_FOLDER,
        text: labels.INCOMING_MAIL_FOLDER_TEXT
      },
      {
        title: labels.CONTACT_EMAIL,
        text: labels.CONTACT_EMAIL_TEXT
      },
      {
        title: labels.ELABORATE_AUTOMATICALLY_RECEIPTS,
        text: labels.ELABORATE_AUTOMATICALLY_RECEIPTS_TEXT
      }
    ]

    return <InfoBox additionalClasses={'flex-grow-1 p-1 px-3 py-2'} simplePointsArr={tips} />
  }

  const renderMailSection = () => {
    return (
      <div
        className="configurazioneSID d-flex flex-column justify-content-center"
        key={'mailsection-' + props.coraSubjectSid?.id}>
        <div className="row">
          <div className="col-10 mb-2 ps-3">
            <BwmInput
              className={'form-control'}
              label={labels.PEC_SENDER_ADDRESS}
              value={values.emailAddress}
              error={errors.emailAddress}
              touched={touched}
              onChange={(e) => handleChangeTextField(e.target.value.toUpperCase(), 'emailAddress')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-10 mb-2 ps-3">
            <div className="d-flex">
              <div style={{ flexGrow: 1 }}>
                <BwmInput
                  className={'form-control'}
                  label={labels.PASSWORD}
                  type={passwordFieldType}
                  name={'passwordPec'}
                  value={values.password}
                  error={errors.password}
                  touched={touched}
                  Icon={() => (
                    <div
                      className={`align-self-center mt-2 ms-2 cursor-pointer ${errors.password && touched ? 'me-4' : ''}`}
                      onClick={() => onClickPwdEye()}>
                      <i
                        className={`thx-icon bg-primary ${passwordFieldType === 'text' ? 'thx-eye' : 'thx-eye-off'}`}
                      />
                    </div>
                  )}
                  onChange={(e) => handleChangeTextField(e.target.value, 'password')}
                  autoComplete={'new-password'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-10 mb-2 ps-3">
            <BwmInput
              className={'form-control'}
              label={labels.PEC_RECIPIENT}
              value={values.recipientEmail}
              error={errors.recipientEmail}
              touched={touched}
              onChange={(e) => {
                handleChangeTextField(e.target.value.toUpperCase(), 'recipientEmail')
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  const renderMailParamsSection = () => {
    return (
      <div key={'mailParamssection-' + props.coraSubjectSid?.id}>
        <div className="row justify-content-center mb-2">
          <div className="col-6 pe-2">
            <div className="configurazioneSID p-3">
              {/*posta in entrata*/}
              <div>
                <BwmInput
                  className={'form-control'}
                  label={labels.INCOMING_MAIL_HOST}
                  value={values.incomingMailHost}
                  error={errors.incomingMailHost}
                  touched={touched}
                  onChange={(e) => handleChangeTextField(e.target.value, 'incomingMailHost')}
                />
              </div>
              <div className="row">
                <div className="col-6 pe-2 mt-2">
                  <BwmInput
                    className={'form-control'}
                    label={labels.PORT}
                    value={values.incomingMailPort}
                    error={errors.incomingMailPort}
                    touched={touched}
                    onChange={(e) => handleChangeNumericField(e.target.value, 'incomingMailPort')}
                  />
                </div>
                <div className="col-6 mt-2">
                  <div className="d-flex">
                    <div style={{ flexGrow: 1 }}>
                      <BwmInput
                        className={'form-control'}
                        label={labels.INCOMING_MAIL_FOLDER}
                        value={values.inFolder}
                        error={errors.inFolder}
                        touched={touched}
                        onChange={(e) => handleChangeTextField(e.target.value, 'inFolder')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="configurazioneSID p-3">
              {/*posta in uscita*/}
              <div className="mb-2">
                <BwmInput
                  className={'form-control'}
                  label={labels.OUTGOING_MAIL_HOST}
                  value={values.outgoingMailHost}
                  error={errors.outgoingMailHost}
                  touched={touched}
                  onChange={(e) => handleChangeTextField(e.target.value, 'outgoingMailHost')}
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <BwmInput
                    className={'form-control'}
                    label={labels.PORT}
                    value={values.outgoingMailPort}
                    error={errors.outgoingMailPort}
                    touched={touched}
                    onChange={(e) => handleChangeNumericField(e.target.value, 'outgoingMailPort')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderServiceSection = () => {
    return (
      <div className="row">
        <div
          className="configurazioneSID p-3 mb-2"
          key={'servicesection-' + props.coraSubjectSid?.id}>
          <div className="row">
            <div className="col-8 pe-2">
              <div className="d-flex">
                <div style={{ flexGrow: 1 }}>
                  <BwmInput
                    className={'form-control'}
                    label={labels.CONTACT_EMAIL}
                    value={values.contactEmail}
                    error={errors.contactEmail}
                    touched={touched}
                    onChange={(e) => handleChangeTextField(e.target.value, 'contactEmail')}
                  />
                </div>
              </div>
            </div>
            <div className="col d-flex">
              <div className={'align-self-center'}>
                <BwmCheckbox
                  name={'automaticElaborationEnabled'}
                  label={labels.ELABORATE_AUTOMATICALLY_RECEIPTS}
                  checked={values.automaticElaborationEnabled}
                  error={errors.automaticElaborationEnabled}
                  touched={touched}
                  onChange={(e) => handleAutoElaborationCheckBox(e.target.checked)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderEULASection = () => {
    return (
      <div className="row">
        <div
          className="configurazioneSID mt-1 pt-1 pb-1 "
          key={'servicesection-' + props.coraSubjectSid?.id}>
          <div className="row">
            <div className="col-10 text-small p-1 px-3">
              <small>
                {labels.ACCEPT_CONDITIONS_TEXT}
                <br />
                {labels.ACCEPT_CONDITIONS_TEXT_2}
              </small>
            </div>
            <div className="col-2 d-flex align-items-center justify-content-center">
              <button
                onClick={() => handleCheckbox(!values.needsToAccept)}
                className={
                  !values.needsToAccept ? 'btn btn-outline-primary ' : 'btn btn-outline-primary '
                }>
                {labels.ACCEPT}
                {!values.needsToAccept ? (
                  <i className="thx-toggle-off thx-icon ms-2" />
                ) : (
                  <i className="thx-toggle-on thx-icon ms-2" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div>
        <div className="row">
          <div className="col-6 d-flex flex-column">
            <div className="row">
              <div className="col-12 mb-2 pe-2">
                <BwmSelect
                  options={
                    props.mailPresets
                      ? props.mailPresets.map((p) => {
                          return { id: p.id, description: p.name }
                        })
                      : []
                  }
                  name="select"
                  label="Seleziona un operatore"
                  className="form-control"
                  value={operatorSelect}
                  onChange={(e) => handleChangeMailPreset(e.target.value)}
                />
              </div>
            </div>
            <div className="row flex-grow-1 mb-2 pe-2">{renderMailSection()}</div>
          </div>
          <div className="col-6 mb-2 d-flex flex-column">{renderTipSection()}</div>
        </div>

        {renderMailParamsSection()}
        {renderServiceSection()}
        {renderEULASection()}
      </div>
      <div className="row pt-3 justify-content-between align-items-center">
        <div className="col-2">
          <button className="btn btn-outline-primary " onClick={() => props.onCancel()}>
            <i className="thx-cancel thx-icon me-2" />
            {labels.CANCEL}
          </button>
        </div>
        <div className="col-4">
          <button
            className="btn btn-outline-primary ms-2 btn-outline-primary"
            onClick={() => onClean()}>
            {labels.DELETE_CONFIGURATION}
          </button>
        </div>
        <div className="col-3 d-flex justify-content-center">
          <button className="btn btn-outline-primary" onClick={() => handleFormVerify()}>
            {labels.CHECK_CONNECTION}
          </button>
        </div>
        <div className="col-3 d-flex justify-content-end">
          <button className="btn btn-primary ml-2" onClick={() => handleFormSubmit()}>
            <i className="thx-floppy-disk thx-icon me-2" />
            {labels.SAVE}
          </button>
        </div>
      </div>
    </>
  )
}
