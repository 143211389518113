import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import * as actions from '../../../../actions'
import * as Utility from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'
import getText from '../../../shared/i18n/labels'

// FORM
import { BwmInput } from '../../../shared/form/BwmInput'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { BwmSelect } from '../../../shared/form/BwmSelect'

// BUTTONS
import { DeleteButton } from './table-components/DeleteButton'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'

import '../../../../styles/comurei-form.css'

const defaultSize = (size = null) => ({
  checkbox: Utility.getSize(size, { default: '5' }),
  code: Utility.getSize(size, { default: '5', lg: '8' }),
  description: Utility.getSize(size, { default: '73', lg: '64' }),
  value: Utility.getSize(size, { default: '5', lg: '8' }),
  default: Utility.getSize(size, { default: '5', lg: '8' }),
  edit: Utility.getSize(size, { default: '8' })
})

const getSize = () => {
  if (window.innerWidth <= Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export const ArcoTableRiskValues = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [sizesClass, setSizesClass] = useState(getSize())

  const [checkedItems, setCheckedItems] = useState({})
  const [allCheck, setAllCheck] = useState(false)
  const [newRow, setNewRow] = useState({})
  const [rows, setRows] = useState([])
  const [renderRows, setRenderRows] = useState([])
  const [editedIds, setEditedIds] = useState([])
  const [selectedRiskCategory, setSelectedRiskCategory] = useState({})

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  useEffect(() => {
    if (props.arcoTableDomains?.length > 0) {
      setSelectedRiskCategory(props.arcoTableDomains[0])
    }
  }, [props.arcoTableDomains])

  useEffect(() => {
    setNewRows()
  }, [props.riskValues, selectedRiskCategory])

  useEffect(() => {
    setRenderRows(_renderRows())
  }, [rows, newRow, checkedItems, sizesClass])

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
  }

  const setNewRows = () => {
    let riskValuesData = props.riskValues.filter(
      (riskValue) => riskValue.type === selectedRiskCategory?.code
    )
    setRows(riskValuesData)
    setTimeout(() => {
      const element = document.getElementById('arco-risk-list')
      if (element) element.scrollTop += 3000
    }, 400)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = {}
      let newRows = cloneDeep(rows)
      newRows.forEach((el) => (items[el.id] = true))
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChange = (event, id) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[id] = true
      else delete newCheckedItems[id]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === rows?.length)
      if (Object.keys(checkedItems)?.length > 0) return
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  const handleValidation = (row) => {
    let isValid = true
    if ([undefined, null, ''].includes(row.description)) isValid = false
    if ([undefined, null, ''].includes(row.value)) isValid = false
    if ([undefined, null, ''].includes(row.code)) isValid = false
    return isValid
  }

  const addArcoRiskValue = (riskValue, index) => {
    const formData = { ...riskValue }
    formData.id = formData.id || null
    formData.arcoSubject = { id: props.subject.id }
    formData.customer = { id: props.subject?.customerId }
    formData.def = riskValue.def || false
    if (!riskValue.id) formData.type = selectedRiskCategory ? selectedRiskCategory?.code : 'A1'
    actions.saveArcoRiskValue(formData).then(
      (response) => {
        setEditedIds(editedIds.filter((_, _index) => index?.toString() !== index?.toString()))
        setCheckedItems({})
        setNewRow({})
        Utility.notifySuccess(labels.SAVED_SUCCESSFULLY)
        props.getAllRiskValueBySubjectId()
      },
      (error) => {
        PopupError({
          text:
            error.status === 511
              ? labels.MAX_SUBJECTS_REACHED
              : labels.ERROR_UPDATING_SUBJECT + error.title,
          labels
        })
      }
    )
  }

  const deleteArcoRiskValueSubject = () => {
    const promises = []
    Object.keys(checkedItems).map((riskValue) => {
      promises.push(actions.deleteArcoRiskValue(props.subject.id, riskValue))
    })
    Promise.all(promises)
      .then(() => {
        setNewRow({})
        setCheckedItems([])
        Utility.notifySuccess(labels.REMOVED_SUCCESSFULLY)
        props.getAllRiskValueBySubjectId()
      })
      .catch((errors) => PopupError({ text: errors.title || labels.GENERIC_ERROR }))
  }

  const getSelectedElement = (e) => {
    const cod = e.target[e.target.selectedIndex].dataset.cod
    const selected = props.arcoTableDomains.find((a) => a.code === cod)
    setSelectedRiskCategory(selected)
  }

  const handleChangeValue = (val, index, name, upperCase = false) => {
    const newEditedIds = Object.assign([], editedIds)
    if (val && val !== '' && upperCase) val = val.toUpperCase()
    if (index !== -1) {
      const newRows = Object.assign([], rows)
      newRows[index][name] = val
      setRows(newRows)
      newEditedIds[index] = handleValidation(newRows[index]) ? true : false
    } else {
      const _newRow = Object.assign({}, newRow)
      _newRow[name] = val
      setNewRow(_newRow)
      newEditedIds[index] = handleValidation(_newRow) ? true : false
    }
    setEditedIds(newEditedIds)
  }

  const onUpdate = (riskValue, index) => {
    if (!handleValidation(riskValue)) return
    addArcoRiskValue(riskValue, index)
  }

  const row = (riskValue, sizes, index = -1) => {
    return (
      <div key={`${selectedRiskCategory.code}_${index}`} className="text-start d-flex row-table">
        <div
          className={`${sizes.checkbox} text-center div-td d-flex align-items-center justify-content-center`}>
          {riskValue?.id && (
            <input
              key={`check_${selectedRiskCategory.code}_${index}}`}
              name={`check_${selectedRiskCategory.code}_${index}}`}
              type="checkbox"
              id={`checkbox_${riskValue.id || ''}`}
              checked={checkedItems[riskValue.id] || false}
              onChange={(e) => handleCheckChange(e, riskValue.id)}
            />
          )}
        </div>
        <div className={`${sizes.code} div-td text-truncate`}>
          <BwmInput
            name="code"
            className="form-control form-control-cell"
            formClassName={`${[undefined, null, ''].includes(riskValue.code) && Object.keys(riskValue).length > 0 ? 'has-error' : ''}`}
            value={riskValue.code}
            onChange={(e) => handleChangeValue(e.target.value, index, 'code')}
          />
        </div>
        <div className={`${sizes.description} div-td`}>
          <BwmInput
            name="description"
            className="form-control form-control-cell"
            formClassName={`${[undefined, null, ''].includes(riskValue.description) && Object.keys(riskValue).length > 0 ? 'has-error' : ''}`}
            value={riskValue.description}
            onChange={(e) => handleChangeValue(e.target.value, index, 'description')}
          />
        </div>
        <div className={`${sizes.value} div-td`}>
          <BwmInput
            name="value"
            type="number"
            className="form-control form-control-cell text-end"
            formClassName={`${[undefined, null, ''].includes(riskValue.value) && Object.keys(riskValue).length > 0 ? 'has-error' : ''}`}
            value={riskValue.value}
            onChange={(e) => handleChangeValue(parseInt(e.target.value || 0), index, 'value')}
          />
        </div>
        <div className={`${sizes.default} div-td d-flex justify-content-center align-items-center`}>
          <BwmCheckbox
            name={`def_${riskValue?.id}`}
            className="form-control form-control-cell"
            checked={riskValue.def}
            onChange={(e) => handleChangeValue(e.target.checked, index, 'def')}
          />
        </div>
        <div className={`${sizes.edit} div-td d-flex justify-content-center align-items-center`}>
          <button
            disabled={!editedIds[index]}
            className="btn btn-primary btn-cell"
            onClick={() => onUpdate(riskValue, index)}>
            {labels.SAVE}
          </button>
        </div>
      </div>
    )
  }

  const _renderRows = () => {
    const newRows = []
    rows.forEach((riskValue, index) => newRows.push(row(riskValue, sizesClass, index)))
    if (!props.disabled) newRows.push(row(newRow, sizesClass))
    return newRows
  }

  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <DeleteButton
          disabled={Object.keys(checkedItems)?.length === 0}
          onClick={deleteArcoRiskValueSubject}
        />
        <h5 className="ms-5 me-0 mb-0">Tabella:</h5>
        <div className="ms-2">
          <BwmSelect
            allowEmpty={false}
            options={props.arcoTableDomains}
            name="table"
            className="form-control form-control-normal"
            showCode={true}
            value={selectedRiskCategory?.id}
            onChange={(e) => getSelectedElement(e)}
          />
        </div>
      </div>

      <div id="arco-risk-list" className="star-table arco-risk-list">
        <div className="text-start header-table">
          <div
            className={`${sizesClass.checkbox} text-center div-td d-flex align-items-center justify-content-center`}>
            <input
              disabled={!props.riskValues?.length > 0}
              type="checkbox"
              checked={allCheck}
              onChange={(e) => handleAllCheckChange(e)}
            />
          </div>
          <div className={`${sizesClass.code} div-td`}>{labels.CODE}</div>
          <div className={`${sizesClass.description} div-td`}>{labels.DESCRIPTION}</div>
          <div className={`${sizesClass.value} div-td text-end`}>{labels.VALUE}</div>
          <div className={`${sizesClass.default} div-td text-center`}>{labels.DEFAULT}</div>
          <div className={`${sizesClass.edit} div-td text-center`}>{labels.EDIT}</div>
        </div>
        {renderRows}
      </div>
    </>
  )
}
