import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isNotEmpty, notifySuccess } from '../../../shared/Utility'
import { CheckRemainingSubjects, IsLicenseValid } from '../../../shared/auth/license/LicenseChecker'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'
import { Route, useRouteMatch } from 'react-router-dom'

// DETAIL
import { CrsRelationships } from '../relationship-crs/NewCrsRelationships'

// MODAL
import { handleCreateCommunicationErrorsText } from '../communications/NewCreateCommunicationModal'
import { ImportCrsSubjectDataModal } from '../import/NewImportCrsSubjectDataModal'
import { CrsCommunicationModal } from '../communications/CrsCommunicationModal'
import { CrsSubjectsExportModal } from './NewCrsSubjectsExportModal'
import { UploadReceiptModal } from '../../application-crs/receipts/UploadReceiptModal'
// import { CrsSubjectModal } from './NewCrsSubjectModal'

// BUTTONS
import { BtnFill } from '../../../shared/BtnFill'

// COMPONENTS
import { CrsSubjectList } from './NewCrsSubjectList'
import { StarTooltip } from '../../../shared/tooltips/Tooltip'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { SubjectTotalAndCallToAction } from '../../../shared/SubjectTotalAndCallToAction'
import { LicenseExpiredPopup } from '../../../shared/auth/license/LicenseExpiredPopup'
import { PopupError, closePopupError } from '../../../shared/popups/PopupError'
import { PopupDelete } from '../../../shared/popups/PopupDelete'

export const CrsSubject = () => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const { path } = useRouteMatch()

  const [relationshipId, setRelationshipId] = useState(null)

  const [searchObject, setSearchObject] = useState({})
  const [checkedItems, setCheckedItems] = useState([])
  const [subjectSummary, setSubjectSummary] = useState(null)
  const [expired, setExpired] = useState(false)
  const [disableNewSubjectButtons, setDisableNewSubjectButtons] = useState(false)
  const previousYear = new Date().getFullYear() - 1
  const [isLoading, setIsLoading] = useState(false)
  const [resetForm, setResetForm] = useState(false)
  const [elaboratedCommunicationModals, setElaboratedCommunicationModals] = useState([])
  const [currentModalIndex, setCurrentModalIndex] = useState(null)
  const [customerApplication, setCustomerApplication] = useState(null)

  const dispatch = useDispatch()

  const auth = useSelector((state) => state.auth)

  const _subjects = useSelector((state) => state.crsSubjects.data)
  const [subjects, setSubjects] = useState([])

  useEffect(() => {
    getLocations()
    getCountries()
    getSubjectSummary()
  }, [])

  useEffect(() => {
    setIsLoading(false)
    setSubjects(_subjects)
  }, [_subjects])

  useEffect(() => {
    if (expired) LicenseExpiredPopup({})
  }, [expired])

  useEffect(() => {
    setExpired(isExpired())
    const application = Constants.APPLICATIONS.find(
      (a) => a.description === Constants.APPLICATION_CRS
    )
    const customerApplication = auth?.customer?.customerApplications?.find(
      (ca) => ca?.application?.id === application?.id
    )
    setCustomerApplication(customerApplication)
  }, [auth])

  const isExpired = () => {
    if (auth?.isAuth) {
      return !IsLicenseValid(auth.customer?.customerApplications, Constants.APPLICATION_CRS)
    }
  }

  const getLocations = () => {
    dispatch(actions.getAllLocations())
  }

  const getCountries = () => {
    dispatch(actions.getAllCountries())
  }

  const getSubjectSummary = (showPopup) => {
    CheckRemainingSubjects(
      Constants.APPLICATION_CRS,
      showPopup,
      (subjectSummary) => {
        setSubjectSummary(subjectSummary)
        setDisableNewSubjectButtons(subjectSummary?.available < 1)
      },
      labels,
      lang
    )
  }

  const getSubjects = () => {
    actions.searchCrsSubjects(searchObject).then((res) => {
      setSubjects(res.content)
      getSubjectSummary(true)
    })
  }

  const onClickCheck = (newList = []) => {
    setCheckedItems(newList)
  }

  const setData = (subjects, searchObject) => {
    setSubjects(subjects)
    setSearchObject(searchObject)
  }

  const deleteCrsSubject = () => {
    checkedItems.map((subject) => {
      actions.deleteCrsSubject(subject).then(
        () => {
          setCheckedItems([])
          getSubjects()
          getSubjectSummary(false)
          notifySuccess(labels.REMOVED_SUCCESSFULLY)
        },
        (errors) => {
          PopupError({ text: errors })
        }
      )
    })
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: labels.SELECTED_SUBJECTS,
      handleClickConfirm: deleteCrsSubject,
      text: labels.ATTENTION_IF_IN_FUTURE_IT_IS_NEEDED_TO_RESTORE_THIS_SUBJECT_AUTHORIZATION_MUST_BE_REQUESTED_TO_THE_ASSISTANCE_OFFICE
    })
  }

  const onClickCreateEmptyCommunications = () => {
    setIsLoading(true)
    setElaboratedCommunicationModals([])
    let elaboratedCommunications = []
    actions
      .createCrsCommunicationEmptyMultiple(checkedItems)
      .then(
        (res) => {
          res.forEach((element, index) => {
            if (element.communication) {
              elaboratedCommunications.push(() => (
                <CrsCommunicationModal
                  key={'communication-modal-' + element.communication.id}
                  sentCommunications={[element.communication]}
                  showModal={true}
                  subject={element.communication.subject}
                  emailAddress={element.communication.subject?.coraSubjectSid?.email}
                  closeModal={() => {
                    setCurrentModalIndex(
                      elaboratedCommunications[currentModalIndex + 1] ? currentModalIndex + 1 : null
                    )
                  }}
                />
              ))
            } else if (element.error) {
              elaboratedCommunications.push(() => (
                <div key={'communication-error-modal-' + index}>
                  {PopupError({
                    text: element.error,
                    buttonClose: () => (
                      <BtnFill
                        text={labels.CLOSE}
                        handlerOnClick={() => {
                          closePopupError()
                          setCurrentModalIndex(
                            elaboratedCommunications[currentModalIndex + 1]
                              ? currentModalIndex + 1
                              : null
                          )
                        }}
                      />
                    )
                  })}
                </div>
              ))
            }
          })
        },
        (err) => {
          PopupError({
            text: handleCreateCommunicationErrorsText(err)
          })
        }
      )
      .then(() => {
        setIsLoading(false)
        setElaboratedCommunicationModals(elaboratedCommunications)
        setCurrentModalIndex(elaboratedCommunications.length !== 0 ? 0 : null)
        getSubjects()
      })
  }

  const actionsComponent = () => {
    return (
      <div className="col-12 ps-0">
        <div className="btn-group" role="group" aria-label="action buttons">
          <button
            type="button"
            className={`btn btn-outline-primary ${checkedItems?.length === 0 && 'disabled'}`}
            onClick={openPopupDelete}>
            <i className="thx-trash thx-icon me-2" />
            {labels.DELETE}
          </button>
          <CrsSubjectsExportModal
            disabled={checkedItems?.length === 0}
            checkedItems={checkedItems}
          />
          <ImportCrsSubjectDataModal
            disabled={disableNewSubjectButtons || expired}
            getSubjects={getSubjects}
          />

          <StarTooltip
            text={
              checkedItems?.length === 0
                ? labels.SELECT_SUBJECTS_TO_CREATE_COMMUNICATION
                : labels.CREATE_EMPTY_COMMUNICATIONS_FOR_SELECTED_SUBJECTS
            }>
            <button
              type="button"
              className={`btn btn-primary  ${checkedItems?.length === 0 && 'disabled'}`}
              onClick={() => {
                /**
                 * -salva soggetti con anno
                 * -apri modale per ogni soggetto
                 */
                onClickCreateEmptyCommunications()
              }}
              disabled={checkedItems?.length === 0 || expired}>
              <i className="thx-text-file thx-icon me-2" />
              {labels.CREATE_EMPTY_COMMUNICATIONS_FOR_SELECTED_SUBJECTS} {previousYear}
            </button>
          </StarTooltip>
        </div>
      </div>
    )
  }

  return (
    <>
      {isLoading && <PageSpinner />}
      <Route key={path} path={`${path}/relationship/:id`}>
        <CrsRelationships setId={setRelationshipId} />
      </Route>
      <div className={relationshipId ? 'd-none' : ''}>
        <div className="table-custom">
          <div className="d-flex w-100 justify-content-between align-items-center">
            <SubjectTotalAndCallToAction
              total={subjectSummary?.total}
              remaining={subjectSummary?.available}
              used={subjectSummary?.used}
              baseEcommerceProductId={Constants.getEcommerceProductId(Constants.APPLICATION_CRS)}
              subjectsProductId={Constants.getEcommerceSubjectProductId(Constants.APPLICATION_CRS)}
              licenseType={customerApplication?.licenseType}
              customerApplication={customerApplication}
              buyLink={Constants.CRS_BUY_LINK}
              language={lang}
            />
            <div className="d-flex">
              <div className="me-2">
                <UploadReceiptModal disabled={expired} refreshSubjects={getSubjects} />
              </div>
              <div>
                {/* <CrsSubjectModal
                subjectSummary={subjectSummary}
                licenseExpired={!isLicenseValid}
                locations={locations || []}
                countries={countries || []}
                getSubjects={getSubjects}
                disabled={!isLicenseValid}
                closeModal={() => setSubject(null)}
              /> */}
                <button
                  type="button"
                  className="btn btn-primary"
                  data-tip={labels.NEW_SUBJECT}
                  disabled={expired || disableNewSubjectButtons}
                  onClick={() => setResetForm(!resetForm)}>
                  <i className="thx-plus thx-icon" />
                  {window.innerWidth > Constants.BREAKPOINT_MD && (
                    <span className="ms-2">{labels.NEW_SUBJECT}</span>
                  )}
                </button>
              </div>
            </div>
          </div>
          <CrsSubjectList
            disabled={expired}
            resetForm={resetForm}
            actionsComponent={actionsComponent()}
            subjects={subjects}
            subjectSummary={subjectSummary}
            getSubjects={getSubjects}
            checkedItems={checkedItems}
            setData={setData}
            setSearchObject={setSearchObject}
            onClickCheck={onClickCheck}
          />
        </div>
        {isNotEmpty(currentModalIndex) &&
          isNotEmpty(elaboratedCommunicationModals[currentModalIndex]) &&
          elaboratedCommunicationModals[currentModalIndex]()}
      </div>
    </>
  )
}
