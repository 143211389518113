import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Constants from '../../../../config/Constants'
import * as Utility from '../../../shared/Utility'
import { cloneDeep } from 'lodash'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// FORM
import { BwmInput } from '../../../shared/form/BwmInput'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { DateField } from '../../../shared/form/DateField'

// COMPONENTS
import { DeleteButton } from './table-components/DeleteButton'
import { PopupError } from '../../../shared/popups/PopupError'

import '../../../../styles/comurei-form.css'

const defaultSize = (size = null) => ({
  checkbox: Utility.getSize(size, { default: '5' }),
  code: Utility.getSize(size, { default: '7' }),
  fullName: Utility.getSize(size, { default: '44', md: '36' }),
  fiscalCode: Utility.getSize(size, { default: '15' }),
  disabledDate: Utility.getSize(size, { default: '15' }),
  disabled: Utility.getSize(size, { default: '7', md: '11' }),
  save: Utility.getSize(size, { default: '7', md: '11' })
})

const getSize = () => {
  if (window.innerWidth <= Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export const ArcoTableCollaboratorsList = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [sizesClass, setSizesClass] = useState(getSize())

  const [checkedItems, setCheckedItems] = useState({})
  const [allCheck, setAllCheck] = useState(false)
  const [newRow, setNewRow] = useState({})
  const [rows, setRows] = useState([])
  const [renderRows, setRenderRows] = useState([])
  const [editedIds, setEditedIds] = useState([])

  useEffect(() => {
    setSizesClass(getSize())
    setRenderRows(_renderRows())
  }, [window.innerWidth])

  useEffect(() => {
    setNewRows()
  }, [props.collaborators])

  useEffect(() => {
    setRenderRows(_renderRows())
  }, [rows, newRow, checkedItems, editedIds, sizesClass])

  const resetCheckBox = () => {
    setCheckedItems({})
    setAllCheck(false)
  }

  const setNewRows = () => {
    let collaboratorsData = props.collaborators
    setRows(collaboratorsData)
    setTimeout(() => {
      const element = document.getElementById('arco-collaborators-list')
      if (element) element.scrollTop += 3000
    }, 400)
  }

  const handleAllCheckChange = (event) => {
    setAllCheck(event.target.checked)
    if (event.target.checked) {
      let items = {}
      let newRows = cloneDeep(rows)
      newRows.forEach((el) => (items[el.id] = true))
      setCheckedItems(items)
    } else {
      setCheckedItems({})
    }
  }

  const handleCheckChange = (event, id) => {
    if (event !== undefined) {
      let newCheckedItems = cloneDeep(checkedItems)
      if (event.target.checked) newCheckedItems[id] = true
      else delete newCheckedItems[id]
      setCheckedItems(newCheckedItems)
      setAllCheck(Object.keys(newCheckedItems)?.length === rows?.length)
      if (Object.keys(checkedItems)?.length > 0) return
      if (Object.keys(checkedItems) > 0) {
        setCheckedItems({})
        resetCheckBox()
      }
    }
  }

  const handleValidation = (row) => {
    let isValid = true
    if ([undefined, null, ''].includes(row.code)) isValid = false
    if ([undefined, null, ''].includes(row.name)) isValid = false
    if ([undefined, null, ''].includes(row.fiscalCode)) isValid = false

    if (row.disabledDate && row.disabled) isValid = false
    if (!row.disabledDate && !row.disabled) isValid = false
    return isValid
  }

  const addArcoCollaborator = (collaborator, index) => {
    const formData = Object.assign({}, collaborator)
    formData.createDate = new Date()
    formData.updateDate = new Date()
    formData.code = formData.code || ''
    formData.disableDate = formData.disableDate || null
    formData.disabled = formData.disabled || false
    formData.arcoSubject = { id: props.subject.id }
    formData.customer = { id: props.subject.customerId }
    actions.saveArcoCollaborator(formData).then(
      (response) => {
        setEditedIds(editedIds.filter((_, _index) => index?.toString() !== index?.toString()))
        setCheckedItems({})
        setNewRow({})
        Utility.notifySuccess(labels.SAVED_SUCCESSFULLY)
        props.getAllCollaboratorBySubjectId()
      },
      (errors) => {
        PopupError({ text: errors?.title || labels.GENERIC_ERROR })
      }
    )
  }

  const onUpdate = (collaborator, index) => {
    if (!handleValidation(collaborator)) return
    addArcoCollaborator(collaborator, index)
  }

  const deleteArcoCollaboratorSubject = () => {
    const promises = []
    Object.keys(checkedItems).map((collaborator) => {
      promises.push(actions.deleteArcoCollaborator(collaborator))
    })
    Promise.all(promises)
      .then(() => {
        setNewRow({})
        resetCheckBox()
        Utility.notifySuccess(labels.REMOVED_SUCCESSFULLY)
        props.getAllCollaboratorBySubjectId()
      })
      .catch((errors) => PopupError({ text: errors.title || labels.GENERIC_ERROR }))
  }

  const handleChangeValue = (val, index, name, upperCase = false) => {
    const newEditedIds = Object.assign([], editedIds)
    if (val && val !== '' && upperCase) val = val.toUpperCase()
    if (index !== -1) {
      const newRows = Object.assign([], rows)
      newRows[index][name] = val
      if (val && name === 'disabled') newRows[index]['disabledDate'] = null
      if (val && name === 'disabledDate') newRows[index]['disabled'] = false
      setRows(newRows)
      newEditedIds[index] = handleValidation(newRows[index]) ? true : false
    } else {
      const _newRow = Object.assign({}, newRow)
      _newRow[name] = val
      if (val && name === 'disabled') _newRow['disabledDate'] = null
      if (val && name === 'disabledDate') _newRow['disabled'] = false
      setNewRow(_newRow)
      newEditedIds[index] = handleValidation(_newRow) ? true : false
    }
    setEditedIds(newEditedIds)
  }

  const row = (collaborator, index = -1) => {
    return (
      <div key={index} className="text-start d-flex row-table">
        <div
          className={`${sizesClass.checkbox} text-center div-td d-flex align-items-center justify-content-center`}>
          {collaborator?.id && (
            <input
              key={`check_${index}}`}
              name={`check_${index}}`}
              type="checkbox"
              id={`checkbox_${collaborator.id || ''}`}
              checked={checkedItems[collaborator.id] || false}
              onChange={(e) => handleCheckChange(e, collaborator.id)}
            />
          )}
        </div>
        <div className={`${sizesClass.code} div-td text-truncate`}>
          <BwmInput
            name="code"
            className="form-control form-control-cell"
            formClassName={`${[undefined, null, ''].includes(collaborator.code) && Object.keys(collaborator).length > 0 ? 'has-error' : ''}`}
            value={collaborator.code}
            onChange={(e) => handleChangeValue(e.target.value, index, 'code')}
          />
        </div>
        <div className={`${sizesClass.fullName} div-td text-truncate`}>
          <BwmInput
            name="name"
            className="form-control form-control-cell"
            formClassName={`${[undefined, null, ''].includes(collaborator.name) && Object.keys(collaborator).length > 0 ? 'has-error' : ''}`}
            value={collaborator.name}
            onChange={(e) => handleChangeValue(e.target.value, index, 'name', true)}
          />
        </div>
        <div className={`${sizesClass.fiscalCode} div-td text-truncate`}>
          <BwmInput
            name="fiscalCode"
            className="form-control form-control-cell"
            formClassName={`${[undefined, null, ''].includes(collaborator.fiscalCode) && Object.keys(collaborator).length > 0 ? 'has-error' : ''}`}
            value={collaborator.fiscalCode}
            onChange={(e) => handleChangeValue(e.target.value, index, 'fiscalCode', true, true)}
          />
        </div>
        <div className={`${sizesClass.disabledDate} div-td text-truncate`}>
          <DateField
            disabled={props.disabled}
            className="form-control form-control-cell"
            formClassName={`${((collaborator.disabledDate && collaborator.disabled) || (!collaborator.disabledDate && !collaborator.disabled)) && Object.keys(collaborator).length > 0 ? 'has-error' : ''}`}
            date={collaborator.disabledDate}
            error={collaborator.disabledDate}
            onChange={(value) => handleChangeValue(value, index, 'disabledDate')}
          />
        </div>
        <div
          className={`${sizesClass.disabled} div-td text-truncate d-flex align-items-center justify-content-center`}>
          <BwmCheckbox
            name={`disabled_${collaborator?.id}`}
            className="form-control form-control-cell"
            formClassName={`${((collaborator.disabledDate && collaborator.disabled) || (!collaborator.disabledDate && !collaborator.disabled)) && Object.keys(collaborator).length > 0 ? 'has-error' : ''}`}
            checked={collaborator.disabled}
            onChange={(e) => handleChangeValue(e.target.checked, index, 'disabled')}
          />
        </div>
        <div
          className={`${sizesClass.save} div-td text-truncate d-flex align-items-center justify-content-center`}>
          <button
            disabled={!editedIds[index]}
            className="btn btn-primary btn-cell"
            onClick={() => onUpdate(collaborator, index)}>
            {labels.SAVE}
          </button>
        </div>
      </div>
    )
  }

  const _renderRows = () => {
    const newRows = []
    rows.forEach((collaborator, index) => newRows.push(row(collaborator, index)))
    if (!props.disabled) newRows.push(row(newRow))
    return newRows
  }

  return (
    <>
      <div className="d-flex align-items-center mb-2">
        <DeleteButton
          disabled={Object.keys(checkedItems)?.length === 0}
          onClick={deleteArcoCollaboratorSubject}
        />
      </div>

      <div id="arco-collaborators-list" className="star-table arco-collaborators-list">
        <div className="text-start header-table">
          <div
            className={`${sizesClass.checkbox} text-center div-td d-flex align-items-center justify-content-center`}>
            <input
              disabled={!props.collaborators?.length > 0}
              type="checkbox"
              checked={allCheck}
              onChange={(e) => handleAllCheckChange(e)}
            />
          </div>
          <div className={`${sizesClass.code} div-td`}>{labels.CODE}</div>
          <div className={`${sizesClass.fullName} div-td`}>{labels.NAME_AND_SURNAME}</div>
          <div className={`${sizesClass.fiscalCode} div-td`}>{labels.FISCAL_CODE}</div>
          <div className={`${sizesClass.disabledDate} div-td`}>{labels.END_DATE}</div>
          <div className={`${sizesClass.disabled} div-td text-center`}>{labels.ENABLED}</div>
          <div className={`${sizesClass.save} div-td text-center`}>{labels.EDIT}</div>
        </div>
        {renderRows}
      </div>
    </>
  )
}
