import axiosService from '../../../services/axios-service'
import { PopupError } from '../../shared/popups/PopupError'
import { base64ToArrayBuffer, downloadFile, notifySuccess } from '../../shared/Utility'
import * as actions from '../../../actions'
import getText from '../../shared/i18n/labels'
const txtRegexp = new RegExp('.txt', 'ig')

export const downloadConsistencyReceiptTxt = (communication) => {
  let url = `/api/cora/custom/cora-communications/downloadConsistencyReceiptTxt/${communication.subject.id}/${communication.id}`
  callDownloadFile(url, communication.txtFilename)
}

export const downloadOriginalReceipt = (communication) => {
  let url = `/api/cora/custom/cora-communications/downloadOriginalReceipt/${communication.subject.id}/${communication.id}`
  callDownloadFile(url, communication.txtFilename.replace(txtRegexp, ''))
}

export const downloadConsistencyFile = (communication) => {
  let url = `/api/cora/custom/cora-communications/downloadConsistencyFile/${communication.subject.id}/${communication.id}`
  if (communication.dispatchType === 'CONSISTENCY_RECEIPT') {
    callDownloadFile(url, communication.txtFilename.replace('.run.txt', '.p7m.enc'))
  } else {
    callDownloadFile(url, communication.txtFilename.replace('.run.txt', '.p7m'))
  }
}

export const downloadReceiptTxt = (communication, fileName = '') => {
  if (!communication.txtFilename.startsWith('ATPEC')) {
    //TRATTASI DI RICEVUTE CHE SI RIFERISCONO A COMUNICAZIONI NELLO STORICO
    if (fileName) {
      fileName = fileName.substring(0, 7) + '.' + communication.pecatFilename + '.run.txt'
    } else {
      fileName = 'ATPECZC.' + communication.pecatFilename + '.run.txt'
    }
  } else {
    fileName = communication.txtFilename //trattasi di ricevute che NON si riferiscono a comunicazioni nello storico
  }

  const type = fileName.substring(0, 7) //atpeczc e 03 possono trovarsi nella stessa posizione. si rende necessaria una disambiguazione

  let url = `/api/cora/custom/cora-communications/downloadReceiptTxt/${communication.subject.id}/${communication.id}/${type}`
  callDownloadFile(url, fileName)
}

export const downloadDgn = (communication) => {
  let url = `/api/cora/custom/cora-communications/downloadDgn/${communication.subject.id}/${communication.id}`
  callDownloadFile(url, communication.txtFilename.replace(txtRegexp, '.dgn'))
}

/**
 *
 * @param {*} communication
 * @param {*} onSuccess funzione da eseguire dopo lo scaricamento dei dati
 */
export const downloadTxt = (communication, onSuccess) => {
  let url = `/api/cora/custom/cora-communications/downloadTxt/${communication.subject.id}/${communication.id}`
  callDownloadFile(url, communication.txtFilename, onSuccess)
}

export const downloadPecTz = (communication) => {
  let url = `/api/cora/custom/cora-communications/downloadPecTz/${communication.subject.id}/${communication.id}`
  callDownloadFile(url, 'PECAT.' + communication.pecatFilename + '.zip')
}

export const downloadPdf = (communication) => {
  let url = `/api/cora/custom/cora-communications/downloadPdf/${communication.subject.id}/${communication.id}`
  callDownloadFile(url, communication.txtFilename.replace(txtRegexp, '.pdf'))
}

export const downloadErrorReport = (communication) => {
  const labels = getText(localStorage.getItem('language') || 'it')

  actions.downloadCommunicationErrorReport(communication.id).then(
    (res) => {
      if (res.length > 0) {
        downloadFile(res, `Report_errori_${communication.txtFilename}.txt`)
        notifySuccess(labels.FILE_DOWNLOADED)
      } else {
        PopupError({ text: labels.REPORT_ERRORS_NOT_GENERATED_FOR_THIS_COMMUNICATION })
      }
    },
    (err) => {
      PopupError({ text: labels.REPORT_ERRORS_NOT_GENERATED_FOR_THIS_COMMUNICATION })
    }
  )
}

/**
 *
 * @param {Array[number]} communicationIds
 */
export const downloadErrorReportBulk = (communicationIds) => {
  const labels = getText(localStorage.getItem('language') || 'it')

  actions.downloadCommunicationErrorReportBulk(communicationIds).then(
    (res) => {
      if (res.length > 0) {
        downloadFile(base64ToArrayBuffer(res), `Report_errori.zip`)
        notifySuccess(labels.FILE_DOWNLOADED)
      } else {
        PopupError({ text: labels.REPORT_ERRORS_NOT_GENERATED })
      }
    },
    (err) => {
      PopupError({ text: labels.REPORT_ERRORS_NOT_GENERATED })
    }
  )
}

/**
 * esegue una chiamata GET all'url specificato
 * @param {string} url
 * @param {string} fileName
 * @param {Function} onSuccess opzionale
 */
function callDownloadFile(url = '', fileName = '', onSuccess) {
  const labels = getText(localStorage.getItem('language') || 'it')

  const axiosInstance = axiosService.getInstance()
  axiosInstance({
    url: url,
    method: 'GET',
    responseType: 'blob'
  }).then(
    (response) => {
      if (response?.data?.size > 0) {
        if (!onSuccess) {
          downloadFile(response.data, fileName)
          notifySuccess(labels.FILE_DOWNLOADED)
        } else {
          onSuccess(response.data, fileName)
        }
      } else {
        PopupError({ text: labels.FILE_NOT_FOUND })
      }
    },
    (err) => {
      new Response(err.response?.data)
        .text()
        .then((text) => PopupError({ text: text || labels.FILE_NOT_FOUND }))
    }
  )
}
