import { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import { isNotEmpty } from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'
import * as Utility from '../../../shared/Utility'
import axiosService from '../../../../services/axios-service'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// FORM
import { ControllingPersonList } from './NewControllingPersonList'
import { ControllingPersonForm } from './NewControllingPersonForm'
import { HolderForm } from './NewHolderForm'
import { PaymentForm } from './NewPaymentForm'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

// COMPONENTS
import { Tabs } from '../../../shared/Tabs'
import { PopupError } from '../../../shared/popups/PopupError'

import '../../../../styles/relationship-crs.css'

/* eslint-disable */
const defaultSize = (size = null) => ({
  progressive: Utility.getSize(size, { additionalClass: `min-w-15ch` }),
  relationshipNumber: Utility.getSize(size, { additionalClass: `min-w-40ch` }),
  relationshipType: Utility.getSize(size, { additionalClass: `min-w-79ch` }),
  'tab-holder': {
    personType: Utility.getSize(size, { default: 'w-100' }),
    identifier: Utility.getSize(size, { additionalClass: `min-w-36ch` }),
    fiscalIdentifier: Constants.tinSize(size),
    holderType: Utility.getSize(size, { additionalClass: `min-w-55ch` }),
    companyName: Utility.getSize(size, { default: 'w-40', md: 'w-80', additionalClass: 'flex-grow-1' }),
    lastName: Utility.getSize(size, { default: 'w-10', md: 'w-20', additionalClass: 'flex-grow-1' }),
    firstName: Utility.getSize(size, { default: 'w-10', md: 'w-20', additionalClass: 'flex-grow-1' }),
    birthDate: Constants.dateSize(size),
    birthLocation: Utility.getSize(size, { default: 'w-18' }),
    birthProvince: Constants.provinceSize(size),
    completeAddress: Utility.getSize(size, { default: 'w-30', lg: 'w-40', md: 'w-55' }),
    country: Utility.getSize(size, { default: 'w-30', lg: 'w-40', md: 'w-35' })
  },
  'tab-controlling-person': {
    // form
    form_fiscalIdentifier: Utility.getSize(size, { default: 'w-12', lg: 'w-14', md: 'w-16' }),
    form_personType: Utility.getSize(size, { default: 'w-28', lg: 'w-32', md: 'w-34' }),
    form_last_name: Utility.getSize(size, { default: 'w-25' }),
    form_first_name: Utility.getSize(size, { default: 'w-25' }),
    form_birthDate: Constants.dateSize(size),
    form_birthLocation: Utility.getSize(size, { default: 'w-20' }),
    form_address: Utility.getSize(size, { default: 'w-30', md: 'w-34' }),
    form_country: Utility.getSize(size, { default: 'w-30', md: 'w-32' }),
    // controlling list
    checkbox: Utility.getSize(size, { default: 'w-5' }),
    fiscalIdentifier: Utility.getSize(size, { default: 'w-17' }),
    name: Utility.getSize(size, { default: 'w-16' }),
    personType: Utility.getSize(size, { default: 'w-36' }),
    country: Utility.getSize(size, { default: 'w-26' })
  },
  'tab-payment': {
    leftSide: Utility.getSize(size, { default: 'w-37' }),
    rightSide: Utility.getSize(size, { default: 'w-63' }),
    // form
    yearRel: Utility.getSize(size, { default: 'w-100' }),
    amountRel: Utility.getSize(size, { default: 'w-50' }),
    currencyRel: Utility.getSize(size, { default: 'w-50' }),
    // payment list
    checkbox: Utility.getSize(size, { default: 'w-7' }),
    progressive: Utility.getSize(size, { default: 'w-11' }),
    paymentType: Utility.getSize(size, { default: 'w-41' }),
    amount: Utility.getSize(size, { default: 'w-28' }),
    currency: Utility.getSize(size, { default: 'w-13' })
  }
})
/* eslint-enable */

const getSize = () => {
  if (window.innerWidth <= Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export const RelationshipForm = (props) => {
  const dispatch = useDispatch()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [initialValues, setInitialValues] = useState({})
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [loading, setLoading] = useState(false)

  const [activeTab, setActiveTab] = useState('tab-holder')
  const [defaultTab, setDefaultTab] = useState()
  const [touched, setTouched] = useState(false)
  const [forceReload, setForceReload] = useState(false)

  const [holderForm, setHolderForm] = useState({})
  const [holderErrors, setHolderErrors] = useState({})

  const [balanceForm, setBalanceForm] = useState({})

  const [disabledTabControllingPerson, setDisabledTabControllingPerson] = useState()

  useEffect(() => {
    getCountries()
    getLocations()
    getAllCurrency()
  }, [])

  useEffect(() => {
    if (props.setLoading) props.setLoading(loading)
  }, [loading])

  useEffect(() => {
    setActiveTab('tab-holder')
    retrieveRelationship()
  }, [props.id, props.subject])

  const retrieveRelationship = (force = false) => {
    if (force) setForceReload(!forceReload)
    setLoading(true)
    if (props.id && props.subject?.id) {
      actions.getCrsRelationshipIdBySubjectId(props.id, props.subject.id).then((res) => {
        setNewInitialValues(res, null)
        setLoading(false)
        if (defaultTab) {
          setActiveTab(defaultTab)
          setDefaultTab(null)
        }
      })
    } else if (props.subject?.id) {
      getLastProgressive()
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const setNewInitialValues = (relationship, newCode = null) => {
    setActiveTab('tab-holder')
    setDisabledTabControllingPerson(
      relationship?.crsRelationshipHolder?.holderType?.code === 'CRS101' ? false : true
    )
    setHolderForm(relationship?.crsRelationshipHolder ? relationship.crsRelationshipHolder : {})
    setBalanceForm({
      id: relationship?.id,
      yearRel: props.subject?.referenceYear || new Date().getFullYear() - 1,
      amountRel: relationship?.balance || 0.0,
      currencyRel: relationship?.currency || {
        id: '58',
        isoCode: 'EUR',
        description: ''
      },
      closed: relationship?.closed || false,
      sleepy: relationship?.sleepy || false,
      notDocumented: relationship?.notDocumented || false
    })
    setInitialValues({
      id: relationship?.id || null,
      progressive: newCode || '0' + relationship?.progressive || '',
      relationshipNumber: relationship?.relationshipNumber || '',
      crsRelationshipNumberType: {
        id: relationship?.crsRelationshipNumberType?.id || ''
      },
      balance: relationship?.balance || 0,
      currency: { id: relationship?.currency?.id || 58 },
      closed: relationship?.closed || false,
      sleepy: relationship?.sleepy || false,
      notDocumented: relationship?.notDocumented || false
    })
  }

  const getLastProgressive = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance
      .get(`/api/crs/custom/crs-relationships/lastProgressive/${props.subject.id}`)
      .then((res) => {
        let newCode = parseInt(res.data) + 1
        setNewInitialValues({}, newCode)
      })
  }

  const getCountries = () => {
    dispatch(actions.getAllCountries())
  }

  const getLocations = () => {
    dispatch(actions.getAllLocations())
  }

  const getAllCurrency = () => {
    dispatch(actions.getAllCurrency())
  }

  const getErrors = (errors) => {
    let allErrors = cloneDeep(errors)
    let allFormErrorsMapping = cloneDeep(formErrorsMapping)
    if (activeTab === 'tab-holder' && holderErrors?.errors) {
      allErrors = { ...allErrors, ...holderErrors.errors }
      allFormErrorsMapping = [...formErrorsMapping, ...holderErrors.formErrorsMapping]
    }
    return Utility.extractErrors(allErrors, allFormErrorsMapping)
  }

  const formErrorsMapping = [
    {
      errorKey: 'relationshipNumber',
      errorLabel: labels.RELATIONSHIP_NUMBER
    },
    {
      errorKey: 'crsRelationshipNumberType.id',
      errorLabel: labels.NUMBER_TYPE
    }
  ]

  const validationSchema = Yup.object().shape({
    relationshipNumber: Yup.string().required(labels.REQUIRED_FIELD),
    crsRelationshipNumberType: Yup.object().shape({
      id: Yup.string().required(labels.REQUIRED_FIELD)
    })
  })

  return (
    <div className="border-top">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ errors }) => (
          <Form className={props.formOnBottom ? 'p-2 form-on-bottom' : ''}>
            {showErrorAlert && touched && (
              <div className={props.formOnBottom ? 'crs-relationship-form-on-bottom-error' : ''}>
                <ErrorListAlert errors={getErrors(errors)} hide={() => setShowErrorAlert(false)} />
              </div>
            )}
            <FormBody
              {...props}
              loading={loading}
              defaultTab={defaultTab}
              forceReload={forceReload}
              initialValues={initialValues}
              disabledTabControllingPerson={disabledTabControllingPerson}
              holderForm={holderForm}
              holderErrors={holderErrors}
              balanceForm={balanceForm}
              touched={touched}
              activeTab={activeTab}
              setLoading={setLoading}
              setHolderForm={setHolderForm}
              setBalanceForm={setBalanceForm}
              setDisabledTabControllingPerson={setDisabledTabControllingPerson}
              setActiveTab={setActiveTab}
              setShowErrorAlert={setShowErrorAlert}
              setHolderErrors={setHolderErrors}
              setTouched={setTouched}
              setDefaultTab={setDefaultTab}
              retrieveRelationship={retrieveRelationship}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

export const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [sizesClass, setSizesClass] = useState(getSize())

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const FIRST_TAB = labels.HOLDER_OF_THE_FINANCIAL_RELATIONSHIP
  const SECOND_TAB = labels.CONTROLLING_PERSONS
  const THIRD_TAB = labels.BALANCE_AND_PAYMENTS

  const holderRefData = useRef()
  const cpRefData = useRef()
  const balanceRefData = useRef()

  const [showPersonForm, setShowPersonForm] = useState(false)

  const [controllingPerson, setControllingPerson] = useState({})

  const selectTab = (tab) => props.setActiveTab(tab)

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  useEffect(() => {
    if (!props.touched) return
    const _tabErrors = {}
    if (errors && Object.keys(errors)?.length > 0) {
      _tabErrors['tab-holder'] = true
    }
    if (props.holderErrors?.errors && Object.keys(props.holderErrors?.errors)?.length > 0) {
      _tabErrors['tab-holder'] = true
    }
    setTabWithError(_tabErrors)
  }, [props.holderErrors])

  useEffect(() => {
    if (props.activeTab !== 'tab-controlling-person' && showPersonForm) setShowPersonForm(true)
  }, [props.activeTab])

  useEffect(() => {
    setTabWithError({})
    setValues(props.initialValues)
  }, [props.initialValues])

  const handlerShowPersonForm = (cp) => {
    const form = Object.assign({}, values)
    if (isNotEmpty(cp)) {
      setTabWithError({})
      setControllingPerson(cp)
    }
    setShowPersonForm(!showPersonForm)
    setValues(form)
  }

  const handlerChangeRelationshipNumber = (e) => {
    const form = Object.assign({}, values)
    form.relationshipNumber = e.target.value?.toUpperCase() || ''
    setValues(form)
  }

  const handlerChangeRelationshipNumberType = (e) => {
    const form = Object.assign({}, values)
    form.crsRelationshipNumberType = {
      id: e.target.value,
      code: e.target[e.target.selectedIndex].dataset.cod
    }
    setValues(form)
  }

  const refreshControllingPersonList = () => {
    cpRefData.current.getAllCrsControllingPeople()
  }

  const close = (retrieve = false, newRelationship = null) => {
    setTabWithError({})
    props.setTouched(false)
    setShowPersonForm(false)
    if (retrieve) props.retrieveRelationship(true)
    if (props.id) props.setDefaultTab(null)
    if (props.close) props.close(props.id ? null : newRelationship)
  }

  const addCrsRelationshipHolder = (tab = null) => {
    let newRelationshipHolder = holderRefData.current.getData()
    if (newRelationshipHolder?.personType === Constants.PF) newRelationshipHolder.holderType = null

    if (values) newRelationshipHolder.crsRelationship = values?.id
    props.setLoading(true)
    actions
      .addCrsRelationshipHolder(newRelationshipHolder)
      .then(
        (response) => {
          newRelationshipHolder = response
          addCrsRelationship(newRelationshipHolder, tab)
        },
        (errors) => PopupError({ text: labels.GENERIC_ERROR })
      )
      .then(() => props.setLoading(false))
      .catch(() => props.setLoading(false))
  }

  const addCrsRelationship = (newRelationshipHolder, tab = null) => {
    let balances = balanceRefData.current.getData()
    if (balances) {
      props.setLoading(true)
      let newRelationship = Object.assign({}, values)
      newRelationship.crsRelationshipNumberType = {
        id: parseInt(values?.crsRelationshipNumberType?.id || values?.crsRelationshipNumberType?.id)
      }
      newRelationship.crsRelationshipHolder = newRelationshipHolder
      let subject = { id: props.subject.id }
      newRelationship.crsSubject = subject
      newRelationship.includeInNextCommunication = true

      newRelationship.balance = balances.amountRel
      newRelationship.closed = balances.closed
      newRelationship.sleepy = balances.sleepy
      newRelationship.notDocumented = balances.notDocumented

      actions
        .addCrsRelationship(newRelationship)
        .then(
          (response) => {
            balanceRefData.current.savePayments(response.id)
            props.setDefaultTab(tab)
            if (!tab) Utility.notifySuccess(labels.RELATIONSHIP_SAVED_CORRECTLY)
            close(false, response)
            props.getRelationships(props.subject.id)
          },
          (errors) => PopupError({ text: labels.GENERIC_ERROR })
        )
        .then(() => props.setLoading(false))
        .catch(() => props.setLoading(false))
    }
  }

  const setTabWithError = (tabDict) => {
    const tabList = Object.entries(tabDict)
      .filter(([key, value]) => value === true) // Filtra solo dove il valore è true
      .map(([key]) => key) // Mappa solo le chiavi

    document.querySelectorAll('.tab-list-item').forEach((item) => {
      item.firstChild.classList.remove('text-danger')
    })
    tabList.forEach((tabId) => {
      // Add 'border-danger' to the element with id 'test'
      if (
        tabId !== 'tab-controlling-person' ||
        (tabId === 'tab-controlling-person' && !props.disabledTabControllingPerson)
      ) {
        document.getElementById(tabId).firstChild.classList.add('text-danger')
      }
    })
  }

  const handleTabsValidation = async () => {
    const errors = await validateForm(values)
    props.setTouched(true)
    const _tabErrors = {}
    const holderIsValid = holderRefData.current
      ? await holderRefData.current.handleValidation()
      : true

    // Validation first tab
    if (Object.keys(errors).length > 0 || !holderIsValid) {
      if (props.activeTab !== 'tab-holder') selectTab('tab-holder')
      props.setShowErrorAlert(true)
      _tabErrors['tab-holder'] = true
      setTabWithError(_tabErrors)
      return false
    }

    // validation second tab
    if (
      isNotEmpty(holderRefData.current.getPersonType()) &&
      holderRefData.current.getPersonType() === Constants.PNF &&
      !props.disabledTabControllingPerson &&
      cpRefData.current &&
      props.activeTab === 'tab-controlling-person'
    ) {
      let holderType = holderRefData.current.getHolderType()
      let controllingPerson = cpRefData.current.countControllingPerson() === 0
      if (holderType?.id === 1 && controllingPerson && !props.disabledTabControllingPerson) {
        props.setShowErrorAlert(true)
        _tabErrors['tab-controlling-person'] = true
        setTabWithError(_tabErrors)
        return false
      } else {
        cpRefData.current.setErrorControllingPerson()
      }
    }

    // Validation third tab
    if (
      values.balance === null ||
      values.balance === undefined ||
      (values?.balance === 0 && !balanceRefData.current.handleValidation())
    ) {
      if (props.activeTab !== 'tab-payment') selectTab('tab-payment')
      props.setShowErrorAlert(true)
      _tabErrors['tab-payment'] = true
      setTabWithError(_tabErrors)
      return false
    }

    props.setShowErrorAlert(false)
    setTabWithError({})
    props.setTouched(false)
    return true
  }

  const handleFormSubmit = async (tab = null) => {
    const isValid = await handleTabsValidation()
    if (isValid) {
      if (tab) props.setActiveTab(tab)
      addCrsRelationshipHolder(tab)
    }
  }

  const handleFormSubmitHolder = async (tab) => {
    if (props.activeTab !== tab) handleFormSubmit(tab)
  }

  const handlerTabHolder = (disabled) => {
    props.setDisabledTabControllingPerson(disabled)
  }

  const countries = useSelector((state) =>
    state.countries?.data.map((country) => {
      return { name: country.code, key: country.description }
    })
  )

  const locations = useSelector((state) =>
    state.locations?.data.map((location, index) => {
      return { name: location.location, key: index }
    })
  )

  const currencies = useSelector((state) =>
    state.currencies?.data.map((currency) => {
      return { name: currency.isoCode + ' - ' + currency.description, id: currency.id }
    })
  )

  return (
    <div className="row-detail bg-gray d-flex">
      <div className="tab-content relationship col-12">
        <div style={{ height: '274px' }}>
          <div className="p-1 pb-0 w-all">
            <div className="row mb-2">
              <div className={`${sizesClass.progressive} pe-2`}>
                <BwmInput
                  name="progressive"
                  label={labels.PROGRESSIVE}
                  className="form-control"
                  disabled={true}
                  maxLength="16"
                  value={values.progressive}
                  error={errors.progressive}
                  touched={props.touched}
                />
              </div>
              <div className={`${sizesClass.relationshipNumber} pe-2`}>
                <BwmInput
                  name="relationshipNumber"
                  label={`${labels.RELATIONSHIP_NUMBER}*`}
                  className="form-control"
                  value={values?.relationshipNumber}
                  error={errors?.relationshipNumber}
                  touched={props.touched}
                  onChange={(e) => handlerChangeRelationshipNumber(e)}
                />
              </div>
              <div className={`${sizesClass.relationshipType}`}>
                <BwmSelect
                  options={props.relationshipNumbersTypes}
                  name="relationshipType"
                  label={`${labels.NUMBER_TYPE}*`}
                  className="form-control"
                  showCode={true}
                  value={values?.crsRelationshipNumberType?.id}
                  error={errors?.crsRelationshipNumberType}
                  touched={props.touched}
                  onChange={(e) => handlerChangeRelationshipNumberType(e)}
                />
              </div>
            </div>
          </div>
          <Tabs
            forceRender={true}
            activeTab={props.activeTab}
            className="tabs-sm tab-content-crs"
            setActiveTab={handleFormSubmitHolder}>
            <div id="tab-holder" label={FIRST_TAB}>
              <HolderForm
                ref={holderRefData}
                sizes={sizesClass['tab-holder']}
                relationshipHolderTypes={props.relationshipHolderTypes}
                forceReload={props.forceReload}
                touched={props.touched && props.activeTab === 'tab-holder'}
                index={props.index}
                relationship={props.holderForm}
                locations={locations}
                countries={countries}
                handlerTabHolder={handlerTabHolder}
                setRelationship={props.setHolderForm}
                setErrors={props.setHolderErrors}
              />
            </div>
            <div
              id="tab-controlling-person"
              label={SECOND_TAB}
              disabled={props.disabledTabControllingPerson}>
              {showPersonForm ? (
                <ControllingPersonForm
                  sizes={sizesClass['tab-controlling-person']}
                  formOnBottom={true}
                  activeTab={props.activeTab}
                  controllingPerson={controllingPerson}
                  crsRelationshipHolder={props.holderForm}
                  relationship={values}
                  handlerShowPersonForm={handlerShowPersonForm}
                  setTabWithError={setTabWithError}
                  refreshList={refreshControllingPersonList}
                />
              ) : (
                <ControllingPersonList
                  ref={cpRefData}
                  sizes={sizesClass['tab-controlling-person']}
                  activeTab={props.activeTab}
                  subject={props.subject}
                  relationship={values}
                  handlerShowPersonForm={handlerShowPersonForm}
                />
              )}
            </div>
            <div id="tab-payment" label={THIRD_TAB}>
              <PaymentForm
                ref={balanceRefData}
                sizes={sizesClass['tab-payment']}
                activeTab={props.activeTab}
                subject={props.subject}
                currencies={currencies}
                relationship={props.balanceForm}
                setRelationship={props.setBalanceForm}
              />
            </div>
          </Tabs>
        </div>
        <div className="form-row mt-2 row align-item-center">
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <button className="btn btn-outline-primary me-4" onClick={() => close(true)}>
                <i className="thx-cancel thx-icon me-2" />
                {labels.CANCEL}
              </button>
              {/* Vista sulla destra */}
              {props.paginationComponent && (
                <div className="d-flex justify-content-end">{props.paginationComponent}</div>
              )}
              {window.innerWidth >= 1300 && (
                <div className="ms-4">
                  <b className="me-2">*</b>
                  <span>{labels.REQUIRED_FIELD_FOR_FORM_COMPLETION}</span>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center">
              <button
                disabled={props.disabled || showPersonForm}
                onClick={() => handleFormSubmit()}
                className="btn btn-primary">
                <i className="thx-floppy-disk thx-icon me-2" />
                {labels.SAVE}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
