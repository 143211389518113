import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { base64ToArrayBuffer, downloadFile, notifySuccess } from '../../../shared/Utility'
import axiosService from '../../../../services/axios-service'
import getText from '../../../shared/i18n/labels'

// MODAL
import { Modal } from 'react-bootstrap'

// FORM
import EmailForm from './EmailForm'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'

export function CrsCommunicationModal({
  sentCommunications = [],
  showModal = false,
  closeModal = () => {},
  subject = {},
  emailAddress = ''
}) {
  const [show, setShow] = useState(false)

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  useEffect(() => {
    setShow(showModal)
  }, [])

  const onClose = () => {
    setShow(false)
    closeModal()
  }

  const subjectId = subject?.id

  const buttonAssistance = () => {
    const link = document.createElement('a')
    let subjectName = subject ? ' - Soggetto: ' + subject.companyName : ''
    let customerName = subject.customer ? 'Utente: ' + subject.customer?.companyName : ''
    link.href = `mailto:assistenza@starinfostudio.it?subject=CRSWEB - Richiesta assistenza ${customerName} ${subjectName}`
    document.body.appendChild(link)
    link.click()
  }

  const downloadXml = (communication) => {
    let url = `/api/crs/custom/crs-communications/downloadXml/${subjectId}/${communication.id}`
    callDownloadService(url, communication.xmlFilename)
  }

  const downloadDgn = (communication) => {
    let url = `/api/crs/custom/crs-communications/downloadDgn/${subjectId}/${communication.id}`
    callDownloadService(url, communication.xmlFilename.replace('.xml', '.dgn'))
  }

  const downloadPecTz = (communication) => {
    let url = `/api/crs/custom/crs-communications/downloadPecTz/${subjectId}/${communication.id}`
    callDownloadService(url, 'PECAT.' + communication.sidFilename + '.zip')
  }

  const downloadPdf = (communication) => {
    downloadFile(
      new Blob([base64ToArrayBuffer(communication.pdf)], { type: 'application/pdf' }),
      communication.xmlFilename.replace('.xml', '.pdf')
    )
    notifySuccess(labels.FILE_DOWNLOADED)
  }

  const callDownloadService = (url, file) => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      if (response) {
        if (response?.data?.size > 0) {
          downloadFile(response.data, file)
          notifySuccess(labels.FILE_DOWNLOADED)
        } else {
          PopupError({ text: labels.FILE_NOT_FOUND })
        }
      }
    })
  }

  const sendEnabled =
    sentCommunications?.length > 0 &&
    sentCommunications[0]?.communicationStatus !== 'CREATED_WITH_ERROR'

  const renderRows = (communications) => {
    return communications.map((communication) => {
      let status = ''
      let isError =
        communication.communicationStatus === 'RECEIPT_NOT_ACCEPTED' ||
        communication.communicationStatus === 'ERROR_SID' ||
        communication.communicationStatus === 'CREATED_WITH_ERROR'
          ? true
          : false

      if (communication.communicationStatus === 'CREATED') {
        status = 'Creata - In attesa di ricevuta'
      } else if (communication.communicationStatus === 'CREATED_WITH_ERROR') {
        status = 'Creata con errori'
      } else if (communication.communicationStatus === 'RECEIPT_ACCEPTED') {
        status = 'Comunicazione accolta'
      } else if (communication.communicationStatus === 'RECEIPT_NOT_ACCEPTED') {
        status = 'Comunicazione NON accolta'
      }

      return (
        <div key={'communication-row-' + communication.id} className="row row-table">
          <>
            <div className="w-10 div-td">{communication.referenceYear}</div>
            <div className="w-30 div-td">CRS</div>
            <div className="w-20 div-td">XML</div>
            <div className={isError ? 'w-30 div-td  color-red' : 'w-30 div-td'}>{status}</div>
            <div className="w-15 div-td text-center containerPopOver">
              <div className={'xml'} onClick={() => downloadXml(communication)}>
                <i className="thx-text-file thx-icon" />
              </div>
              {communication.communicationStatus === 'CREATED' ? (
                <div className={'zip'} onClick={() => downloadPecTz(communication)}>
                  <i className="thx-zip thx-icon me-2" />
                </div>
              ) : (
                <div className={'dgn'} onClick={() => downloadDgn(communication)}>
                  <i className="thx-list-file thx-icon me-2" />
                </div>
              )}
              {communication?.pdf && (
                <div className={'pdf'} onClick={() => downloadPdf(communication)}>
                  <i className="thx-pdf thx-icon" />
                </div>
              )}
            </div>
          </>
        </div>
      )
    })
  }

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={onClose}
      dialogClassName="modal-70w"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <div className="bar"></div>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>
            {labels.SID_PROCESSING_RESULT}: {subject?.companyName}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="communication">
          <div className="star-table">
            <div className="row text-start header-table">
              <>
                <div className="w-10 div-td">{labels.PERIOD}</div>
                <div className="w-30 div-td">{labels.TYPE_OF_COMMUNICATION}</div>
                <div className="w-20 div-td">{labels.TYPE_OF_SENDING}</div>
                <div className="w-30 div-td">{labels.STATUS}</div>
                <div className="w-15 text-center div-td">{labels.DOWNLOAD}</div>
              </>
            </div>
            {renderRows(sentCommunications)}
          </div>
          {sendEnabled ? (
            <EmailForm
              closeModal={onClose}
              sentCommunications={sentCommunications}
              emailAddress={emailAddress}
              flagPec={subject?.coraSubjectSid?.flagPec}
              subject={subject}
            />
          ) : (
            <div className="form-row mt-4 justify-content-between align-item-center">
              <button className="btn btn-outline-primary " onClick={onClose}>
                <i className="thx-cancel thx-icon me-2" />
                {labels.CANCEL}
              </button>
              <button type="button" className="btn btn-primary " onClick={buttonAssistance}>
                <i className="thx-question thx-icon me-2" />
                {labels.REQUEST_ASSISTANCE}
              </button>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}
