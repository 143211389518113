import { useState } from 'react'
import { useSelector } from 'react-redux'
import { downloadRelationshipsCsvTemplate } from '../relationships/utils/CoraTemplateUtils'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// MODAL
import { Modal } from 'react-bootstrap'

// FORM
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { BwmCheckbox } from '../../../shared/form/BwmCheckbox'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { UploadFileLabel } from '../../../shared/form/UploadFileLabel'
import { InfoBox } from '../../../shared/InfoBox'
import { notifySuccess } from '../../../shared/Utility'

export default function CoraImportRelationsModal({ subject, show, onClose, onSuccess }) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [dataFile, setDataFile] = useState([])
  const [errorDataFile, setErrorDataFile] = useState({ isValid: true, msg: '' })
  const [loadingSpinner, setLoadingSpinner] = useState(false)
  const [includedInNextCommunication, setIncludedInNextCommunication] = useState(false)
  const [charSeparator, setCharSeparator] = useState(';')

  const onChangeIncludedInNextCommunication = () => {
    setIncludedInNextCommunication(!includedInNextCommunication)
  }

  const checkFileName = (name) => {
    return name.split('.').pop().toLowerCase() === 'csv'
  }

  const handleUploadFile = (val) => {
    if (!val) {
      setErrorDataFile({ isValid: false, msg: labels.REQUIRED_FILE })
    }

    let files = []
    for (let file of val.target.files) {
      if (!checkFileName(file.name)) {
        setErrorDataFile({
          isValid: false,
          msg: labels.INVALID_FILE_EXTENSION
        })
      } else {
        setErrorDataFile({ isValid: true, msg: '' })
        files.push(file)
      }
    }
    if (files.length > 0) {
      setDataFile(files)
    }
  }

  const handleSendDataFile = () => {
    let formData = new FormData()
    formData.append('subjectId', subject?.id)
    formData.append('includedInNextCommunication', includedInNextCommunication)
    formData.append('separator', charSeparator)
    if (dataFile?.length === 1) {
      formData.append('file', dataFile[0])
    } else {
      setErrorDataFile({
        isValid: false,
        msg: labels.ONLY_ONE_CSV_FILE
      })
      return
    }
    setLoadingSpinner(true)
    actions.uploadRelationshipCsvFile(formData).then(
      (res) => {
        if (!res.error) {
          const messageSplit = res.trim().split(/\r?\n/)
          if (messageSplit.length > 1) {
            let blob = new Blob([res], { type: 'text/plain' })
            let textFile = window.URL.createObjectURL(blob)
            let fragment = (
              <>
                <a
                  download={`${labels.IMPORT_RELATIONSHIPS_FROM_CSV_FILE_ON_DATE} ${new Date().toLocaleDateString()}`}
                  id="downloadErrorFile"
                  href={textFile}>
                  {labels.DOWNLOAD_ERROR_FILE}
                </a>
              </>
            )
            PopupSuccess({
              dataList: [messageSplit[0], fragment]
            })
          } else {
            const relations = messageSplit[0].split(' ')[3]
            notifySuccess(
              `${labels.CORRECTLY_READ} ${relations} ${labels.LINES}: ${labels.IMPORT_RELATIONSHIPS_COMPLETED}`
            )
          }
          onSuccess()
          onClose()
          setLoadingSpinner(false)
        } else {
          setLoadingSpinner(false)
          setDataFile([])
        }
      },
      (err) => {
        setLoadingSpinner(false)
        PopupError({ text: err?.title || labels.GENERIC_ERROR })
      }
    )
  }

  return (
    <>
      {loadingSpinner && <PageSpinner />}
      <Modal
        backdrop="static"
        show={show}
        onHide={onClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton className="ps-3">
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.IMPORT_RELATIONSHIPS}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-3">
            <div className="row justify-content-between align-items-center mb-3">
              <div className="col-4">
                <div className="image-upload">
                  <UploadFileLabel label={labels.LOAD_CSV_FILE} forLabel={'dataFile'} />
                  <input
                    id={`dataFile`}
                    type="file"
                    name={`dataFile`}
                    onChange={(e) => handleUploadFile(e)}
                    accept=".csv"
                  />
                </div>
              </div>
              <div className="col-8 text-center">
                <div className="certificatiLoaded ps-0 ms-0 text-center">
                  {!errorDataFile.isValid ? (
                    <div className="certificatiLoaded-error">{errorDataFile.msg}</div>
                  ) : (
                    <div>
                      {dataFile.length > 0 &&
                        dataFile.map((file, index) => {
                          return (
                            <div
                              key={'blue-import' + { index }}
                              className="green-label text-truncate">
                              <i className="thx-check thx-icon me-2" />
                              {file.name}
                            </div>
                          )
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4" style={{ fontSize: '14px' }}>
              <div className="col-12">
                <div className="pt-1 pb-3">
                  <BwmCheckbox
                    name="includedInNextComm"
                    label={
                      labels.SELECT_IF_YOU_WANT_TO_INCLUDE_THE_RELATIONSHIPS_IN_THE_NEXT_COMMUNICATION
                    }
                    className="form-control"
                    checked={includedInNextCommunication}
                    onChange={onChangeIncludedInNextCommunication}
                  />
                </div>
                <div className=" pt-1 pb-1">
                  <label className="form-group label me-1">Separatore di dati:</label>
                  <input
                    style={{ maxWidth: '20px', textAlign: 'center' }}
                    maxLength="1"
                    type="text"
                    value={charSeparator}
                    onChange={(e) => setCharSeparator(e.target.value)}></input>
                </div>
              </div>
              <div className="col-12 mt-4">
                <InfoBox
                  simplePointsArr={[
                    {
                      title: labels.INSTRUCTIONS,
                      text: labels.IF_YOU_WANT_TO_INSERT_RELATIONSHIPS_WITHOUT_UNIQUE_CODE_FILL_THE_POSITION_FIELD_WITH_VALUE_1_IN_CORRESPONDENCE_WITH_THE_FIRST_OWNER
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="d-flex col-8 justify-content-start">
              <button onClick={() => onClose()} className="btn btn-outline-primary">
                <i className="thx-cancel thx-icon me-2" />
                {labels.CANCEL}
              </button>
              <button
                onClick={() => downloadRelationshipsCsvTemplate()}
                className="btn btn-outline-primary ms-2 ">
                <i className="thx-export thx-icon me-2 mt-1" />
                {labels.DOWNLOAD_TEMPLATE_CSV}
              </button>
            </div>
            <div className="btngroup d-flex col-4 justify-content-end">
              <button
                onClick={() => handleSendDataFile()}
                disabled={!errorDataFile.isValid || !dataFile || dataFile.length === 0}
                className="btn btn-primary w-45">
                {labels.CONFIRM}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
