import { useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import * as Constants from '../../../../config/Constants'
import axiosService from '../../../../services/axios-service'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// MODAL
import Modal from 'react-bootstrap/Modal'

// BUTTONS
import { BtnEmpty } from '../../../shared/BtnEmpty'

// COMPONENTS
import RowSpinner from '../../../shared/spinner/Spinner'
import { notifySuccess } from '../../../shared/Utility'

export function CommunicationsHistoryModal(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [communicationsHistory, setCommunicationsHistory] = useState([])

  const getHistory = (subjectId) => {
    setLoading(true)
    setCommunicationsHistory([])
    actions.getComureiCommunicationsHistoryBySubjectId(subjectId).then((res) => {
      setCommunicationsHistory(res)
      setLoading(false)
    })
  }

  const openModal = () => {
    getHistory(props.subjectId)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const callDownloadService = (url, file) => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file)
        document.body.appendChild(link)
        link.click()
        notifySuccess(labels.FILE_DOWNLOADED)
      }
    })
  }

  const downloadTxt = (communication) => {
    let url = `/api/comurei/custom/comurei-communications/downloadTxt/${props.subjectId}/${communication.id}`
    callDownloadService(url, communication.filename)
  }

  const downloadPdf = (communication) => {
    let url = `/api/comurei/custom/comurei-communications/downloadPdf/${props.subjectId}/${communication.id}`
    callDownloadService(url, communication.filename.replace('.txt', '.pdf'))
  }

  const renderTableHeader = () => {
    return (
      <>
        <div className="text-start header-table">
          <div className="w-15 div-td">{labels.CREATION_DATE}</div>
          <div className="w-30 div-td">{labels.USER}</div>
          <div className="w-20 div-td">{labels.COMMUNICATION_TYPE}</div>
          <div className="w-25 div-td">{labels.SECTION}</div>
          <div className="w-10 div-td">{labels.DOWNLOAD}</div>
        </div>
      </>
    )
  }

  const renderRows = (communications) => {
    if (loading) {
      return <RowSpinner />
    } else if (!communicationsHistory || communicationsHistory?.length === 0) {
      return (
        <div className="d-flex flex-column align-items-center border pt-2">
          <i
            className="thx-warning-triangle thx-warning-triangle-grey thx-icon"
            style={{ width: '30px', height: '30px' }}
          />
          <h6>{labels.NO_RESULTS}</h6>
        </div>
      )
    }
    return communications.map((communication, index) => {
      let section = ''
      if (communication.registryReason) {
        section = section + 'Indagini Bancarie/'
      }

      if (communication.monitoringReason) {
        section = section + 'Monitoraggio Fiscale/'
      }

      if (communication.factaReason) {
        section = section + 'FATCA/CRS'
      }

      if (section.endsWith('/')) {
        section = section.substring(0, section.length - 1)
      }
      return (
        <div key={index} className={'text-start row-table'}>
          <div className="w-15 div-td text-truncate">
            {moment(communication.creationDate).format(Constants.FORMAT_DATE_AND_TIME)}
          </div>
          <div className="w-30 div-td text-truncate">{communication.creationUser}</div>
          <div className="w-20 div-td text-truncate">
            {communication.communicationType.code} - {communication.communicationType.description}
          </div>
          <div className="w-25 div-td text-truncate">{section}</div>
          <div className="w-10 div-td text-truncate text-center containerPopOver">
            <div className="txt" onClick={() => downloadTxt(communication)}>
              <i className="thx-text-file thx-icon bg-primary" />
            </div>
            <div className="pdf" onClick={() => downloadPdf(communication)}>
              <i className="thx-pdf thx-icon bg-primary" />
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <>
      <button
        title="Mostra dettagli"
        className="btn btn-outline-primary btn-table"
        onClick={openModal}>
        <i className="thx-history thx-icon" />
      </button>
      <Modal
        backdrop="static"
        show={props.showModal || showModal}
        onHide={closeModal}
        dialogClassName="modal-70w"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>
              {labels.REI_HISTORY} - <strong>{props.subjectName}</strong>
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="star-table star-table-communications">
            {renderTableHeader()}
            {renderRows(communicationsHistory)}
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-0">
          <BtnEmpty text={labels.CLOSE} handlerOnClick={closeModal} />
        </Modal.Footer>
      </Modal>
    </>
  )
}
