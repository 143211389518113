import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Utility from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'
import axiosService from '../../../../services/axios-service'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// TABLE
import { TableUtils, baseSearchObject, fieldTypes } from '../../../shared/tables/TableUtils'
import { StarTable } from '../../../shared/tables/StarTable'

// FORM
import { ArcoRegistrationForm } from './NewArcoRegistrationForm'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'

import 'react-datepicker/dist/react-datepicker.css'
import { PopupConfirm } from '../../../shared/popups/PopupConfirm'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'

const getTableContainerWidth = () => {
  if (window.innerWidth <= Constants.BREAKPOINT_MD) return '220%'
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return '150%'
  return '100%'
}

const getOffset = () => {
  if (window.innerWidth <= Constants.BREAKPOINT_MD) return 538
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return 535
  return 525
}

export const ArcoRegistrationsList = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [operations, setOperations] = useState([])
  const [operation, setOperation] = useState()

  const [checkedItems, setCheckedItems] = useState([])
  const [arcoCasualOperations, setArcoCasualOperations] = useState([])

  const [searchObject, setSearchObject] = useState({ ...baseSearchObject })

  const tableName = 'arco-operations-subject'

  useEffect(() => {
    setRows(prepareRows(operations))
    setOperation(operation ? operation : operations?.[0])
  }, [operations])

  useEffect(() => {
    if (props.arcoSubject?.id) {
      actions
        .getAllArcoCasualOperations(props.arcoSubject.arcoSubjectType.code)
        .then((response) => {
          setArcoCasualOperations(response)
        })
    }
  }, [props.arcoSubject])

  const setNewSearchObject = (newSearchObject) => {
    if (isProfessionals()) {
      delete newSearchObject.counterparts
      newSearchObject.professionals = newSearchObject.professionalOrCounterpart
    } else {
      delete newSearchObject.professionals
      newSearchObject.counterparts = newSearchObject.professionalOrCounterpart
    }
    setSearchObject(newSearchObject)
  }

  const searchOperations = (newSearchObject, response = null) => {
    if (response) props.showClipModal(response)
    setDisabled(true)
    return actions
      .getArcoOperationsBySubjectId(props.arcoSubjectId, newSearchObject)
      .then((res) => {
        setDisabled(false)
        setOperations(res?.content || res?.data, res)
        setSearchObject({ ...baseSearchObject, totalElements: res.totalElements })
        return res
      })
      .finally(() => {
        setDisabled(false)
      })
  }

  const handleDeleteArcoOperation = () => {
    PopupConfirm({
      titleColor: 'popup-title-delete',
      text: labels.DELETE_SELECTED_ELEMENTS,
      handleClickConfirm: deleteArcoOperationSubject
    })
  }

  const deleteArcoOperationSubject = () => {
    //FIXME implementare delete in batch
    setLoading(true)
    const promises = []
    checkedItems.forEach((el) => promises.push(actions.deleteArcoOperation(el)))

    Promise.all(promises)
      .then(() => {
        searchOperations(searchObject)
        setCheckedItems([])
        Utility.notifySuccess(labels.REMOVED_SUCCESSFULLY)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        PopupError({ text: labels.ERROR_DELETING_SUBJECT })
      })
  }

  const printPersonalData = () => {
    setLoading(true)
    const axiosInstance = axiosService.getInstance()
    let idArray = []
    if (checkedItems.length === 0) {
      idArray.push(0)
    } else {
      checkedItems.forEach((arcoOperation) => {
        idArray.push(arcoOperation)
      })
    }
    axiosInstance({
      url: `/api/arco/custom/arco-registries-list-download/${props.arcoSubjectId}/${idArray}`,
      method: 'GET'
    })
      .then(
        (response) => {
          if (response)
            Utility.downloadFile(Utility.base64ToArrayBuffer(response.data), 'Anagrafica.pdf')
          Utility.notifySuccess(labels.FILE_DOWNLOADED)
          setCheckedItems([])
        },
        (err) => {
          if (err.errorKey === 'arcoOperationCheck') PopupError({ text: err.title })
          else PopupError({ text: labels.ERROR_DOWNLOADING_DOCUMENT })
        }
      )
      .finally(() => setLoading(false))
  }

  const printSAR = () => {
    setLoading(true)
    actions
      .downloadSAR(props.arcoSubjectId)
      .then(
        (data) => {
          Utility.downloadFile(Utility.base64ToArrayBuffer(data), 'Estrazione.zip')
          Utility.notifySuccess(labels.FILE_DOWNLOADED)
          setCheckedItems([])
        },
        (err) => {
          PopupError({
            text: err?.title || labels.ERROR_CREATING_FILE
          })
        }
      )
      .then(() => setLoading(false))
  }

  const printContract = () => {
    setLoading(true)
    const axiosInstance = axiosService.getInstance()
    let idArray = []
    if (checkedItems.length === 0) idArray.push(0)
    else checkedItems.forEach((arcoOperation) => idArray.push(arcoOperation))
    axiosInstance({
      url: `/api/arco/custom/arco-contracts-list-download/${props.arcoSubjectId}/${idArray}`,
      method: 'GET'
    }).then(
      (response) => {
        if (response) {
          Utility.downloadFile(Utility.base64ToArrayBuffer(response.data), 'Contratti.pdf')
          Utility.notifySuccess(labels.FILE_DOWNLOADED)
        }
        setCheckedItems([])
        setLoading(false)
      },
      (err) => {
        if (err.errorKey === 'arcoOperationCheck') PopupError({ text: err.title })
        else PopupError({ text: labels.ERROR_DOWNLOADING_DOCUMENT })
        setLoading(false)
      }
    )
  }

  const renderHeaderButtons = () => {
    return (
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="d-flex">
          <div className="me-2">
            <button
              type="button"
              disabled={checkedItems.length === 0 || props.disabled}
              className={`btn btn-outline-primary ${checkedItems.length === 0 ? 'disabled' : ''}`}
              onClick={handleDeleteArcoOperation}>
              <i className="thx-trash thx-icon me-2" />
              {labels.DELETE}
            </button>
          </div>
          <div className="me-2">
            <button
              type="button"
              className="btn btn-outline-primary"
              data-tip={labels.PRINT_PERSONAL_DATA}
              onClick={printPersonalData}>
              <i className="thx-export thx-icon me-2 mt-1" />
              {labels.PRINT_PERSONAL_DATA}
            </button>
          </div>
          <div className="me-2">
            <button
              type="button"
              className="btn btn-outline-primary"
              data-tip={labels.PRINT_CONTRACTS}
              onClick={printContract}>
              <i className="thx-export thx-icon me-2 mt-1" />
              {labels.PRINT_CONTRACTS}
            </button>
          </div>
          <div className="me-2">
            <button
              type="button"
              className="btn btn-outline-primary"
              data-tip={labels.PRINT_COMPLETE_LIST}
              onClick={printSAR}>
              <i className="thx-export thx-icon me-2 mt-1" />
              {labels.PRINT_COMPLETE_LIST}
            </button>
          </div>
          <div className="me-2">
            <button
              type="button"
              className="btn btn-outline-primary"
              data-tip={labels.REGISTRIES_LIST}
              onClick={() => props.setShowRegistryList(true)}>
              <i className="thx-list thx-icon me-2" />
              {labels.REGISTRIES_LIST}
            </button>
          </div>
        </div>
        <div>
          <button
            type="button"
            disabled={props.disabled}
            className="btn btn-primary"
            onClick={() => setOperation()}>
            <i className="thx-plus thx-icon" />
            {window.innerWidth > Constants.BREAKPOINT_MD && (
              <span className="ms-2">{labels.NEW_OPERATION}</span>
            )}
          </button>
        </div>
      </div>
    )
  }

  const setNewOperations = (content, res) => {
    setOperations(content)
    setSearchObject({ ...baseSearchObject, totalElements: res.totalElements })
  }

  const isProfessionals = () => {
    return props?.arcoSubject?.arcoSubjectType?.code === 'PB'
  }

  const formatCurrency = (amount, currencyCode) => {
    let body = {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }
    return amount.toLocaleString('it-IT', body)
  }

  const onClickRow = (row) => {
    setOperation(row.rowData)
  }

  const header = [
    TableUtils.composeHeader({
      fieldName: 'number',
      displayedName: labels.NUMBER,
      maxWidth: '1.1fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'profilingDate',
      type: fieldTypes.DATERANGE,
      displayedName: labels.PROFILING_DATE,
      maxWidth: '1fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'description',
      displayedName: labels.DESCRIPTION,
      maxWidth: '2fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'customers',
      displayedName: labels.CLIENTS,
      maxWidth: '2fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'professionalOrCounterpart',
      displayedName: isProfessionals() ? labels.PROFESSIONISTS : labels.COUNTERPARTS,
      maxWidth: '2fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'registrationDate',
      type: fieldTypes.DATERANGE,
      displayedName: labels.AUI,
      maxWidth: '1fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'contractRegistrationDate',
      displayedName: labels.CONTRACT_REGISTRATION_DATE,
      type: fieldTypes.DATERANGE,
      maxWidth: '1.8fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'contractAmount',
      displayedName: labels.CONTRACT_AMOUNT,
      maxWidth: '1.8fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'arcoCausalOperationType',
      type: fieldTypes.SELECTION,
      options: arcoCasualOperations,
      displayedName: labels.CONTRACT_TYPE,
      maxWidth: '2fr'
    }),
    TableUtils.composeHeader({
      fieldName: 'contractNotes',
      displayedName: labels.CONTRACT_NOTES,
      maxWidth: '2fr'
    })
  ]

  const prepareRows = (fromOperations = []) => {
    return fromOperations.map((operation) => {
      return TableUtils.composeRow({
        id: operation.id,
        rowData: operation,
        cellData: {
          number: TableUtils.composeCell({
            fieldName: 'number',
            fieldValue: operation.number
          }),
          profilingDate: TableUtils.composeCell({
            fieldName: 'profilingDate',
            fieldValue: Utility.formatDateForDisplay(operation.profilingDate)
          }),
          description: TableUtils.composeCell({
            fieldName: 'description',
            fieldValue: operation.description
          }),
          customers: TableUtils.composeCell({
            fieldName: 'customers',
            fieldValue: operation.customers
          }),
          professionalOrCounterpart: TableUtils.composeCell({
            fieldName: 'professionalOrCounterpart',
            fieldValue: isProfessionals() ? operation.professionals : operation.counteparts
          }),
          registrationDate: TableUtils.composeCell({
            fieldName: 'registrationDate',
            fieldValue: operation?.contractDate
              ? Utility.formatDateForDisplay(operation.contractDate)
              : ''
          }),
          contractRegistrationDate: TableUtils.composeCell({
            fieldName: 'contractRegistrationDate',
            fieldValue: operation?.contractDate
              ? Utility.formatDateForDisplay(operation.contractRegistrationDate)
              : ''
          }),
          contractAmount: TableUtils.composeCell({
            fieldName: 'contractAmount',
            additionalClass: 'text-end',
            fieldValue: formatCurrency(
              parseInt(operation.contractAmount == null ? '0.00' : operation.contractAmount),
              'EUR'
            )
          }),
          arcoCausalOperationType: TableUtils.composeCell({
            fieldName: 'arcoCausalOperationType',
            fieldValue: operation.arcoCausalOperationType
          }),
          contractNotes: TableUtils.composeCell({
            fieldName: 'contractNotes',
            fieldValue: operation.contractNotes
          })
        }
      })
    })
  }

  return (
    <div style={{ marginBottom: '60px' }}>
      {loading && <PageSpinner />}
      {renderHeaderButtons()}
      <StarTable
        id={operation?.id}
        version="1"
        headerColumns={header}
        checkedItems={checkedItems}
        rows={rows}
        tableContainerWidth={getTableContainerWidth()}
        getOffset={getOffset}
        formOnBottom={true}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={setNewSearchObject}
        searchCallBack={(searchObject) => {
          setOperation()
          return searchOperations(searchObject)
        }}
        onExecutedSearch={(content, res) => setNewOperations(content, res)}
        usePagination={true}
        withCheckBoxes={true}
        onClickCheck={setCheckedItems}
        onClickRow={onClickRow}
        formComponent={ArcoRegistrationForm}
        formComponentProps={{
          disabled: props.disabled || disabled || loading,
          arcoSubject: props.arcoSubject,
          formOnBottom: true,
          id: operation?.id,
          arcoSubjectId: props.arcoSubjectId,
          setLoading: setLoading,
          getOperations: (response) => searchOperations(searchObject, response),
          close: (newOperation) =>
            setOperation(newOperation ? newOperation : operation ? operation : undefined)
        }}
      />
    </div>
  )
}
