import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { base64ToArrayBuffer, downloadFile } from '../../shared/Utility'
import { blueDownloadAntiTerrorism } from '../../../actions'
import getText from '../../shared/i18n/labels'

// POPUPS
import { PopupSuccess } from '../../shared/popups/PopupSuccess'
import { PopupError } from '../../shared/popups/PopupError'

import 'react-confirm-alert/src/react-confirm-alert.css'

export default function BlueSearchResultPopup(props) {
  const data = props.data
  const subject = props.subject
  const [size, setSize] = useState(0)
  //const [searchTerm, setSearchTerm] = useState('');
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  useEffect(() => {
    if (data?.result && data.result[0].entity) {
      setSize(data.result[0].entity.length)
    } else {
      setSize(0)
    }
  }, [data])

  const downloadDocument = () => {
    blueDownloadAntiTerrorism(subject.id, data.savedEventId, null, lang).then(
      (response) => {
        if (response) {
          let ext = 'pdf'
          downloadFile(
            base64ToArrayBuffer(response),
            `${subject.denomination}-${moment(data.searchDate).format('YYYY-MM-DD')}.${ext}`
          )
          PopupSuccess({
            title: labels.SUCCESS_TITLE,
            text: labels.DOWLOAD_OK,
            ok: labels.SUCCESS_OK
          })
        } else {
          PopupError({
            text: labels.FILEERROR,
            title: labels.WARNING,
            request: labels.CONTACT_SUPPORT,
            close: labels.CLOSE
          })
        }
      },
      (err) => {
        PopupError({
          text: labels.FILEERROR,
          title: labels.WARNING,
          request: labels.CONTACT_SUPPORT,
          close: labels.CLOSE
        })
      }
    )
  }

  return (
    <>
      <p>
        <b>{`${labels.SEARCH_RESULT_1} ${size} ${labels.SEARCH_RESULT_2}`}</b>
      </p>
      <div className="d-flex w-100 mt-4 align-items-center justify-content-between">
        <div>
          <button onClick={() => props.onClose()} className="btn btn-primary">
            <i className="thx-cancel thx-icon me-2" />
            {labels.CLOSE}
          </button>
        </div>
        <div>
          <button onClick={() => downloadDocument()} className="btn btn-primary">
            <i className="thx-pdf thx-icon me-2" />
            {labels.VIEWPDF}
          </button>
        </div>
      </div>
    </>
  )
}
