import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CodiceFiscale from 'codice-fiscale-js'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import * as Utility from '../../../shared/Utility'
import getText from '../../../shared/i18n/labels'

// FORM
import { AutoCompileCustom } from '../../../shared/form/AutoCompileCustomB5'
import { ProvinceField } from '../../../shared/form/ProvinceFieldB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { DateField } from '../../../shared/form/DateFieldB5'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

// COMPONENTS
import { Tabs } from '../../../shared/Tabs'
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupConfirm, titleColors } from '../../../shared/popups/PopupConfirm'
import { PopupSubjectLicense } from '../../../shared/popups/PopupSubjectLicense'
import { CheckRemainingSubjects } from '../../../shared/auth/license/LicenseChecker'

import radioSel from '../../../../styles/images/radiobutton-selected.png'
import radioUnsel from '../../../../styles/images/radiobutton-unselected.png'
import '../../../../styles/comurei-form.css'

/* eslint-disable */
const defaultSize = (size = null) => ({
  'tab-sub': {
    personType: Utility.getSize(size, { default: 'w-61', lg: 'w-60', md: 'w-51' }),
    comureiSubjectType: Utility.getSize(size, { default: 'w-64', additionalClass: 'flex-grow-1' }),
    fiscalCode: Constants.fiscalCodeSize(size),
    companyName: Utility.getSize(size, { default: 'w-50', md: 'w-100', additionalClass: 'flex-grow-1' }),
    firstName: Utility.getSize(size, { default: 'w-24', md: 'w-50', additionalClass: 'flex-grow-1' }),
    lastName: Utility.getSize(size, { default: 'w-24', md: 'w-50', additionalClass: 'flex-grow-1' }),
    gender: Constants.genderSize(size),
    birthDate: Constants.dateSize(size),
    location: Utility.getSize(size, { default: 'w-18', additionalClass: 'flex-grow-1' }),
    province: Constants.provinceSize(size),
    communicationSection: Utility.getSize(size, { default: 'w-39', lg: 'w-40', md: 'w-49' })
  },
  'tab-resp': {
    fiscalCode: Constants.fiscalCodeSize(size),
    lastName: Utility.getSize(size, { default: 'w-17', lg: 'w-30', additionalClass: 'flex-grow-1' }),
    firstName: Utility.getSize(size, { default: 'w-17', lg: 'w-30', additionalClass: 'flex-grow-1' }),
    gender: Constants.genderSize(size),
    birthDate: Constants.dateSize(size),
    birthPlace: Utility.getSize(size, { default: 'w-20', lg: 'w-25' }),
    province: Constants.provinceSize(size),
    email: Utility.getSize(size, { default: 'w-15', lg: 'w-28' }),
    phone: Constants.phoneSize(size)
  },
  'tab-pec': {
    pecEmail: Utility.getSize(size, { default: 'w-50', md: 'w-100' }),
    pecManager: Utility.getSize(size, { default: 'w-50', md: 'w-100' }),
    pecManager_4: Utility.getSize(size, { default: 'w-50', md: 'w-100' }),
    effectiveDate: Constants.dateSize(size, 17),
    endDate: Constants.dateSize(size),
    reiEmail_2: Utility.getSize(size, { default: 'w-56', lg: 'w-90' }),
    reiEmail: Utility.getSize(size, { default: 'w-50', lg: 'w-50' }),
    pecEmailAddress: Utility.getSize(size, { default: 'w-42', lg: 'w-50' })
  },
  'tab-delete': {
    cancellationDate: Constants.dateSize(size, 24),
    leftSide: Utility.getSize(size, { default: 'w-35', lg: 'w-40', md: 'w-50' }),
    rightSide: Utility.getSize(size, { default: 'w-30', additionalClass: 'flex-grow-1' }),
    disponibilityAccounting: Utility.getSize(size, { default: 'w-18', lg: 'w-19' }),
    address: Utility.getSize(size, { default: 'w-50', md: '100' }),
    location: Utility.getSize(size, { default: 'w-40', md: '100' }),
    province: Constants.provinceSize(size),
    accountingAvailability: Utility.getSize(size, { default: 'w-18', lg: 'w-22' }),
    accountingAvailabilityTwo: Utility.getSize(size, { default: 'w-18', lg: 'w-19' }),
    incorporatingFiscalCodeOne: Constants.fiscalCodeSize(size, 40),
    incorporatingFiscalCodeTwo: Constants.fiscalCodeSize(size, 40),
    incorporatingFiscalCodeThree: Constants.fiscalCodeSize(size, 40)
  },
  'tab-transmission': {
    fiscalCode: Constants.fiscalCodeSize(size),
    comureiTransmissionType: Utility.getSize(size, { default: 'w-35', lg: 'w-35' })
  }
})
/* eslint-enable */

const getSize = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth < Constants.COMUREI_BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export const ComureiForm = (props) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [subjectSummary, setSubjectSummary] = useState({})
  const [initialValues, setInitialValues] = useState({})
  const [touched, setTouched] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tabError, _] = useState({
    subject: true,
    responsible: true,
    pec: true,
    canc: true
  })
  const [activeTab, setActiveTab] = useState('tab-sub')
  const [showErrorAlert, setShowErrorAlert] = useState(false)

  useEffect(() => {
    if (props.setLoading) props.setLoading(loading)
  }, [loading])

  useEffect(() => {
    if (!tabError.subject) {
      if (!document.getElementById('tab-sub').classList.contains('active')) {
        document.getElementById('tab-sub').click()
      }
    } else if (!tabError.responsible) {
      if (!document.getElementById('tab-resp').classList.contains('active')) {
        document.getElementById('tab-resp').click()
      }
    } else if (!tabError.pec) {
      if (!document.getElementById('tab-pec').classList.contains('active')) {
        document.getElementById('tab-pec').click()
      }
    } else if (!tabError.canc) {
      if (!document.getElementById('tab-delete').classList.contains('active')) {
        document.getElementById('tab-delete').click()
      }
    }
  }, [tabError])

  useEffect(() => {
    retrieveSubject(props.subjectId)
  }, [props.subjectId])

  useEffect(() => {
    CheckRemainingSubjects(
      Constants.APPLICATION_COMUREI,
      false,
      (subjectSummary) => {
        setSubjectSummary(subjectSummary)
      },
      labels,
      lang
    )
  }, [])

  const retrieveSubject = (id) => {
    setActiveTab('tab-sub')
    setLoading(true)
    if (id) {
      actions.getComureiSubjectDetail(id).then((response) => {
        setNewInitialValues(response.data)
        setLoading(false)
        setTabWithError({})
      })
    } else {
      setNewInitialValues()
      setTabWithError({})
      setLoading(false)
    }
  }

  const setNewInitialValues = (data) => {
    setInitialValues({
      id: data?.id || null,
      fiscalCode: data?.fiscalCode || '',
      companyName: data?.companyName || '',
      location: data?.location || '',
      province: data?.province || '',
      registryReason: data && data.registryReason !== null ? data.registryReason : true,
      monitoringReason: data?.monitoringReason || false,
      factaReason: data?.factaReason || false,
      birthDate: data?.birthDate ? new Date(data?.birthDate) : null,
      firstName: data?.firstName || '',
      lastName: data?.lastName || '',
      centralizedStructure: data?.centralizedStructure || false,
      gender:
        data?.gender ||
        ((data?.personType || Constants.PNF) === Constants.PF ? Constants.genderList[0].id : null),
      personType: data?.personType || Constants.PNF,
      comureiCommunicationType: {
        id: data?.comureiCommunicationType.id.toString() || '1'
      },
      comureiSubjectType: {
        id: data?.comureiSubjectType?.id || '',
        code: data?.comureiSubjectType?.code || ''
      },
      comureiSubjectResponsible: {
        id: data?.comureiSubjectResponsible?.id || null,
        fiscalCode: data?.comureiSubjectResponsible?.fiscalCode || '',
        firstName: data?.comureiSubjectResponsible?.firstName || '',
        lastName: data?.comureiSubjectResponsible?.lastName || '',
        gender: data?.comureiSubjectResponsible?.gender || 'FEMALE',
        birthDate: data?.comureiSubjectResponsible?.birthDate
          ? new Date(data?.comureiSubjectResponsible?.birthDate)
          : null,
        location: data?.comureiSubjectResponsible?.location || '',
        province: data?.comureiSubjectResponsible?.province || '',
        email: data?.comureiSubjectResponsible?.email || props.user?.login || '',
        phone: data?.comureiSubjectResponsible?.phone || ''
      },
      comureiSubjectPec: {
        id: data?.comureiSubjectPec?.id || null,
        pecEmail: data?.comureiSubjectPec?.pecEmail || '',
        pecManager: data?.comureiSubjectPec?.pecManager || '',
        effectiveDate: data?.comureiSubjectPec?.effectiveDate
          ? new Date(data.comureiSubjectPec.effectiveDate)
          : '',
        endDate: data?.comureiSubjectPec?.endDate ? new Date(data.comureiSubjectPec.endDate) : '',
        reiEmail: data?.comureiSubjectPec?.reiEmail || ''
      },
      comureiSubjectCancellation: {
        id: data?.comureiSubjectCancellation?.id || null,
        cancellationDate: data?.comureiSubjectCancellation?.cancellationDate
          ? new Date(data.comureiSubjectCancellation.cancellationDate)
          : null,
        address: data?.comureiSubjectCancellation?.address || '',
        incorporatingFiscalCodeOne:
          data?.comureiSubjectCancellation?.incorporatingFiscalCodeOne || '',
        incorporatingFiscalCodeTwo:
          data?.comureiSubjectCancellation?.incorporatingFiscalCodeTwo || '',
        incorporatingFiscalCodeThree:
          data?.comureiSubjectCancellation?.incorporatingFiscalCodeThree || '',
        location: data?.comureiSubjectCancellation?.location || '',
        province: data?.comureiSubjectCancellation?.province || '',
        comureiSubjectCancellationType: {
          id:
            data?.comureiSubjectCancellation?.comureiSubjectCancellationType?.id.toString() || '1',
          code:
            data?.comureiSubjectCancellation?.comureiSubjectCancellationType?.id.toString() || '1'
        }
      },
      comureiTransmission: {
        id: data?.comureiTransmission?.id || null,
        fiscalCode: data?.comureiTransmission?.fiscalCode || '',
        comureiTransmissionType: {
          id: data?.comureiTransmission?.comureiTransmissionType.id.toString() || '1'
        }
      },
      errorFiscalCode: false,
      errorComureiSubjectResponsibleFiscalCode: false
    })
  }

  const setTabWithError = (tabDict) => {
    const tabList = Object.entries(tabDict)
      .filter(([key, value]) => value === true) // Filtra solo dove il valore è true
      .map(([key]) => key) // Mappa solo le chiavi

    document.querySelectorAll('.tab-list-item').forEach((item) => {
      item.firstChild.classList.remove('text-danger')
    })
    tabList.forEach((tabId) => {
      // Add 'border-danger' to the element with id 'test'
      document.getElementById(tabId).firstChild.classList.add('text-danger')
    })
  }

  const validationSchema = Yup.object().shape({
    // VALIDATION SUBJECT
    personType: Yup.string().required(labels.SELECT_PERSON_TYPE),
    companyName: Yup.string().when('personType', {
      is: (val) => val === Constants.PNF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    firstName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    lastName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    gender: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    birthDate: Yup.date(labels.INVALID_DATE).when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) =>
        schema
          .required(labels.REQUIRED_FIELD)
          .test(
            'is-valid-date',
            labels.INVALID_DATE,
            (value) => !value || Utility.isValidDate(new Date(value))
          ),
      otherwise: (schema) => schema.notRequired()
    }),
    fiscalCode: Yup.string().required(labels.REQUIRED_FIELD),
    location: Yup.string().required(labels.REQUIRED_FIELD),
    province: Yup.string().required(labels.REQUIRED_FIELD),
    atLeastOne: Yup.boolean().test('at-least-one', labels.SELECT_AT_LEAST_ONE_TYPE, function () {
      const { monitoringReason, registryReason, factaReason } = this.parent
      return monitoringReason || registryReason || factaReason
    }),
    notMonitoringReasonSelectable: Yup.boolean().test(
      'notMonitoringReasonSelectable',
      labels.NOT_MONITORING_REASON_SELECTABLE,
      function () {
        const { comureiSubjectType, monitoringReason } = this.parent // accede ai valori dei campi
        return !(comureiSubjectType?.id?.toString() === '5' && monitoringReason)
      }
    ),
    notRegistryReasonSelectable: Yup.boolean().test(
      'notRegistryReasonSelectable',
      labels.NOT_REGISTRY_REASON_SELECTABLE,
      function () {
        const { comureiSubjectType, registryReason } = this.parent // accede ai valori dei campi
        return !(comureiSubjectType?.id?.toString() === '29' && registryReason)
      }
    ),
    comureiSubjectType: Yup.object().shape({
      id: Yup.string().required(labels.REQUIRED_FIELD),
      code: Yup.string()
    }),
    // VALIDATION RESPONSABLE
    comureiSubjectResponsible: Yup.object().shape({
      id: Yup.string().nullable(),
      fiscalCode: Yup.string().required(labels.REQUIRED_FIELD),
      firstName: Yup.string().required(labels.REQUIRED_FIELD),
      lastName: Yup.string().required(labels.REQUIRED_FIELD),
      gender: Yup.string().required(labels.REQUIRED_FIELD),
      birthDate: Yup.date(labels.INVALID_DATE)
        .required(labels.REQUIRED_FIELD)
        .test(
          'is-valid-date',
          labels.INVALID_DATE,
          (value) => !value || Utility.isValidDate(new Date(value))
        ),
      location: Yup.string().required(labels.REQUIRED_FIELD),
      province: Yup.string().required(labels.REQUIRED_FIELD),
      email: Yup.string().email(labels.INVALID_EMAIL).required(labels.REQUIRED_FIELD),
      phone: Yup.string().required(labels.REQUIRED_FIELD)
    }),
    // VALIDATION EMAIL
    comureiSubjectPec: Yup.object().shape({
      id: Yup.string().nullable(),
      pecEmail: Yup.string().when('$comureiCommunicationType', {
        is: (comureiCommunicationType) =>
          comureiCommunicationType?.id !== '2' && comureiCommunicationType?.id !== '3',
        then: (schema) => schema.email(labels.INVALID_EMAIL).required(labels.REQUIRED_FIELD),
        otherwise: (schema) => schema.email(labels.INVALID_EMAIL)
      }),
      reiEmail: Yup.string().when('$comureiCommunicationType', {
        is: (comureiCommunicationType) =>
          comureiCommunicationType?.id === '2' || comureiCommunicationType?.id === '3',
        then: (schema) => schema.email(labels.INVALID_EMAIL).required(labels.REQUIRED_FIELD),
        otherwise: (schema) => schema.email(labels.INVALID_EMAIL)
      }),
      pecManager: Yup.string().when('$comureiCommunicationType', {
        is: (comureiCommunicationType) => comureiCommunicationType?.id !== '2',
        then: (schema) => schema.required(labels.REQUIRED_FIELD),
        otherwise: (schema) => schema.nullable()
      }),
      effectiveDate: Yup.date(labels.INVALID_DATE)
        .required(labels.REQUIRED_FIELD)
        .test(
          'is-valid-date',
          labels.INVALID_DATE,
          (value) => !value || Utility.isValidDate(new Date(value))
        )
        .when('$comureiCommunicationType', {
          is: (comureiCommunicationType) => comureiCommunicationType?.id !== '2',
          then: (schema) => schema.required(labels.REQUIRED_FIELD),
          otherwise: (schema) => schema.nullable()
        }),
      endDate: Yup.date(labels.INVALID_DATE)
        .nullable()
        .test(
          'is-valid-date',
          labels.INVALID_DATE,
          (value) => !value || Utility.isValidDate(new Date(value))
        )
        .when(['effectiveDate', '$comureiCommunicationType'], (data, schema) => {
          return schema.test({
            test: (endDate) => {
              return !(
                data[1]?.id !== '2' &&
                endDate &&
                data?.[0] &&
                new Date(endDate) < new Date(data[0])
              )
            },
            message: labels.INVALID_DATE
          })
        })
    }),

    // VALIDATION CANCELLATION
    comureiSubjectCancellation: Yup.object().shape({
      id: Yup.string().nullable(),
      cancellationDate: Yup.date(labels.INVALID_DATE)
        .nullable()
        .test(
          'is-valid-date',
          labels.INVALID_DATE,
          (value) => !value || Utility.isValidDate(new Date(value))
        )
        .when('$comureiCommunicationType', {
          is: (comureiCommunicationType) => comureiCommunicationType?.id === '2',
          then: (schema) => schema.required(labels.REQUIRED_FIELD)
        }),
      address: Yup.string().when(['$comureiCommunicationType', '$comureiSubjectCancellation'], {
        is: (comureiCommunicationType, comureiSubjectCancellation) => {
          return (
            comureiCommunicationType?.id === '2' &&
            ['2', '3', '4'].includes(comureiSubjectCancellation?.comureiSubjectCancellationType?.id)
          )
        },
        then: (schema) => schema.required(labels.REQUIRED_FIELD)
      }),
      location: Yup.string().when(['$comureiCommunicationType', '$comureiSubjectCancellation'], {
        is: (comureiCommunicationType, comureiSubjectCancellation) => {
          return (
            comureiCommunicationType?.id === '2' &&
            ['2', '3', '4'].includes(comureiSubjectCancellation?.comureiSubjectCancellationType?.id)
          )
        },
        then: (schema) => schema.required(labels.REQUIRED_FIELD)
      }),
      province: Yup.string().when(['$comureiCommunicationType', '$comureiSubjectCancellation'], {
        is: (comureiCommunicationType, comureiSubjectCancellation) => {
          return (
            comureiCommunicationType?.id === '2' &&
            ['2', '3', '4'].includes(comureiSubjectCancellation?.comureiSubjectCancellationType?.id)
          )
        },
        then: (schema) => schema.required(labels.REQUIRED_FIELD)
      }),
      comureiSubjectCancellationType: Yup.object().shape({
        id: Yup.string().when(['$comureiCommunicationType'], {
          is: (comureiCommunicationType) => comureiCommunicationType?.id === '2',
          then: (schema) => schema.required(labels.REQUIRED_FIELD)
        }),
        code: Yup.string().when(['$comureiCommunicationType'], {
          is: (comureiCommunicationType) => comureiCommunicationType?.id === '2',
          then: (schema) => schema.required(labels.REQUIRED_FIELD)
        })
      }),
      incorporatingFiscalCodeOne: Yup.string().when(
        ['$comureiCommunicationType', '$comureiSubjectCancellation'],
        {
          is: (comureiCommunicationType, comureiSubjectCancellation) => {
            if (
              comureiCommunicationType?.id === '2' &&
              comureiSubjectCancellation?.comureiSubjectCancellationType?.id === '1'
            )
              return true
            return checkIncorporatingFiscalCodeOne(
              comureiCommunicationType,
              comureiSubjectCancellation
            )
          },
          then: (schema) => schema.required(labels.REQUIRED_FIELD)
        }
      ),
      incorporatingFiscalCodeTwo: Yup.string().when(
        ['$comureiCommunicationType', '$comureiSubjectCancellation'],
        {
          is: (comureiCommunicationType, comureiSubjectCancellation) => {
            return checkIncorporatingFiscalCodeOne(
              comureiCommunicationType,
              comureiSubjectCancellation
            )
          },
          then: (schema) => schema.required(labels.REQUIRED_FIELD)
        }
      ),
      incorporatingFiscalCodeThree: Yup.string().when(
        ['$comureiCommunicationType', '$comureiSubjectCancellation'],
        {
          is: (comureiCommunicationType, comureiSubjectCancellation) => {
            return checkIncorporatingFiscalCodeOne(
              comureiCommunicationType,
              comureiSubjectCancellation
            )
          },
          then: (schema) => schema.required(labels.REQUIRED_FIELD)
        }
      )
    }),

    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      labels.INVALID_FISCAL_CODE,
      (value) => !value
    ),
    errorComureiSubjectResponsibleFiscalCode: Yup.boolean().test(
      'errorComureiSubjectResponsibleFiscalCode',
      labels.INVALID_FISCAL_CODE,
      (value) => !value
    )
  })

  const formErrorsTabMapping = {
    'tab-sub': [
      {
        errorKey: 'comureiSubjectType.id',
        errorLabel: labels.SUBJECT_TYPE
      },
      {
        errorKey: 'atLeastOne',
        errorLabel: labels.COMMUNICATION_REASONS
      },
      {
        errorKey: 'notMonitoringReasonSelectable',
        errorLabel: labels.MONITORING_REASON
      },
      {
        errorKey: 'notRegistryReasonSelectable',
        errorLabel: labels.FINANCIAL_INVESTIGATIONS
      },
      {
        errorKey: 'errorFiscalCode',
        errorLabel: labels.FISCAL_CODE
      },
      {
        errorKey: 'fiscalCode',
        errorLabel: labels.FISCAL_CODE
      },
      {
        errorKey: 'companyName',
        errorLabel: labels.COMPANY_NAME
      },
      {
        errorKey: 'location',
        errorLabel: labels.LEGAL_SEAT
      },
      {
        errorKey: 'province',
        errorLabel: labels.PROVINCE
      },
      {
        errorKey: 'firstName',
        errorLabel: labels.FIRST_NAME
      },
      {
        errorKey: 'lastName',
        errorLabel: labels.LAST_NAME
      },
      {
        errorKey: 'birthDate',
        errorLabel: labels.BIRTH_DATE
      },
      {
        errorKey: 'gender',
        errorLabel: labels.GENDER
      }
    ],
    'tab-resp': [
      {
        errorKey: 'errorComureiSubjectResponsibleFiscalCode',
        errorLabel: labels.FISCAL_CODE
      },
      {
        errorKey: 'comureiSubjectResponsible.fiscalCode',
        errorLabel: labels.FISCAL_CODE
      },
      {
        errorKey: 'comureiSubjectResponsible.firstName',
        errorLabel: labels.FIRST_NAME
      },
      {
        errorKey: 'comureiSubjectResponsible.lastName',
        errorLabel: labels.LAST_NAME
      },
      {
        errorKey: 'comureiSubjectResponsible.gender',
        errorLabel: labels.GENDER
      },
      {
        errorKey: 'comureiSubjectResponsible.birthDate',
        errorLabel: labels.BIRTH_DATE
      },
      {
        errorKey: 'comureiSubjectResponsible.location',
        errorLabel: labels.BIRTH_PLACE
      },
      {
        errorKey: 'comureiSubjectResponsible.province',
        errorLabel: labels.PROVINCE
      },
      {
        errorKey: 'comureiSubjectResponsible.email',
        errorLabel: labels.EMAIL
      },
      {
        errorKey: 'comureiSubjectResponsible.phone',
        errorLabel: labels.PHONE
      }
    ],
    'tab-pec': [
      {
        errorKey: 'comureiSubjectPec.reiEmail',
        errorLabel: labels.PEC_ALREADY_COMMUNICATED_TO_CHANGE
      },
      {
        errorKey: 'comureiSubjectPec.pecEmail',
        errorLabel: labels.PEC_EMAIL
      },
      {
        errorKey: 'comureiSubjectPec.pecManager',
        errorLabel: labels.PEC_MANAGER
      },
      {
        errorKey: 'comureiSubjectPec.effectiveDate',
        errorLabel: labels.EFFECTIVE_DATE
      },
      {
        errorKey: 'comureiSubjectPec.endDate',
        errorLabel: labels.END_DATE_
      },
      {
        errorKey: 'comureiSubjectPec.pecEmailAddress',
        errorLabel: labels.PEC_EMAIL_ADDRESS
      }
    ],
    'tab-delete': [
      {
        errorKey: 'comureiSubjectCancellation.cancellationDate',
        errorLabel: labels.CANCELLATION_DATE
      },
      {
        errorKey: 'comureiSubjectCancellation.address',
        errorLabel: labels.ADDRESS
      },
      {
        errorKey: 'comureiSubjectCancellation.province',
        errorLabel: labels.PROVINCE
      },
      {
        errorKey: 'comureiSubjectCancellation.incorporatingFiscalCodeOne',
        errorLabel: labels.INCORPORATING_FISCAL_CODE_ONE
      },
      {
        errorKey: 'comureiSubjectCancellation.incorporatingFiscalCodeTwo',
        errorLabel: labels.INCORPORATING_FISCAL_CODE_TWO
      },
      {
        errorKey: 'comureiSubjectCancellation.incorporatingFiscalCodeThree',
        errorLabel: labels.INCORPORATING_FISCAL_CODE_THREE
      }
    ],
    'tab-transmission': []
  }

  const formTabsWithErrors = {
    'tab-sub': false,
    'tab-resp': false,
    'tab-pec': false,
    'tab-delete': false,
    'tab-transmission': false
  }

  const getActivePropsAndTab = (errors) => {
    var tabsWithErrors = findTabsWithErrors(errors, formTabsWithErrors, formErrorsTabMapping)
    setTabWithError(tabsWithErrors)

    var activeErrorsLabels = undefined
    if (activeTab) {
      activeErrorsLabels = formErrorsTabMapping[activeTab]
    }

    if (activeErrorsLabels) {
      errors = Utility.extractErrors(errors, activeErrorsLabels)
    }

    return errors
  }

  // Funzione per cercare i tab con errori
  const findTabsWithErrors = (errors, formTabsWithErrors, formErrorsTabMapping) => {
    const updatedTabsWithErrors = { ...formTabsWithErrors } // Clone the initial state

    // Helper function to check recursively if a field exists in errors (including nested objects)
    const checkFieldInErrors = (errors, fieldKey) => {
      const keys = fieldKey.split('.') // Handle nested keys, e.g., comureiSubjectResponsible.fiscalCode
      let current = errors

      // Traverse through nested objects
      for (let key of keys) {
        if (current[key]) {
          current = current[key] // Dive deeper if the key exists
        } else {
          return false // If any key is not found, return false
        }
      }
      return true // If all keys are found, return true
    }

    // Iterate over each tab in the mapping
    for (let tab in formErrorsTabMapping) {
      const fields = formErrorsTabMapping[tab]

      // Check if at least one field in this tab has an error
      const hasError = fields.some(({ errorKey }) => checkFieldInErrors(errors, errorKey))

      // Update the tab status based on whether it has an error
      updatedTabsWithErrors[tab] = hasError
    }

    return updatedTabsWithErrors
  }

  const goToErrorTab = (errors, formTabsWithErrors, formErrorsTabMapping) => {
    const _tabs = findTabsWithErrors(errors, formTabsWithErrors, formErrorsTabMapping)
    const _tabsWithErrors = Object.entries(_tabs).filter(([key, value]) => !!value)
    if (_tabsWithErrors.length > 0) setActiveTab(_tabsWithErrors[0][0])
  }

  const checkIncorporatingFiscalCodeOne = (
    comureiCommunicationType,
    comureiSubjectCancellation = {}
  ) => {
    const cancellationReason = comureiSubjectCancellation?.comureiSubjectCancellationType?.id
    if (comureiCommunicationType?.id?.toString() === '2') {
      if (cancellationReason === '5') {
        return (
          (!comureiSubjectCancellation.incorporatingFiscalCodeOne &&
            !comureiSubjectCancellation.incorporatingFiscalCodeTwo) ||
          (!comureiSubjectCancellation.incorporatingFiscalCodeOne &&
            !comureiSubjectCancellation.incorporatingFiscalCodeThree) ||
          (!comureiSubjectCancellation.incorporatingFiscalCodeTwo &&
            !comureiSubjectCancellation.incorporatingFiscalCodeThree)
        )
      }
    }
    return false
  }

  return (
    <div className="border-top">
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ errors }) => (
          <Form className={props.formOnBottom ? 'p-2 form-on-bottom' : ''}>
            {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
            {showErrorAlert && touched && (
              <div className={props.formOnBottom ? 'comurei-form-on-bottom-error' : ''}>
                <ErrorListAlert
                  errors={getActivePropsAndTab(errors, activeTab)}
                  hide={() => setShowErrorAlert(false)}
                />
              </div>
            )}
            <FormBody
              {...props}
              loading={loading}
              activeTab={activeTab}
              touched={touched}
              initialValues={initialValues}
              subjectSummary={subjectSummary}
              errors={errors}
              windowWidth={window.innerWidth}
              formErrorsTabMapping={formErrorsTabMapping}
              formTabsWithErrors={formTabsWithErrors}
              findTabsWithErrors={findTabsWithErrors}
              goToErrorTab={goToErrorTab}
              retrieveSubject={retrieveSubject}
              setLoading={setLoading}
              setTouched={setTouched}
              setActiveTab={setActiveTab}
              setTabWithError={setTabWithError}
              setShowErrorAlert={setShowErrorAlert}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [sizesClass, setSizesClass] = useState(getSize())

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  useEffect(() => {
    setValues(props.initialValues)
  }, [props.initialValues])

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  const handlerChangeSubjectType = (e) => {
    const cod = e.target[e.target.selectedIndex].dataset.cod
    setValues({ ...values, comureiSubjectType: { id: parseInt(e.target.value), code: cod } })
  }

  const handleChangeTextField = (value, field, trim = false, uppercase = true) => {
    if (props.disabled) return
    if (typeof value === 'string') {
      if (uppercase) value = value ? value?.toUpperCase() : ''
      if (trim) value = value?.trim()
    }
    const form = Object.assign({}, values)
    Utility.updateObjProp(form, value, field)
    setValues(form)
  }

  const handleChangeComureiTrasmissionTypeCheckbox = (value) => {
    if (props.disabled) return
    const form = Object.assign({}, values)
    if (form.comureiTransmission && !form.comureiTransmission.comureiTransmissionType) {
      form.comureiTransmission.comureiTransmissionType = {}
    }
    form.comureiTransmission.comureiTransmissionType.id = value
    setValues(form)
  }

  const handleChangeComureiSubjectCancellationTypeCheckbox = (value) => {
    if (props.disabled) return
    const form = Object.assign({}, values)
    if (
      form.comureiSubjectCancellation &&
      !form.comureiSubjectCancellation.comureiSubjectCancellationType
    ) {
      form.comureiSubjectCancellation.comureiSubjectCancellationType = {}
    }
    form.comureiSubjectCancellation.comureiSubjectCancellationType.id = value
    setValues(form)
  }

  const handleFiscalCode = async (val, update = true) => {
    val = val && val?.toUpperCase() ? val.toUpperCase() : null
    const form = Object.assign({}, values)
    try {
      const baseRegistry = await actions.getBaseRegistryByFiscalCode(val)
      if (baseRegistry) {
        if (values.personType === Constants.PNF && update) {
          form.companyName = baseRegistry?.companyName || values.companyName || ''
          form.location = baseRegistry?.location?.location || values.location || ''
          form.province = baseRegistry?.location?.province || values.province || ''
          form.firstName = null
          form.lastName = null
          form.birthDate = null
          form.gender = null
        } else if (values.personType === Constants.PF && update) {
          form.companyName = null
          form.location = baseRegistry?.location?.location || values.location || ''
          form.province = baseRegistry?.location?.province || values.province || ''
          form.firstName = baseRegistry?.firstName || ''
          form.lastName = baseRegistry?.lastName || ''
          form.birthDate = baseRegistry?.birthDate ? new Date(baseRegistry?.birthDate) : null
          form.gender = baseRegistry?.gender || ''
        }
        form.errorFiscalCode = false
        setValues(form)
        return false
      } else if (form.personType === Constants.PF) {
        try {
          const cf = new CodiceFiscale(val).toJSON()
          form.errorFiscalCode = true
          if (cf) {
            const form = Object.assign({}, values)
            if (update) {
              form.birthDate = form.birthDate || cf?.birthday ? new Date(cf?.birthday) : null
              form.gender = cf?.gender === 'M' ? 'MALE' : 'FEMALE'
              if (cf.birthplaceProvincia !== 'EE') {
                form.location = form.location || cf?.birthplace.toUpperCase()
              }
              form.province = form.province || cf?.birthplaceProvincia || ''
            }
            form.errorFiscalCode = false
          }
          setValues(form)
          return false
        } catch (e) {
          form.errorFiscalCode = true
          setValues(form)
          return true
        }
      }
    } catch {
      form.errorFiscalCode = false
      setValues(form)
      return false
    }
  }

  const handlePersonType = (val) => {
    const form = Object.assign({}, values)
    form.personType = val
    if (val === Constants.PF && !form.gender) form.gender = 'FEMALE'
    setValues(form)
  }

  const handlerResponsibleFiscalCode = async (val, update = true) => {
    const form = Object.assign({}, values)
    val = val && val?.toUpperCase() ? val.toUpperCase() : null
    try {
      const baseRegistry = await actions.getBaseRegistryByFiscalCode(val)
      if (baseRegistry !== undefined && baseRegistry?.personType === Constants.PF) {
        const form = Object.assign({}, values)
        if (update) {
          form.comureiSubjectResponsible.fiscalCode = val
          form.comureiSubjectResponsible.lastName = baseRegistry?.lastName || ''
          form.comureiSubjectResponsible.firstName = baseRegistry?.firstName || ''
          form.comureiSubjectResponsible.location = baseRegistry?.location?.location || ''
          form.comureiSubjectResponsible.province = baseRegistry?.location?.province || ''
          form.comureiSubjectResponsible.birthDate = baseRegistry?.birthDate
            ? new Date(baseRegistry?.birthDate)
            : null
          form.comureiSubjectResponsible.gender = baseRegistry?.gender
        }
        form.errorComureiSubjectResponsibleFiscalCode = false
        setValues(form)
        return false
      } else if (form.personType === Constants.PF) {
        try {
          const cf = new CodiceFiscale(val).toJSON()
          form.errorComureiSubjectResponsibleFiscalCode = true
          if (cf) {
            const form = Object.assign({}, values)
            if (update) {
              form.comureiSubjectResponsible.location =
                cf?.birthplaceProvincia !== 'EE' ? cf?.birthplace : ''
              form.comureiSubjectResponsible.province = cf?.birthplaceProvincia || ''
              form.comureiSubjectResponsible.birthDate = cf?.birthday
                ? new Date(cf?.birthday)
                : null
              form.comureiSubjectResponsible.gender = cf?.gender === 'M' ? 'MALE' : 'FEMALE'
            }
            form.errorComureiSubjectResponsibleFiscalCode = false
            setValues(form)
            return false
          }
          setValues(form)
          return true
        } catch (e) {
          form.errorComureiSubjectResponsibleFiscalCode = true
          setValues(form)
          return true
        }
      }
    } catch {
      form.errorComureiSubjectResponsibleFiscalCode = false
      setValues(form)
      return false
    }
  }

  const handleInputAutocompile = (newLocation, objectKey) => {
    if (!newLocation || newLocation === '') {
      const form = Object.assign({}, values)
      form.location = newLocation
      setValues(form)
    }
    newLocation = newLocation.toUpperCase()
    actions.getLocation(newLocation).then(
      (loc) => {
        const form = Object.assign({}, values)
        if (objectKey) {
          form[objectKey].location = newLocation
          if (loc) form[objectKey].province = loc.province
        } else {
          form.location = newLocation
          if (loc) form.province = loc.province
        }
        setValues(form)
      },
      (errors) => {
        const form = Object.assign({}, values)
        if (objectKey) {
          form[objectKey].location = ''
          form[objectKey].province = ''
        } else {
          form.location = ''
          form.province = ''
        }
        setValues(form)
      }
    )
  }

  const setTab = (tab) => {
    props.setActiveTab(tab)
  }

  const handleFormSubmit = async (sendComm) => {
    const errorFiscalCode = await handleFiscalCode(values.fiscalCode, false)
    const errorComureiSubjectResponsibleFiscalCode = await handlerResponsibleFiscalCode(
      values.comureiSubjectResponsible?.fiscalCode,
      false
    )
    const form = Object.assign({}, values)
    form.errorFiscalCode = errorFiscalCode
    form.errorComureiSubjectResponsibleFiscalCode = errorComureiSubjectResponsibleFiscalCode
    const errors = await validateForm(form) // Validazione dei valori
    setValues(form)
    props.setTouched(true)
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
      props.goToErrorTab(errors, props.formTabsWithErrors, props.formErrorsTabMapping)
    } else {
      updateAll(sendComm) // Esegui l'aggiornamento se non ci sono errori
      var tabsWithErrors = props.findTabsWithErrors(
        props.errors,
        props.formTabsWithErrors,
        props.formErrorsTabMapping
      )
      props.setTabWithError(tabsWithErrors)
      props.setShowErrorAlert(false) // Nascondi l'alert
    }
  }

  const close = (retrieve = false, id = null) => {
    if (retrieve) props.retrieveSubject(props.subjectId)
    props.setTouched(false)
    props.setTabWithError({})
    if (props.close) props.close(props.subjectId ? null : id)
  }

  const updateAll = (sendComm) => {
    let newSubject = { ...values }
    if (newSubject.personType === Constants.PF) {
      newSubject.companyName = null
    } else {
      newSubject.gender = null
      newSubject.firstName = null
      newSubject.lastName = null
      newSubject.birthDate = null
    }
    const formData = {
      ...newSubject,
      id: values?.id,
      errorFiscalCode: undefined,
      errorComureiSubjectResponsibleFiscalCode: undefined
    }
    formData.gender = Utility.getGender(formData.gender)
    formData.comureiSubjectResponsible.gender = Utility.getGender(
      formData.comureiSubjectResponsible.gender
    )
    addComureiSubject(formData, sendComm)
  }

  const addComureiSubject = (newSubject, sendComm) => {
    props.setLoading(true)
    actions.addComureiSubject(newSubject).then(
      (response) => {
        if (sendComm) {
          createCommunication(response)
          props.getSubjects()
        } else {
          PopupConfirm({
            title: labels.SUBJECT_SAVED_CORRECTLY,
            text: labels.SAVE_DATA_CREATE_COMMUNICATION,
            confirm: labels.CREATE_COMMUNICATION,
            cancel: labels.CLOSE,
            titleColor: titleColors.SUCCESS,
            handleClickConfirm: () => createCommunication(response)
          })
          close(false, response.id)
        }
        Utility.notifySuccess(labels.SUBJECT_SAVED_CORRECTLY)
        props.setLoading(false)
        props.getSubjects()
      },
      (errors) => {
        props.setLoading(false)
        if (errors?.status === 511) {
          PopupSubjectLicense(
            {
              total: props.subjectSummary.total,
              used: props.subjectSummary.used,
              link: Constants.COMUREI_BUY_LINK
            },
            lang
          )
        } else {
          PopupError({
            text: labels.GENERIC_ERROR
          })
        }
      }
    )

    if (values?.id === null) close()
  }

  const createCommunication = (response) => {
    props.setLoading(true)
    actions.createComureiCommunication(response).then(
      (communications) => {
        props.setLoading(false)
        props.openCreateCommunicationModal(response, communications)
      },
      (errors) => {
        props.setLoading(false)
        if (errors?.status === 511) {
          PopupSubjectLicense(
            {
              total: props.subjectSummary.total,
              used: props.subjectSummary.used,
              link: Constants.COMUREI_BUY_LINK
            },
            lang
          )
        } else if (errors?.data?.title) {
          PopupError({
            text: errors?.data?.title
          })
        } else {
          PopupError({
            text: labels.ERROR_GENERATION_COMMUNICATION
          })
        }
      }
    )
  }

  return (
    <>
      <div className="row-detail comurei d-flex">
        <div className="tab-content tab-content-comurei col-12 pe-2">
          <Tabs activeTab={props.activeTab} setActiveTab={setTab}>
            <div id="tab-sub" label={labels.SUBJECT}>
              <div className="row">
                <div className={`${sizesClass['tab-sub'].personType} subDetailComurei`}>
                  {/* <h6>Dettaglio Soggetto che Comunica</h6> */}
                  <div className="row mb-2">
                    {/* TIPO PERSONA */}
                    <div className="w-100 mb-2 ps-0">
                      <div className="input-group">
                        <div className="inputRadio me-5">
                          <label
                            disabled={props.disabled}
                            className={`form-check-label d-flex align-items-center ${props.disabled ? 'pointer-events-none' : ''}`}
                            htmlFor={`pType1${values?.id}`}
                            onClick={() => handlePersonType(Constants.PNF)}>
                            <input
                              disabled={props.disabled}
                              className="form-check-input pe-1 me-2 mt-0"
                              style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                              type="radio"
                              checked={values.personType === Constants.PNF}
                              onChange={() => {}}
                            />
                            {labels.LEGAL_PERSON}
                          </label>
                        </div>
                        <div className="inputRadio">
                          <label
                            disabled={props.disabled}
                            className={`form-check-label d-flex align-items-center ${props.disabled ? 'pointer-events-none' : ''}`}
                            htmlFor={`pType2${values?.id}`}
                            onClick={() => handlePersonType(Constants.PF)}>
                            <input
                              disabled={props.disabled}
                              className="form-check-input pe-1 me-2 mt-0"
                              style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                              type="radio"
                              checked={values.personType === Constants.PF}
                              onChange={() => {}}
                            />
                            {labels.PHYSICAL_PERSON}
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* TIPO SOGGETTO */}
                    <div className={`${sizesClass['tab-sub'].comureiSubjectType} mb-2 pe-2`}>
                      <BwmSelect
                        disabled={props.disabled}
                        options={props.subjectTypes || []}
                        name="comureiSubjectType"
                        label={`${labels.SUBJECT_TYPE}*`}
                        className="form-control"
                        showCode={true}
                        value={values.comureiSubjectType?.id}
                        error={errors.comureiSubjectType}
                        touched={props.touched}
                        onChange={(e) => handlerChangeSubjectType(e)}
                      />
                    </div>

                    {/* CODICE FISCALE */}
                    <div className={`${sizesClass['tab-sub'].fiscalCode} mb-2 pe-2`}>
                      <BwmInput
                        disabled={props.disabled}
                        name="fiscalCode"
                        label={`${labels.FISCAL_CODE}*`}
                        className="form-control"
                        value={values.fiscalCode}
                        error={errors.fiscalCode || errors.errorFiscalCode}
                        touched={props.touched}
                        maxLength="16"
                        onBlur={(e) => handleFiscalCode(e.target.value)}
                        onChange={(e) => handleChangeTextField(e.target.value, 'fiscalCode', true)}
                      />
                    </div>

                    {values.personType === Constants.PNF && (
                      <div className={`${sizesClass['tab-sub'].companyName} mb-2 pe-2`}>
                        {/* RAGIONE SOCIALE */}
                        <BwmInput
                          disabled={props.disabled}
                          name="companyName"
                          label={`${labels.COMPANY_NAME}*`}
                          className="form-control font-weight-bold font-size-big"
                          maxLength="60"
                          value={values.companyName}
                          error={errors.companyName}
                          touched={props.touched}
                          onChange={(e) => handleChangeTextField(e.target.value, 'companyName')}
                        />
                      </div>
                    )}
                    {values.personType === Constants.PF && (
                      <>
                        {/* NOME */}
                        <div className={`${sizesClass['tab-sub'].firstName} pe-2 mb-2`}>
                          <BwmInput
                            disabled={props.disabled}
                            name="firstName"
                            label={`${labels.FIRST_NAME}*`}
                            className="form-control font-weight-bold font-size-big"
                            maxLength="25"
                            value={values.firstName}
                            error={errors.firstName}
                            touched={props.touched}
                            onChange={(e) => handleChangeTextField(e.target.value, 'firstName')}
                          />
                        </div>
                        {/* COGNOME */}
                        <div className={`${sizesClass['tab-sub'].lastName} pe-2`}>
                          <BwmInput
                            disabled={props.disabled}
                            name="lastName"
                            label={`${labels.LAST_NAME}*`}
                            className="form-control font-weight-bold font-size-big"
                            maxLength="25"
                            value={values.lastName}
                            error={errors.lastName}
                            touched={props.touched}
                            onChange={(e) => handleChangeTextField(e.target.value, 'lastName')}
                          />
                        </div>

                        {/* SESSO */}
                        <div className={`${sizesClass['tab-sub'].gender} hide-error-icon pe-2`}>
                          <BwmSelect
                            disabled={props.disabled}
                            options={Constants.genderList || []}
                            name="gender"
                            label={`${labels.GENDER}*`}
                            allowEmpty={false}
                            className="form-control"
                            value={values.gender}
                            error={errors.gender}
                            touched={props.touched}
                            onChange={(e) => handleChangeTextField(e.target.value, 'gender')}
                          />
                        </div>
                        {/* DATA DI NASCITA */}
                        <div className={`${sizesClass['tab-sub'].birthDate} pe-2`}>
                          <DateField
                            disabled={props.disabled}
                            customOptions={{ maxDate: new Date() }}
                            label={`${labels.BIRTH_DATE}*`}
                            date={values.birthDate}
                            error={errors.birthDate}
                            touched={props.touched}
                            onChange={(value) =>
                              Utility.handleChangeValue(value, 'birthDate', values, setValues)
                            }
                          />
                        </div>
                      </>
                    )}

                    <div className={`${sizesClass['tab-sub'].location} pe-2`}>
                      {/* LOCALITÀ */}
                      <AutoCompileCustom
                        disabled={props.disabled}
                        label={
                          values.personType === Constants.PNF
                            ? `${labels.LEGAL_ADDRESS}*`
                            : `${labels.BIRTH_PLACE}*`
                        }
                        placeholder={values.personType === Constants.PNF ? 'Località' : undefined}
                        id="location"
                        filter={props.locations}
                        value={values.location}
                        error={errors.location}
                        touched={props.touched}
                        handleInputAutocompile={(e) => handleInputAutocompile(e, undefined)}
                      />
                    </div>
                    <div className={`${sizesClass['tab-sub'].province} pe-2`}>
                      {/* PROVINCIA */}
                      <ProvinceField
                        disabled={props.disabled}
                        label={`${labels.PROVINCE}*`}
                        placeholder={labels.EE_FOR_FOREIGN_COUNTRIES}
                        maxLength="2"
                        province={values.province}
                        error={errors.province}
                        touched={props.touched}
                        onChange={(e) => handleChangeTextField(e.target.value, 'province', true)}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`${sizesClass['tab-sub'].communicationSection} row ps-3`}
                  style={{ height: '160px' }}>
                  {/* Sezione Tipo Comunicazione (a sinistra) */}
                  <div className="col-6 pe-5">
                    {/* <h6>Dettaglio Comunicazione</h6> */}
                    <div className="form-check">
                      <label>{`${labels.COMMUNICATION_TYPE}*`}</label>
                      <div className="input-group mt-2 d-flex flex-column">
                        <div className="col-12 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'2'}
                              id={`ct2${values?.id}`}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`ct2${values?.id}`}
                              onClick={() =>
                                handleChangeTextField('2', 'comureiCommunicationType.id')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiCommunicationType?.id === '2'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt={labels.DELETE_FROM_REGISTRY}
                              />
                              <span
                                className={
                                  values.comureiCommunicationType?.id === '2' ? 'fw-bold' : ''
                                }>
                                {labels.DELETE_FROM_REGISTRY}
                              </span>
                            </label>
                          </>
                        </div>
                        <div className="col-12 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'1'}
                              id={`ct1${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`ct1${values?.id}`}
                              onClick={() =>
                                handleChangeTextField('1', 'comureiCommunicationType.id')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiCommunicationType?.id === '1'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt={labels.FIRST_REGISTRATION}
                              />
                              <span
                                className={
                                  values.comureiCommunicationType?.id === '1' ? 'fw-bold' : ''
                                }>
                                {labels.FIRST_REGISTRATION}
                              </span>
                            </label>
                          </>
                        </div>
                        <div className="col-12 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'3'}
                              id={`ct3${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`ct3${values?.id}`}
                              onClick={() =>
                                handleChangeTextField('3', 'comureiCommunicationType.id')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiCommunicationType?.id === '3'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt={labels.VARIATION_OF_DATA_ALREADY_COMMUNICATED}
                              />
                              <span
                                className={
                                  values.comureiCommunicationType?.id === '3' ? 'fw-bold' : ''
                                }>
                                {labels.VARIATION_OF_DATA_ALREADY_COMMUNICATED}
                              </span>
                            </label>
                          </>
                        </div>
                        <div className="col-12 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'4'}
                              id={`ct4${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`ct4${values?.id}`}
                              onClick={() =>
                                handleChangeTextField('4', 'comureiCommunicationType.id')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiCommunicationType?.id === '4'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt={labels.RE_REGISTRATION_TO_REGISTRI}
                              />
                              <span
                                className={
                                  values.comureiCommunicationType?.id === '4' ? 'fw-bold' : ''
                                }>
                                {labels.RE_REGISTRATION_TO_REGISTRI}
                              </span>
                            </label>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Sezione Motivi della Comunicazione (a destra) */}
                  <div className="col-6">
                    <label
                      className="mb-3"
                      style={{ color: '#128186', fontWeight: 'bold', marginRight: '10px' }}>
                      {labels.COMMUNICATION_REASONS_REI_SECTIONS}
                    </label>
                    <div className="form-check col-12 p-0">
                      <BwmCheckbox
                        disabled={props.disabled}
                        name={`registryReason_${values?.id}`}
                        label={
                          <span className={values.registryReason ? 'fw-bold' : ''}>
                            {labels.FINANCIAL_INVESTIGATIONS_ANAGRAFE_RAPPORTI}
                          </span>
                        }
                        className="form-control"
                        checked={values.registryReason}
                        error={errors.registryReason}
                        touched={props.touched}
                        onChange={(e) =>
                          Utility.handleChangeValue(
                            e.target.checked,
                            'registryReason',
                            values,
                            setValues
                          )
                        }
                      />
                    </div>

                    <div className="form-check col-12 p-0">
                      <BwmCheckbox
                        disabled={props.disabled}
                        name={`monitoringReason_${values?.id}`}
                        label={
                          <span className={values.monitoringReason ? 'fw-bold' : ''}>
                            {labels.MONITORING_TAXES}
                          </span>
                        }
                        className="form-control"
                        checked={values.monitoringReason}
                        error={errors.monitoringReason}
                        touched={props.touched}
                        onChange={(e) =>
                          Utility.handleChangeValue(
                            e.target.checked,
                            'monitoringReason',
                            values,
                            setValues
                          )
                        }
                      />
                    </div>

                    <div className="form-check col-12 p-0">
                      {/* <label>Sezione FACTA/CRS</label> */}
                      <BwmCheckbox
                        disabled={props.disabled}
                        name={`factaReason_${values?.id}`}
                        label={
                          <span className={values.factaReason ? 'fw-bold' : ''}>(FACTA/CRS)</span>
                        }
                        className="form-control"
                        checked={values.factaReason}
                        error={errors.factaReason}
                        touched={props.touched}
                        onChange={(e) => {
                          Utility.handleChangeValue(
                            e.target.checked,
                            'factaReason',
                            values,
                            setValues
                          )
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-check col-12 p-0 d-flex align-items-start">
                    <label
                      style={{
                        color: '#128186',
                        fontWeight: 'bold',
                        marginRight: '10px',
                        minWidth: '153px',
                        paddingBottom: '20px'
                      }}>
                      {labels.CENTRALIZED_STRUCTURE}
                    </label>
                    <div className="d-flex align-items-center">
                      <BwmCheckbox
                        disabled={props.disabled}
                        name={`centralizedStructure_${values?.id}`}
                        label={labels.YES}
                        className="form-control"
                        labelStyle={{ minWidth: '50px' }}
                        checked={values.centralizedStructure}
                        error={errors.centralizedStructure}
                        touched={props.touched}
                        onChange={(e) =>
                          Utility.handleChangeValue(
                            e.target.checked,
                            'centralizedStructure',
                            values,
                            setValues
                          )
                        }
                      />
                    </div>

                    {values.centralizedStructure && (
                      <span className="text-danger ms-1 me-1 fw-bold">
                        {labels.CENTRALIZED_STRUCTURE_DESCRIPTION}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div id="tab-resp" className="d-none" label={labels.RESPONSIBLE}>
              <div className="row">
                <div className={`${sizesClass['tab-resp'].fiscalCode} pe-2 mb-2`}>
                  <BwmInput
                    disabled={props.disabled}
                    label={`${labels.FISCAL_CODE}*`}
                    className="form-control"
                    maxLength="16"
                    value={values.comureiSubjectResponsible?.fiscalCode}
                    touched={props.touched}
                    error={
                      errors.comureiSubjectResponsible?.fiscalCode ||
                      errors.errorComureiSubjectResponsibleFiscalCode
                    }
                    onBlur={(e) => handlerResponsibleFiscalCode(e.target.value)}
                    onChange={(e) =>
                      handleChangeTextField(
                        e.target.value,
                        'comureiSubjectResponsible.fiscalCode',
                        true
                      )
                    }
                  />
                </div>
                <div className={`${sizesClass['tab-resp'].lastName} pe-2`}>
                  <BwmInput
                    disabled={props.disabled}
                    label={`${labels.LAST_NAME}*`}
                    className="form-control font-weight-bold font-size-big"
                    maxLength="25"
                    value={values.comureiSubjectResponsible?.lastName}
                    error={errors.comureiSubjectResponsible?.lastName}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeTextField(e.target.value, 'comureiSubjectResponsible.lastName')
                    }
                  />
                </div>
                <div className={`${sizesClass['tab-resp'].firstName} pe-2`}>
                  <BwmInput
                    disabled={props.disabled}
                    label={`${labels.FIRST_NAME}*`}
                    className="form-control font-weight-bold font-size-big"
                    maxLength="25"
                    value={values.comureiSubjectResponsible?.firstName}
                    error={errors.comureiSubjectResponsible?.firstName}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeTextField(e.target.value, 'comureiSubjectResponsible.firstName')
                    }
                  />
                </div>
                <div className={`${sizesClass['tab-resp'].gender} pe-2`}>
                  <BwmSelect
                    disabled={props.disabled}
                    options={Constants.genderList || []}
                    name="responsibleGender"
                    label={`${labels.GENDER}*`}
                    allowEmpty={false}
                    className="form-control"
                    value={values.comureiSubjectResponsible?.gender}
                    error={errors.comureiSubjectResponsible?.gender}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeTextField(e.target.value, 'comureiSubjectResponsible.gender')
                    }
                  />
                </div>
                <div className={`${sizesClass['tab-resp'].birthDate} pe-2`}>
                  <DateField
                    disabled={props.disabled}
                    label={`${labels.BIRTH_DATE}*`}
                    customOptions={{ maxDate: new Date() }}
                    date={values.comureiSubjectResponsible?.birthDate}
                    error={errors.comureiSubjectResponsible?.birthDate}
                    touched={props.touched}
                    onChange={(value) =>
                      handleChangeTextField(value, 'comureiSubjectResponsible.birthDate')
                    }
                  />
                </div>
                <div className={`${sizesClass['tab-resp'].birthPlace} pe-2`}>
                  <AutoCompileCustom
                    disabled={props.disabled}
                    label={`${labels.BIRTH_PLACE}*`}
                    filter={props.locations}
                    value={values.comureiSubjectResponsible?.location}
                    error={errors.comureiSubjectResponsible?.location}
                    touched={props.touched}
                    handleInputAutocompile={(location) =>
                      handleInputAutocompile(location, 'comureiSubjectResponsible')
                    }
                  />
                </div>
                <div className={`${sizesClass['tab-resp'].province} pe-2`}>
                  <ProvinceField
                    disabled={props.disabled}
                    label={`${labels.PROVINCE}*`}
                    placeholder={`${labels.EE} ${labels.FOR_FOREIGN_COUNTRIES}`}
                    province={values.comureiSubjectResponsible?.province}
                    error={errors.comureiSubjectResponsible?.province}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeTextField(
                        e.target.value,
                        'comureiSubjectResponsible.province',
                        true
                      )
                    }
                  />
                </div>
                <div className={`${sizesClass['tab-resp'].email} pe-2`}>
                  <BwmInput
                    disabled={props.disabled}
                    label={`${labels.EMAIL}*`}
                    className="form-control"
                    placeholder={`${labels.PERSONAL_EMAIL_OF_THE_RESPONSIBLE}`}
                    maxLength="60"
                    value={values.comureiSubjectResponsible?.email?.toUpperCase()}
                    error={errors.comureiSubjectResponsible?.email}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeTextField(
                        e.target.value,
                        'comureiSubjectResponsible.email',
                        true,
                        true
                      )
                    }
                  />
                </div>
                <div className={`${sizesClass['tab-resp'].phone} pe-2`}>
                  <BwmInput
                    disabled={props.disabled}
                    label={`${labels.PHONE}*`}
                    className="form-control"
                    maxLength="18"
                    value={values.comureiSubjectResponsible?.phone}
                    error={errors.comureiSubjectResponsible?.phone}
                    touched={props.touched}
                    onChange={(e) =>
                      handleChangeTextField(e.target.value, 'comureiSubjectResponsible.phone', true)
                    }
                  />
                </div>
              </div>
            </div>
            <div id="tab-pec" className="d-none" label={labels.EMAIL_}>
              <div className="row">
                <div className="w-50 w-lg-45 pe-2">
                  <div className="row">
                    <div
                      className={`${sizesClass['tab-pec'].pecEmail} pe-2 mb-2 ${!(values.comureiCommunicationType?.id?.toString() === '1' || values.comureiCommunicationType?.id?.toString() === '4') ? 'hidden' : ''}`}>
                      <BwmInput
                        disabled={props.disabled}
                        label={`${values.comureiCommunicationType?.id === '4' ? labels.PEC_TO_REWRITE : labels.PEC_TO_WRITE}*`}
                        className="form-control"
                        maxLength="100"
                        value={values.comureiSubjectPec?.pecEmail?.toUpperCase()}
                        error={errors.comureiSubjectPec?.pecEmail}
                        touched={props.touched}
                        onChange={(e) =>
                          handleChangeTextField(
                            e.target.value,
                            'comureiSubjectPec.pecEmail',
                            true,
                            true
                          )
                        }
                      />
                    </div>
                    {values.comureiCommunicationType?.id?.toString() !== '2' && (
                      <>
                        <div
                          className={`${values.comureiCommunicationType?.id?.toString() === '1' || values.comureiCommunicationType?.id?.toString() === '4' ? sizesClass['tab-pec'].pecManager_4 : sizesClass['tab-pec'].pecManager} mb-2 pe-2 ${values.comureiCommunicationType?.id?.toString() === '2' ? 'hidden' : ''}`}>
                          <BwmInput
                            disabled={props.disabled}
                            label={`${labels.PEC_MANAGER}*`}
                            className="form-control"
                            maxLength="100"
                            value={values.comureiSubjectPec?.pecManager?.toUpperCase()}
                            error={errors.comureiSubjectPec?.pecManager}
                            touched={props.touched}
                            onChange={(e) =>
                              handleChangeTextField(
                                e.target.value,
                                'comureiSubjectPec.pecManager',
                                false,
                                true
                              )
                            }
                          />
                        </div>
                        {values.comureiCommunicationType?.id?.toString() !== '2' && (
                          <>
                            <div
                              className={`${sizesClass['tab-pec'].effectiveDate} mb-2 pe-2 ${values.comureiCommunicationType?.id?.toString() === '2' ? 'hidden' : ''}`}>
                              <DateField
                                disabled={props.disabled}
                                label={`${labels.EFFECTIVE_DATE}*`}
                                date={values.comureiSubjectPec?.effectiveDate}
                                error={errors.comureiSubjectPec?.effectiveDate}
                                touched={props.touched}
                                onChange={(value) =>
                                  handleChangeTextField(value, 'comureiSubjectPec.effectiveDate')
                                }
                              />
                            </div>
                            <div
                              className={`${sizesClass['tab-pec'].endDate} ${values.comureiCommunicationType?.id?.toString() === '2' ? 'hidden' : ''}`}>
                              <DateField
                                disabled={props.disabled}
                                label={`${labels.END_DATE_}`}
                                date={values.comureiSubjectPec?.endDate}
                                error={errors.comureiSubjectPec?.endDate}
                                touched={props.touched}
                                onChange={(value) =>
                                  handleChangeTextField(value, 'comureiSubjectPec.endDate')
                                }
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                    <div
                      className={`${values.comureiCommunicationType?.id?.toString() === '2' ? sizesClass['tab-pec'].reiEmail_2 : sizesClass['tab-pec'].reiEmail} pe-2 ${values.comureiCommunicationType?.id?.toString() === '2' || values.comureiCommunicationType?.id?.toString() === '3' ? '' : 'hidden'}`}>
                      <BwmInput
                        disabled={props.disabled}
                        label={` ${values.comureiCommunicationType?.id?.toString() === '2' ? `${labels.PEC_ALREADY_COMMUNICATED_TO_REI_TO_CANCEL}*` : `${labels.PEC_ALREADY_COMMUNICATED_TO_CHANGE}*`}`}
                        className="form-control"
                        maxLength="255"
                        value={values.comureiSubjectPec?.reiEmail}
                        error={errors.comureiSubjectPec?.reiEmail}
                        touched={props.touched}
                        onChange={(e) =>
                          handleChangeTextField(e.target.value, 'comureiSubjectPec.reiEmail')
                        }
                      />
                    </div>
                    {values.comureiCommunicationType?.id?.toString() === '3' && (
                      <div
                        className={`${sizesClass['tab-pec'].pecEmailAddress} pe-2 ${values.comureiCommunicationType?.id?.toString() === '2' || values.comureiCommunicationType?.id?.toString() === '3' ? '' : 'hidden'}`}>
                        <BwmInput
                          disabled={props.disabled}
                          label={labels.PEC_EMAIL_ADDRESS}
                          className="form-control"
                          maxLength="255"
                          value={values.comureiSubjectPec?.pecEmailAddress}
                          error={errors.comureiSubjectPec?.pecEmailAddress}
                          touched={props.touched}
                          onChange={(e) =>
                            handleChangeTextField(
                              e.target.value,
                              'comureiSubjectPec.pecEmailAddress',
                              true,
                              true
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-50 w-lg-55 infoPostaEle ps-3">
                  <div className="infoPoint">
                    <strong className="pe-1">{labels.PEC_EMAIL_ADDRESS_}:</strong>{' '}
                    {labels.ADDRESS_PEC_LAST_VERSION}
                  </div>
                  <div className="infoPoint">
                    <strong className="pe-1">{labels.PEC_MANAGER}:</strong>{' '}
                    {labels.PEC_SERVICE_PROVIDER}
                  </div>
                  <div className="infoPoint">
                    <strong className="pe-1">{labels.EFFECTIVE_DATE}:</strong>{' '}
                    {labels.START_OF_CONTRACT_WITH_PEC_PROVIDER}
                  </div>
                  <div className="infoPoint">
                    <strong className="pe-1">{labels.END_DATE_}:</strong>{' '}
                    {labels.NOT_MANDATORY_RENEWAL_NO_NEW_COMMUNICATION}
                  </div>
                  <div className="infoPoint pb-0 mb-0">
                    <strong className="pe-1">{labels.DATE_VARIATION}</strong>{' '}
                    {labels.NOT_REQUIRED_DO_NOT_INDICATE}
                  </div>
                </div>
              </div>
            </div>
            {values.comureiCommunicationType?.id?.toString() === '2' ? (
              <div id="tab-delete" className="d-none" label="Cancellazione">
                <div className="row">
                  <div className={`${sizesClass['tab-delete'].cancellationDate} pe-4`}>
                    <DateField
                      disabled={props.disabled}
                      label={labels.CANCELLATION_DATE}
                      date={values.comureiSubjectCancellation?.cancellationDate}
                      error={errors.comureiSubjectCancellation?.cancellationDate}
                      touched={props.touched}
                      onChange={(value) =>
                        handleChangeTextField(value, 'comureiSubjectCancellation.cancellationDate')
                      }
                    />
                  </div>
                  <div className={`${sizesClass['tab-delete'].leftSide} pe-3`}>
                    <div className="form-check">
                      <label>{labels.CANCELLATION_REASON}</label>
                      <div className="input-group mt-2">
                        <div className="col-12 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'1'}
                              id={`cr1${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`cr1${values?.id}`}
                              onClick={() =>
                                handleChangeComureiSubjectCancellationTypeCheckbox('1')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiSubjectCancellation.comureiSubjectCancellationType
                                    ?.id === '1'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt={labels.CANCELLATION_REASON_1}
                              />
                              {labels.CANCELLATION_REASON_1}
                            </label>
                          </>
                        </div>
                        <div className="col-12 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'2'}
                              id={`cr2${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`cr2${values?.id}`}
                              onClick={() =>
                                handleChangeComureiSubjectCancellationTypeCheckbox('2')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiSubjectCancellation.comureiSubjectCancellationType
                                    ?.id === '2'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt={labels.CANCELLATION_REASON_2}
                              />
                              {labels.CANCELLATION_REASON_2}
                            </label>
                          </>
                        </div>
                        <div className="col-12 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'3'}
                              id={`cr3${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`cr3${values?.id}`}
                              onClick={() =>
                                handleChangeComureiSubjectCancellationTypeCheckbox('3')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiSubjectCancellation.comureiSubjectCancellationType
                                    ?.id === '3'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt={labels.CANCELLATION_REASON_3}
                              />
                              {labels.CANCELLATION_REASON_3}
                            </label>
                          </>
                        </div>
                        <div className="col-12 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'4'}
                              id={`cr4${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`cr4${values?.id}`}
                              onClick={() =>
                                handleChangeComureiSubjectCancellationTypeCheckbox('4')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiSubjectCancellation.comureiSubjectCancellationType
                                    ?.id === '4'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt={labels.CANCELLATION_REASON_4}
                              />
                              {labels.CANCELLATION_REASON_4}
                            </label>
                          </>
                        </div>
                        <div className="col-12 p-0 inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'5'}
                              id={`cr5${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className="form-check-label"
                              htmlFor={`cr5${values?.id}`}
                              onClick={() =>
                                handleChangeComureiSubjectCancellationTypeCheckbox('5')
                              }>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiSubjectCancellation.comureiSubjectCancellationType
                                    ?.id === '5'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt={labels.CANCELLATION_REASON_5}
                              />
                              {labels.CANCELLATION_REASON_5}
                            </label>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${sizesClass['tab-delete'].rightSide} p-0 d-flex align-items-center`}>
                    {(values.comureiSubjectCancellation?.comureiSubjectCancellationType?.id ===
                      '2' ||
                      values.comureiSubjectCancellation?.comureiSubjectCancellationType?.id ===
                        '3' ||
                      values.comureiSubjectCancellation?.comureiSubjectCancellationType?.id ===
                        '4') && (
                      <div className="row align-items-center w-100">
                        <h6
                          className={`${sizesClass['tab-delete'].disponibilityAccounting} d-flex align-items-center w-100 mb-2 pb-0`}>
                          <strong>{labels.DISPONIBILITY_ACCOUNTING_ORDINARY}:</strong>
                        </h6>
                        <div className={`${sizesClass['tab-delete'].address} mb-2 pe-2`}>
                          <BwmInput
                            disabled={props.disabled}
                            label={labels.ADDRESS}
                            className="form-control"
                            maxLength="100"
                            value={values.comureiSubjectCancellation?.address}
                            error={errors.comureiSubjectCancellation?.address}
                            touched={props.touched}
                            onChange={(e) =>
                              handleChangeTextField(
                                e.target.value,
                                'comureiSubjectCancellation.address'
                              )
                            }
                          />
                        </div>
                        <div className={`${sizesClass['tab-delete'].location} mb-2 pe-2`}>
                          <AutoCompileCustom
                            disabled={props.disabled}
                            label={labels.LEGAL_SEAT_COMMUNITY}
                            filter={props.locations}
                            value={values.comureiSubjectCancellation?.location}
                            error={errors.comureiSubjectCancellation?.location}
                            touched={props.touched}
                            handleInputAutocompile={(e) =>
                              handleInputAutocompile(e, 'comureiSubjectCancellation')
                            }
                          />
                        </div>
                        <div className={`${sizesClass['tab-delete'].province} mb-2`}>
                          <ProvinceField
                            disabled={props.disabled}
                            label={labels.PROVINCE}
                            placeholder={labels.EE_FOR_FOREIGN_COUNTRIES}
                            province={values.comureiSubjectCancellation?.province}
                            error={errors.comureiSubjectCancellation?.province}
                            touched={props.touched}
                            onChange={(e) =>
                              handleChangeTextField(
                                e.target.value,
                                'comureiSubjectCancellation.province',
                                true
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                    {values.comureiSubjectCancellation?.comureiSubjectCancellationType?.id ===
                      '1' && (
                      <div className="row">
                        <h6
                          className={`${sizesClass['tab-delete'].accountingAvailability} mb-2 pb-0 d-flex align-items-center w-100`}>
                          <strong>{labels.ACCOUNTING_AVAILABILITY}:</strong>
                        </h6>
                        <div
                          className={`${sizesClass['tab-delete'].incorporatingFiscalCodeOne} mb-2 pe-2`}>
                          <BwmInput
                            disabled={props.disabled}
                            label={labels.INCORPORATING_FISCAL_CODE_ONE}
                            className="form-control"
                            maxLength="100"
                            value={values.comureiSubjectCancellation?.incorporatingFiscalCodeOne}
                            error={errors.comureiSubjectCancellation?.incorporatingFiscalCodeOne}
                            touched={props.touched}
                            onChange={(e) =>
                              handleChangeTextField(
                                e.target.value,
                                'comureiSubjectCancellation.incorporatingFiscalCodeOne',
                                true
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                    {values.comureiSubjectCancellation?.comureiSubjectCancellationType?.id ===
                      '5' && (
                      <div className="row">
                        <h6
                          className={`${sizesClass['tab-delete'].accountingAvailabilityTwo} w-100 pb-0 mb-2 d-flex align-items-center`}>
                          <strong>{labels.ACCOUNTING_AVAILABILITY}:</strong>
                        </h6>
                        <div
                          className={`${sizesClass['tab-delete'].incorporatingFiscalCodeOne} mb-2 pe-2`}>
                          <BwmInput
                            disabled={props.disabled}
                            label={labels.INCORPORATING_FISCAL_CODE_ONE}
                            className="form-control"
                            maxLength="100"
                            value={values.comureiSubjectCancellation?.incorporatingFiscalCodeOne}
                            error={errors.comureiSubjectCancellation?.incorporatingFiscalCodeOne}
                            touched={props.touched}
                            onChange={(e) =>
                              handleChangeTextField(
                                e.target.value,
                                'comureiSubjectCancellation.incorporatingFiscalCodeOne',
                                true
                              )
                            }
                          />
                        </div>
                        <div
                          className={`${sizesClass['tab-delete'].incorporatingFiscalCodeTwo} mb-2 pe-2`}>
                          <BwmInput
                            disabled={props.disabled}
                            label={labels.INCORPORATING_FISCAL_CODE_TWO}
                            className="form-control"
                            maxLength="100"
                            value={values.comureiSubjectCancellation?.incorporatingFiscalCodeTwo}
                            error={errors.comureiSubjectCancellation?.incorporatingFiscalCodeTwo}
                            touched={props.touched}
                            onChange={(e) =>
                              handleChangeTextField(
                                e.target.value,
                                'comureiSubjectCancellation.incorporatingFiscalCodeTwo',
                                true
                              )
                            }
                          />
                        </div>
                        <div
                          className={`${sizesClass['tab-delete'].incorporatingFiscalCodeThree} mb-2 pe-2`}>
                          <BwmInput
                            disabled={props.disabled}
                            label={labels.INCORPORATING_FISCAL_CODE_THREE}
                            className="form-control"
                            maxLength="100"
                            value={values.comureiSubjectCancellation?.incorporatingFiscalCodeThree}
                            error={errors.comureiSubjectCancellation?.incorporatingFiscalCodeThree}
                            touched={props.touched}
                            onChange={(e) =>
                              handleChangeTextField(
                                e.target.value,
                                'comureiSubjectCancellation.incorporatingFiscalCodeThree',
                                true
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div id="tab-delete" className="d-none" label={labels.CANCELLATION} disabled></div>
            )}
            {values.centralizedStructure ? (
              <div
                id="tab-transmission"
                className="d-none"
                disabled
                label={labels.COMMITMENT_TO_TRANSMISSION}></div>
            ) : (
              <div
                id="tab-transmission"
                className="d-none"
                label={labels.COMMITMENT_TO_TRANSMISSION}>
                <div className="row mb-2">
                  <div className={`${sizesClass['tab-transmission'].fiscalCode} me-5`}>
                    <BwmInput
                      disabled={props.disabled}
                      label={labels.FISCAL_CODE}
                      className="form-control"
                      maxLength="16"
                      value={values.comureiTransmission?.fiscalCode}
                      error={errors.comureiTransmission?.fiscalCode}
                      touched={props.touched}
                      onChange={(e) =>
                        handleChangeTextField(
                          e.target.value,
                          'comureiTransmission.fiscalCode',
                          true
                        )
                      }
                    />
                  </div>
                  <div
                    className={`${sizesClass['tab-transmission'].comureiTransmissionType}`}
                    style={{ paddingLeft: '130px' }}>
                    <div className="form-check">
                      <label>{labels.TELEMATIC_COMMITMENT}</label>
                      <div className="input-group mt-2">
                        <div className="inputRadio me-5">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'1'}
                              id={`tt1${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className={`form-check-label ${props.disabled ? 'pointer-events-none' : ''}`}
                              htmlFor={`tt1${values?.id}`}
                              onClick={() => handleChangeComureiTrasmissionTypeCheckbox('1')}>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiTransmission?.comureiTransmissionType?.id === '1'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt={labels.CONTRIBUTOR}
                              />
                              {labels.CONTRIBUTOR}
                            </label>
                          </>
                        </div>
                        <div className="inputRadio">
                          <>
                            <input
                              disabled={props.disabled}
                              hidden={true}
                              className="form-check-input"
                              type="radio"
                              value={'2'}
                              id={`tt2${values?.id}`}
                            />
                            <label
                              disabled={props.disabled}
                              className={`form-check-label ${props.disabled ? 'pointer-events-none' : ''}`}
                              htmlFor={`tt2${values?.id}`}
                              onClick={() => handleChangeComureiTrasmissionTypeCheckbox('2')}>
                              <img
                                width="20"
                                className="me-2"
                                src={
                                  values.comureiTransmission?.comureiTransmissionType?.id === '2'
                                    ? radioSel
                                    : radioUnsel
                                }
                                alt={labels.FROM_WHO_PERFORMS_THE_SENDING}
                              />
                              {labels.FROM_WHO_PERFORMS_THE_SENDING}
                            </label>
                          </>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="d-flex me-4">
                    <div className="infoPostaEle px-2">
                      <div className="infoPoint-title ms-0 mb-1 d-flex align-items-center">
                        <i className="thx-info-circle thx-icon me-2" />
                        {labels.INFORMATION}
                      </div>
                      <div className="infoPoint m-0">
                        {
                          labels.FILL_ONLY_IN_CASE_OF_COMMUNICATION_SENT_VIA_ENTRATEL_OF_AN_INTERMEDIARY_DIFFERENT_FROM_THE_OBLIGED_SUBJECT
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Tabs>
        </div>
      </div>
      <div className="form-row row align-item-center">
        <div className="d-flex justify-content-between">
          {/* Bottone Annulla */}
          <div className="d-flex align-items-center">
            <button
              type="button"
              disabled={props.disabled}
              className="btn btn-outline-primary me-4"
              onClick={() => close(true)}>
              <i className="thx-cancel thx-icon me-2" />
              {labels.CANCEL}
            </button>
            {/* Vista sulla destra */}
            {props.paginationComponent && (
              <div className="d-flex justify-content-end">{props.paginationComponent}</div>
            )}
            {window.innerWidth > Constants.COMUREI_BREAKPOINT_LG && (
              <div className="ms-4">
                <b className="me-2">*</b>
                <span>{labels.REQUIRED_FIELD_FOR_FORM_COMPLETION}</span>
              </div>
            )}
          </div>
          {/* Bottone Crea Comunicazione */}
          <div className="d-flex align-items-center">
            <button
              type="button"
              disabled={props.disabled}
              className="btn btn-outline-primary ml-2 me-2"
              onClick={() => handleFormSubmit(true)}>
              <i className="thx-text-file thx-icon me-2" />
              {labels.CREATE_COMMUNICATION}
            </button>

            {/* Bottone Conferma */}
            <button
              disabled={props.disabled}
              type="submit"
              className="btn btn-primary ml-2"
              onClick={() => handleFormSubmit(false)}>
              <i className="thx-floppy-disk thx-icon me-2" />
              {labels.SAVE}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
