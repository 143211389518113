import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { notifySuccess, testEmail } from '../../shared/Utility'
import { adminFindRestorableCoraSubjects, restoreCoraSubject } from '../../../actions'
import getText from '../../shared/i18n/labels'

// FORM
import { BwmSelect } from '../../shared/form/BwmSelect'
import { BwmInput } from '../../shared/form/BwmInput'

// BUTTONS
import { BtnFill } from '../../shared/BtnFill'

// COMPONENTS
import { PopupError } from '../../shared/popups/PopupError'
import { SimpleSpinner } from '../../shared/spinner/Spinner'

export function CoraAdmin() {
  const [userLogin, setUserLogin] = useState('')
  const [fiscalCode, setFiscalCode] = useState('')
  const location = useLocation()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [isLoading, setIsLoading] = useState(false)
  const [fiscalCodes, setFiscalCodes] = useState([])

  useEffect(() => {
    handleChangeUserLogin(location?.state?.loginParam || '')
  }, [])
  const onRestore = () => {
    let dto = {
      userLogin,
      fiscalCode
    }

    restoreCoraSubject(dto).then(
      (res) => {
        notifySuccess(`${labels.RESTORED_SUBJECT} ${res.companyName}${res.firstName && res.firstName + ' ' + res.lastName} con id ${res.id},  ${labels.RESTORED}.
             ${labels.IT_MAY_BE_NECESSARY_TO_REINSERT_REPORTS_AND_CERTIFICATES}`)
      },
      (err) => {
        PopupError({ text: err.title || labels.GENERIC_ERROR })
      }
    )
  }

  const handleChangeUserLogin = (value) => {
    setUserLogin(value)
    setFiscalCode('')

    const isEmailValid = testEmail(value)
    if (isEmailValid) {
      setIsLoading(true)
      //interrogo il BE per reperire i codici fiscali ripristinabili
      adminFindRestorableCoraSubjects(value)
        .then(
          (res) => {
            setFiscalCodes(res)
          },
          (err) => {
            console.log(err)
          }
        )
        .then(() => setIsLoading(false))
    } else {
      setFiscalCodes([])
    }
  }

  const buildDropDown = (fiscalCodes = []) => {
    const options = fiscalCodes.map((e) => {
      return { id: e, description: e }
    })
    return (
      <BwmSelect
        label={labels.FISCAL_CODE_SUBJECT}
        maxLength={16}
        className="form-control"
        value={fiscalCode}
        options={options}
        onChange={(e) => setFiscalCode(e.target.value)}
      />
    )
  }

  return (
    <>
      <div className="row justify-content-center mt-4">
        <div className="col-6">
          <h4>{labels.RESTORE_CORA_SUBJECT}</h4>
          <div className="row mt-4">
            <div className="col-7">
              <BwmInput
                maxLength={255}
                className="form-control form-control-normal"
                name={'login'}
                value={userLogin}
                label={labels.USER_LOGIN}
                onChange={(e) => handleChangeUserLogin(e.target.value.trim())}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-7">
              {fiscalCodes.length > 0
                ? buildDropDown(fiscalCodes)
                : labels.THIS_USER_HAS_NO_RESTORABLE_SUBJECTS}
              {/* <BwmInput
                                name={"fiscalCode"}
                                maxLength={16}
                                className='form-control'
                                value={fiscalCode}
                                label={"Codice Fiscale soggetto: "}
                                onChange={e => setFiscalCode(e.target.value.trim())}
                            /> */}
            </div>
            <div className="col-1">{isLoading && <SimpleSpinner size={18} />}</div>
          </div>
          {fiscalCode && (
            <div className="row">
              <BtnFill
                text={labels.RESTORE}
                handlerOnClick={() => {
                  onRestore()
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
