import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Utility from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'
import getText from '../../../shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// FORM
import { DateField } from '../../../shared/form/DateFieldB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import NumberFormat from 'react-number-format'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'

const defaultSize = (size = null) => ({
  registrationDate: Constants.dateSize(size, 19),
  contractDate: Constants.dateSize(size),
  amount: Utility.getSize(size, { default: '15' }),
  paymentType: Utility.getSize(size, { default: '52', md: '40', additionalClass: 'flex-grow-1' }),
  arcoCausalOperationType: Utility.getSize(size, { default: '40', additionalClass: 'flex-grow-1' }),
  cash: Utility.getSize(size, { default: '15' }),
  currency: Utility.getSize(size, { default: '40', lg: '32', additionalClass: 'flex-grow-1' }),
  notes: Utility.getSize(size, { default: '100' })
})

const getSize = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export function ArcoRegistrationContractForm(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const customer = useSelector((state) => state.auth.customer)
  const currencies = useSelector((state) => state.currencies.data)

  const [initialValues, setInitialValues] = useState({})
  const [touched, setTouched] = useState(false)
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setNewInitialValues()
  }, [])

  const setNewInitialValues = () => {
    setInitialValues({
      id: props.selectedContract?.id || null,
      index:
        parseInt(props.selectedContract?.index) !== 'NaN' ? props.selectedContract?.index : null,
      arcoCausalOperationType: props.selectedContract?.arcoCausalOperationType
        ? props.selectedContract.arcoCausalOperationType
        : props.arcoCausalOperations[0],
      currency: {
        id: setDefaultCurrency(),
        isoCode: setDefaultCurrencyIso()
      },
      amount: props.selectedContract?.amount || 0,
      paymentType: props.selectedContract?.paymentType || null,
      contractDate: props.selectedContract?.contractDate
        ? new Date(props.selectedContract?.contractDate)
        : setDefaultContractDate(),
      registrationDate: props.selectedContract?.registrationDate
        ? new Date(props.selectedContract?.registrationDate)
        : new Date(),
      cash: props.selectedContract?.cash || 0,
      notes: props.selectedContract?.notes || null,
      arcoSubject: props.arcoSubject,
      customer: customer
    })
  }

  const setDefaultContractDate = () => {
    let date = props.selectedOperation?.profilingDate
      ? new Date(props.selectedOperation?.profilingDate)
      : new Date()
    date.setHours(12)
    return date
  }

  const setDefaultCurrencyIso = () => {
    let eur
    if (props.selectedContract?.currency) {
      return props.selectedContract.currency?.isoCode
    } else {
      currencies.map((currentCurrency) => {
        if (currentCurrency.isoCode === 'EUR') eur = currentCurrency.isoCode
      })
      return eur
    }
  }

  const setDefaultCurrency = () => {
    let eur
    if (props.selectedContract?.currency) {
      return props.selectedContract.currency?.id
    } else {
      currencies.map((currentCurrency) => {
        if (currentCurrency.isoCode === 'EUR') {
          eur = currentCurrency.id
        }
      })
      return eur
    }
  }

  const formErrorsMapping = [
    {
      errorKey: 'arcoCausalOperations',
      errorLabel: labels.ARCO_CAUSAL_OPERATION_TYPE
    },
    {
      errorKey: 'currency.id',
      errorLabel: labels.CURRENCY
    },
    {
      errorKey: 'amount',
      errorLabel: labels.AMOUNT
    },
    {
      errorKey: 'paymentType',
      errorLabel: labels.PAYMENT_TYPE
    },
    {
      errorKey: 'contractDate',
      errorLabel: labels.CONTRACT_DATE
    },
    {
      errorKey: 'registrationDate',
      errorLabel: labels.REGISTRATION_DATE
    },
    {
      errorKey: 'cash',
      errorLabel: labels.CASH
    },
    {
      errorKey: 'notes',
      errorLabel: labels.NOTES
    }
  ]

  const validationSchema = Yup.object().shape({
    arcoCausalOperationType: Yup.object().shape({
      id: Yup.string().required(labels.REQUIRED_FIELD)
    }),
    amount: Yup.number().required(labels.REQUIRED_FIELD),
    paymentType: Yup.string().required(labels.REQUIRED_FIELD),
    contractDate: Yup.date(labels.INVALID_DATE)
      .required(labels.REQUIRED_FIELD)
      .test(
        'is-valid-date',
        labels.INVALID_DATE,
        (value) => !value || Utility.isValidDate(new Date(value))
      ),
    registrationDate: Yup.date(labels.INVALID_DATE)
      .required(labels.REQUIRED_FIELD)
      .test(
        'is-valid-date',
        labels.INVALID_DATE,
        (value) => !value || Utility.isValidDate(new Date(value))
      ),
    cash: Yup.number()
      .required(labels.REQUIRED_FIELD)
      .test('is-less-than-cash', labels.CASH_GREATER_THAN_AMOUNT, function (value) {
        const { amount } = this.parent
        return value <= amount
      }),
    currency: Yup.object().shape({
      id: Yup.string().required(labels.REQUIRED_FIELD)
    })
  })

  const getErrors = (errors) => {
    return Utility.extractErrors(errors, formErrorsMapping)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ errors }) => (
          <Form>
            {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
            {showErrorAlert &&
              touched &&
              errors &&
              typeof errors === 'object' &&
              Object.keys(errors).length > 0 && (
                <div>
                  <ErrorListAlert
                    errors={getErrors(errors)}
                    hide={() => setShowErrorAlert(false)}
                  />
                </div>
              )}
            <FormBody
              {...props}
              loading={loading}
              touched={touched}
              initialValues={initialValues}
              windowWidth={window.innerWidth}
              setLoading={setLoading}
              setTouched={setTouched}
              setShowErrorAlert={setShowErrorAlert}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [sizesClass, setSizesClass] = useState(getSize())

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const currencies = useSelector((state) => state.currencies.data)

  const inputWrapperClassAmount = `input-wrapper d-flex w-100 align-items-center text-end ${props.touched && errors.amount !== undefined ? 'has-error' : ''}`
  const inputWrapperClassCash = `input-wrapper d-flex w-100 align-items-center text-end ${props.touched && errors.cash !== undefined ? 'has-error' : ''}`

  useEffect(() => {
    setValues(props.initialValues)
  }, [props.initialValues])

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  const handlerChangeDate = (date, field) => {
    const form = Object.assign({}, values)
    if (date) {
      date.setHours(12)
      form[field] = date
      setValues(form)
    }
  }

  const handlerChangeNumber = (val, field) => {
    const form = Object.assign({}, values)
    if (Utility.isEmpty(val)) val.floatValue = 0
    form[field] = val.floatValue
    setValues(form)
  }

  const handleArcoCausalOperationType = (val) => {
    const form = Object.assign({}, values)
    form.arcoCausalOperationType = props.arcoCausalOperations.find(
      (el) => el.id?.toString() === val?.toString()
    )
    setValues(form)
  }

  const handleCurrency = (val) => {
    const form = Object.assign({}, values)
    form.currency = currencies.find((el) => el.id?.toString() === val?.toString())
    setValues(form)
  }

  const handleFormSubmit = async () => {
    props.setTouched(true)
    const errors = await validateForm(values) // Validazione dei valori
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      props.setShowErrorAlert(false)
      props.addArcoContract(values)
      props.onHide()
    }
  }

  return (
    <div className="bg-gray d-flex row-form big">
      <div>
        <div>
          <div id={`anagrafica-`}>
            <div className="row">
              <div className={`${sizesClass.registrationDate} pe-2`}>
                <DateField
                  label={`${labels.REGISTRATION_DATE}*`}
                  disabled={props.disabled}
                  date={values.registrationDate}
                  error={errors.registrationDate}
                  touched={props.touched}
                  onChange={(date) => handlerChangeDate(date, 'registrationDate')}
                />
              </div>
              <div className={`${sizesClass.contractDate} pe-2`}>
                <DateField
                  label={`${labels.CONTRACT_DATE}*`}
                  disabled={props.disabled}
                  date={values.contractDate}
                  error={errors.contractDate}
                  touched={props.touched}
                  onChange={(date) => handlerChangeDate(date, 'contractDate')}
                />
              </div>

              <div className={`${sizesClass.amount} pe-2`}>
                <div className="form-group number-format-label">
                  <NumberFormat
                    label={`${labels.AMOUNT}*`}
                    disabled={props.disabled}
                    multiline={true}
                    variant="filled"
                    name="amount"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale={true}
                    className={inputWrapperClassAmount}
                    inputMode="numeric"
                    customInput={BwmInput}
                    value={values.amount || 0}
                    defaultValue={0}
                    onValueChange={(val) => handlerChangeNumber(val, 'amount')}
                  />
                </div>
              </div>
              <div className={`${sizesClass.paymentType}`}>
                <BwmInput
                  name="paymentType"
                  disabled={props.disabled}
                  label={`${labels.PAYMENT_TYPE}*`}
                  className="form-control"
                  maxLength={255}
                  value={values.paymentType}
                  error={errors.paymentType}
                  touched={props.touched}
                  onChange={(e) =>
                    Utility.handleChangeValue(
                      e.target.value,
                      'paymentType',
                      values,
                      setValues,
                      true
                    )
                  }
                />
              </div>
            </div>
            <div className="row mt-2 mb-2">
              <div className={`${sizesClass.arcoCausalOperationType} pe-2`}>
                <BwmSelect
                  options={props.arcoCausalOperations}
                  disabled={props.disabled}
                  name="arcoCausalOperationType"
                  label={`${labels.CAUSAL_OPERATION_TYPE}*`}
                  className="form-control"
                  showCode={true}
                  value={values.arcoCausalOperationType?.id}
                  error={errors.arcoCausalOperationType}
                  touched={props.touched}
                  onChange={(e) => handleArcoCausalOperationType(e.target.value)}
                />
              </div>

              <div className={`${sizesClass.cash} pe-2`}>
                <div className="form-group number-format-label">
                  <NumberFormat
                    thousandSeparator={'.'}
                    disabled={props.disabled}
                    label={`${labels.CASH}*`}
                    decimalSeparator={','}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    className={inputWrapperClassCash}
                    name="cash"
                    inputMode="numeric"
                    customInput={BwmInput}
                    value={values.cash || 0}
                    defaultValue={0}
                    onValueChange={(val) => handlerChangeNumber(val, 'cash')}
                  />
                </div>
              </div>
              <div className={`${sizesClass.currency}`}>
                <BwmSelect
                  options={currencies}
                  disabled={props.disabled}
                  name="divisa"
                  label={`${labels.CURRENCY}*`}
                  className="form-control"
                  showCode={true}
                  value={values.currency?.id}
                  error={errors.currency}
                  touched={props.touched}
                  onChange={(e) => handleCurrency(e.target.value)}
                />
              </div>
            </div>
            <div className={'row'}>
              <div className={`${sizesClass.notes}`}>
                <BwmInput
                  name="note"
                  disabled={props.disabled}
                  label={`${labels.NOTES}`}
                  maxLength={255}
                  placeholder={labels.NOTES_PLACEHOLDER}
                  className="form-control"
                  value={values.notes}
                  error={errors.notes}
                  touched={props.touched}
                  onChange={(e) =>
                    Utility.handleChangeValue(e.target.value, 'notes', values, setValues, true)
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="form-row mt-3 justify-content-between align-item-center">
          <button className="btn btn-outline-primary" onClick={props.onHide}>
            <i className="thx-cancel thx-icon me-2" />
            {labels.CANCEL}
          </button>

          <div className="mt-2">
            <b className="me-2">*</b>
            <span>{labels.REQUIRED_FIELD_FOR_FORM_COMPLETION}</span>
          </div>

          <button disabled={props.disabled} className="btn btn-primary" onClick={handleFormSubmit}>
            <i className="thx-floppy-disk thx-icon me-2" />
            {labels.SAVE}
          </button>
        </div>
      </div>
    </div>
  )
}
