import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

// TABLE
import { StarTable } from '../../../shared/tables/StarTable'
import { baseSearchObject, TableUtils } from '../../../shared/tables/TableUtils'

// MODAL
import Modal from 'react-bootstrap/Modal'
import { ArcoRegistryModal } from './NewArcoRegistryModal'

// COMPONENTS
import { PopupError } from '../../../shared/popups/PopupError'

import 'react-datepicker/dist/react-datepicker.css'
import '../../../../styles/autocomplete.css'

export const ArcoRegistriesList = (props) => {
  const dispatch = useDispatch()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [loading, setLoading] = useState(false)

  const [arcoRegistries, setArcoRegistries] = useState([])
  const [rows, setRows] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [searchObject, setSearchObject] = useState({ ...baseSearchObject })

  const showModalRegistriesList = props.showModalRegistriesList
  const [showRegistryModal, setShowRegistryModal] = useState(false)
  const [modalShow, setModalShow] = useState(props.modalShow != undefined ? props.modalShow : true)
  const [showDetailItem, setShowDetailItem] = useState()
  const [depth, setDepth] = useState(props.depth || 1)
  const [detailId, setDetailId] = useState(props.detailId)

  const tableName = 'arco-registry-list'

  const handleClose = () => props.onHide()

  useEffect(() => {
    if (props.detailId && !showRegistryModal && !props.fromHolders && !props.fromSignatories) {
      showDetailFromId()
    }
  })

  useEffect(() => {
    dispatch(actions.getAllArcoSubjectTypes())
  }, [])

  const handlerShowNewRegistryModal = () => {
    setDepth(depth + 1)
    setModalShow(true)
    handlerShowRegistryModal()
  }

  const handlerShowRegistryModal = () => {
    setShowRegistryModal(!showRegistryModal)
    setShowDetailItem(null)
  }

  const handlerCloseRegistryModal = (registry = null) => {
    if (registry && props.onSelectedItem) {
      props.onSelectedItem(registry)
    } else {
      actions
        .getAllArcoRegistriesBySubjectId(props.arcoSubjectId, searchObject)
        .then((res) => setArcoRegistries(res.content || res.data))
    }

    setDetailId(null)
    handlerShowRegistryModal()

    setDepth(depth - 1)
  }

  const getSurnameOrDenomination = (item) => {
    return Constants.PF === item.personType
      ? item.lastName + ' ' + item.firstName
      : item.denomination
  }

  const showDetailFromId = () => {
    for (let i = 0; i < arcoRegistries.length; i++) {
      let element = arcoRegistries[i]
      if (element.id == detailId) {
        showDetail(element)
        break
      }
    }
  }

  const showDetail = (element) => {
    handlerShowRegistryModal()
    setModalShow(true)
    setDepth(depth + 1)
    setShowDetailItem(element)
  }

  const exportRowValues = async (_registry) => {
    const registryId = _registry.id
    const registry = await actions.getArcoRegistryDetail(registryId)
    if (props.fromHolders || props.fromSignatories) {
      props.onSelectedItem(registry)
      handleClose()
    } else if (props.onSelectedItem) {
      registry.isNew = true
      props.onSelectedItem(registry)
      handleClose()
    }
  }

  const getSubjectName = () => {
    return props.arcoSubject?.companyName
  }

  const deleteArcoArcoRegistrySubject = () => {
    const promises = []
    setLoading(true)
    checkedItems.forEach((arcoRegistry) => {
      promises.push(actions.deleteArcoRegistries(arcoRegistry))
    })
    Promise.all(promises)
      .then(() => {
        setLoading(false)
        setCheckedItems([])
        actions.getAllArcoRegistriesBySubjectId(props.arcoSubjectId, searchObject).then((res) => {
          setArcoRegistries(res.content || res.data)
        })
      })
      .catch((errors) => {
        setLoading(false)
        PopupError({
          text: errors.title || labels.ERROR_DELETING_SUBJECT
        })
      })
  }

  const title = () => {
    if (props.selectedItem == undefined && !(props.fromHolders || props.fromSignatories)) {
      return getSubjectName()
    } else if (props?.fromSignatories?.personType == 'LEGAL') {
      return (
        labels.SIGNATORY_OF + ' ' + getSurnameOrDenomination(props.fromSignatories)?.toUpperCase()
      )
    } else if (props.fromHolders) {
      return labels.HOLDER_OF + ' ' + getSurnameOrDenomination(props.fromHolders)?.toUpperCase()
    } else if (props?.fromSignatories?.personType == 'FISICAL') {
      return (
        labels.PROCURATOR_OF + ' ' + getSurnameOrDenomination(props.fromSignatories)?.toUpperCase()
      )
    }
  }

  const header = [
    TableUtils.composeHeader({
      fieldName: 'completeName',
      displayedName: labels.FIRSTLASTNAME_OR_DENOMINATION
    }),
    TableUtils.composeHeader({
      fieldName: 'fiscalCode',
      displayedName: labels.FISCAL_CODE,
      minWidth: '12rem',
      maxWidth: '12rem'
    }),
    TableUtils.composeHeader({
      fieldName: 'location',
      displayedName: labels.LOCATION,
      minWidth: '18rem',
      maxWidth: '18rem'
    }),
    TableUtils.composeHeader({
      fieldName: 'detail',
      displayedName: labels.DETAIL,
      minWidth: '100px',
      maxWidth: '100px',
      searchable: false
    })
  ]

  const prepareRows = (newSubjects) => {
    return newSubjects.map((c) => {
      return TableUtils.composeRow({
        id: c.id,
        cellData: {
          completeName: TableUtils.composeCell({
            fieldName: 'completeName',
            fieldValue: c.completeName
          }),
          fiscalCode: TableUtils.composeCell({
            fieldName: 'fiscalCode',
            fieldValue: c.fiscalCode
          }),
          location: TableUtils.composeCell({
            fieldName: 'location',
            fieldValue: c.birthLocation?.toUpperCase() || c.location?.toUpperCase()
          }),
          detail: TableUtils.composeCell({
            fieldName: 'detail',
            component: () => {
              return (
                <div className="w-100 d-flex justify-content-center">
                  <button
                    title={labels.DETAIL}
                    className="btn btn-outline-primary btn-cell"
                    onClick={() => showDetail(c)}>
                    <i className="thx-eye thx-icon" />
                  </button>
                </div>
              )
            }
          })
        }
      })
    })
  }

  useEffect(() => {
    setRows(prepareRows(arcoRegistries))
  }, [arcoRegistries])

  return (
    <>
      <Modal
        backdrop="static"
        show={showModalRegistriesList}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className="arco-modal-max mt-0 mb-1"
        centered
        scrollable>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{title()}</h2>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="pt-1 overflow-x-hidden">
          <div className="row header-btn-arcoRegistries d-flex align-items-center pb-1">
            <div className="d-flex justify-content-between pt-3 align-items-center mb-1">
              <div>
                <button
                  type="button"
                  disabled={props.disabled || checkedItems.length === 0}
                  className={`btn btn-outline-primary   ${checkedItems.length > 0 ? '' : 'disabled'}`}
                  onClick={() => deleteArcoArcoRegistrySubject()}>
                  <i className="thx-trash thx-icon me-2" />
                  {labels.DELETE}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  disabled={props.disabled}
                  className="btn btn-primary"
                  onClick={handlerShowNewRegistryModal}>
                  <i className="thx-plus thx-icon me-2" />
                  {labels.ADD_NEW_REGISTRY}
                </button>
              </div>
            </div>
          </div>

          <StarTable
            version="1.2"
            loading={loading}
            checkedItems={checkedItems}
            headerColumns={header}
            searchObjectPrototype={searchObject}
            offset={350}
            rows={rows}
            tableConfigurationKey={tableName}
            usePagination={true}
            useDrag={false}
            useResize={false}
            withCheckBoxes={true}
            setSearchObject={setSearchObject}
            onExecutedSearch={(content) => setArcoRegistries(content)}
            searchCallBack={(searchObject) => {
              return actions.getAllArcoRegistriesBySubjectId(props.arcoSubjectId, searchObject)
            }}
            onClickRow={exportRowValues}
            onClickCheck={setCheckedItems}
          />

          {showRegistryModal && modalShow && (
            <div>
              <ArcoRegistryModal
                arcoSubjectId={props.arcoSubjectId}
                disabled={props.disabled}
                arcoSubject={props.arcoSubject}
                arcoRegistries={arcoRegistries}
                selectedItem={showDetailItem}
                showModalCheck={showRegistryModal}
                onHide={handlerCloseRegistryModal}
                depth={depth}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button className="btn btn-outline-primary " onClick={handleClose}>
              <i className="thx-cancel thx-icon me-2" />
              {labels.CANCEL}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
