import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as Constants from '../../../config/Constants'
import { getAllBlueSubjects } from '../../../actions'
import getText from '../../shared/i18n/labels'

// TABLE
import { baseSearchObject, TableUtils } from '../../shared/tables/TableUtils'
import { StarTable } from '../../shared/tables/StarTable'

// FORM
import BlueSubjectForm from './NewBlueSubjectForm'

import '../../../styles/dac7NewStyles.css'

const getTableContainerWidth = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return '140%'
  return '100%'
}

const getOffset = () => {
  if (window.innerWidth <= Constants.BREAKPOINT_MD) return 435
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return 420
  return 370
}

const BlueSubjectList = (props) => {
  const [show] = useState(false)
  const [rows, setRows] = useState([])
  const history = useHistory()

  const [subject, setSubject] = useState()
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [searchObject, setSearchObject] = useState(baseSearchObject)

  const tableUtils = TableUtils
  const tableName = 'blue-subject'

  useEffect(() => {
    setRows(prepareRows(props.subjects || []))
    setSubject(subject ? subject : props.subjects?.[0])
  }, [props.subjects])

  useEffect(() => {
    setSubject()
  }, [props.resetForm])

  const setNewSubjects = (content, res) => {
    props.setSubjects(res)
  }

  const openDetail = (subject) => {
    const path = `/app/blue/dashboard/search/${subject.id}`
    history.push(path)
  }

  const onClickRow = (row) => {
    let _subject
    if (row.id && props.subjects?.length > 0) {
      _subject = props.subjects.find((el) => el.id === row.id)
    }
    setSubject(_subject)
  }

  const header = [
    tableUtils.composeHeader({
      fieldName: 'fiscalCode',
      searchable: false,
      displayedName: labels.FISCALCODE,
      maxWidth: '2fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'denomination',
      searchable: false,
      displayedName: labels.DENOMINATION,
      maxWidth: '5fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'emailAddress',
      searchable: false,
      displayedName: labels.EMAILADDRESS,
      maxWidth: '4fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'address',
      searchable: false,
      displayedName: labels.ADDRESS,
      maxWidth: '4fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'historyAction',
      sortable: false,
      searchable: false,
      additionalClass: 'justify-content-center',
      displayedName: labels.HISTORY,
      maxWidth: '1fr'
    })
  ]

  const prepareRows = (newSubjects) => {
    return newSubjects.map((row) => {
      return tableUtils.composeRow({
        id: row.id,
        cellData: {
          fiscalCode: tableUtils.composeCell({
            fieldName: 'fiscalCode',
            fieldValue: row.fiscalCode
          }),
          denomination: tableUtils.composeCell({
            fieldName: 'denomination',
            fieldValue: row.denomination
          }),
          emailAddress: tableUtils.composeCell({
            fieldName: 'emailAddress',
            fieldValue: row.email
          }),
          address: tableUtils.composeCell({
            fieldName: 'address',
            fieldValue: row.address
          }),
          historyAction: tableUtils.composeCell({
            fieldName: 'historyAction',
            additionalClass: 'd-flex justify-content-center p-0',
            component: () => {
              return (
                <div className="d-flex justify-content-center p-0">
                  <button
                    title="Mostra storico"
                    className="btn btn-outline-primary btn-table"
                    onClick={() => props.showHistory(row.id)}>
                    <i className="thx-history thx-icon" />
                  </button>
                </div>
              )
            }
          })
        }
      })
    })
  }

  return (
    <StarTable
      id={subject?.id}
      version="1"
      headerColumns={header}
      rows={rows}
      getOffset={getOffset}
      tableContainerWidth={getTableContainerWidth()}
      loading={show}
      tableConfigurationKey={tableName}
      searchObjectPrototype={searchObject}
      setSearchObject={setSearchObject}
      checkedItems={props.checkedItems}
      language={lang}
      searchCallBack={(searchObject) => getAllBlueSubjects(searchObject)}
      onExecutedSearch={(content, res) => setNewSubjects(content, res)}
      usePagination={false}
      useSort={false}
      sortStyle={{}}
      withCheckBoxes={true}
      onClickRow={onClickRow}
      onDoubleClickRow={openDetail}
      onClickCheck={props.setSelectedSubjects}
      actionsComponent={props.actionsComponent}
      formComponent={BlueSubjectForm}
      formComponentProps={{
        id: subject?.id,
        disabled: props.disabled,
        formOnBottom: true,
        subject: subject,
        getSubjects: props.getSubjects,
        close: () => setSubject(subject ? subject : undefined)
      }}
    />
  )
}

export default BlueSubjectList
