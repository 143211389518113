import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Constants from '../../../../config/Constants'
import { IsLicenseValid } from '../../../shared/auth/license/LicenseChecker'
import { APPLICATION_CORA } from '../../../../config/Constants'
import { base64ToArrayBuffer, downloadFile, formatDateForDisplay, notifySuccess } from '../../../shared/Utility'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// TABLE
import { baseSearchObject, fieldTypes, TableUtils } from '../../../shared/tables/TableUtils'
import { StarTable } from '../../../shared/tables/StarTable'

// MODAL
import { MultiImportModal } from '../import/NewMultiImportModal'
import { ImportDataModal } from '../import/NewImportDataModal'
import CoraImportRelationsModal from '../subject-cora/NewCoraImportRelationsModal'

// FORM
import DatePicker from 'react-datepicker'
import { RelationshipForm } from './NewCoraRelationshipForm'
import ImportConsitencyRelationshipsForm from '../import/ImportConsistencyRelationshipsForm'

// BUTTONS
// import ButtonRelationshipModal from './relationship-modal/ButtonRelationshipModal'
import { DropdownButton } from 'react-bootstrap'

// COMPONENTS
import Dropdown from 'react-bootstrap/Dropdown'
import { PopupDelete } from '../../../shared/popups/PopupDelete'
import { PopupError } from '../../../shared/popups/PopupError'

import '../../../../styles/relationship-form.css'
import { StarTooltip } from '../../../shared/tooltips/Tooltip'
import { YearlyBalanceImportModal } from '../balances/NewYearlyBalanceImportModal'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'

const getTableContainerWidth = () => {
  if (window.innerWidth < Constants.BREAKPOINT_MD) return '240%'
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return '150%'
  return '100%'
}

const getOffset = () => {
  if (window.innerHeight < Constants.BREAKPOINT_HEIGHT_FORM_ON_BOTTOM) return 400
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return 525
  return 510
}

const DatePickerCustomInput = React.forwardRef(({ value, onClick, disabled }, ref) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  return (
    <StarTooltip text={labels.SET_CLOSING_DATE}>
      <button
        ref={ref} // Pass the ref to the button
        type="button"
        className={`btn btn-outline-primary ms-1 me-1 ${disabled ? 'disabled' : ''}`}
        disabled={disabled}
        onClick={onClick}>
        <i className="thx-calendar thx-icon" />
        {window.innerWidth > Constants.BREAKPOINT_MD ? (
          <span className="ms-2">{labels.SET_CLOSING_DATE}</span>
        ) : (
          ''
        )}
      </button>
    </StarTooltip>
  )
})

const CommunicationToggle = React.forwardRef(({ children: disabled, onClick }, ref) => {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  return (
    <button
      type={'button'}
      className={`btn btn-outline-primary me-0 ${disabled && 'disabled'}`}
      disabled={disabled}
      ref={ref}
      onClick={(e) => {
        if (!disabled) {
          onClick(e)
        }
      }}>
      <i className="thx-list-solid thx-icon me-2" />
      {labels.TO_BE_COMMUNICATED_AS} &nbsp; &#9660;
    </button>
  )
})

export function RelationshipsList(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [endDateUpload, setEndDateUpload] = useState(new Date())
  const [id, setId] = useState(null)
  const auth = useSelector((state) => state.auth)
  const history = useHistory()
  const [expired, setExpired] = useState(false)

  const [showImportModal, setShowImportModal] = useState(false)
  const [showImportConsistencyModal, setShowImportConsistencyModal] = useState(false)
  const [showSubjectImportModal, setShowSubjectImportModal] = useState(false)
  const [showRelationshipImportModal, setShowRelationshipImportModal] = useState(false)
  const [showImportBalanceModal, setShowImportBalanceModal] = useState(false)

  const [relationships, setRelationships] = useState([])

  const communicationTypes = useSelector((state) =>
    state.communicationTypes.data.filter((el) => Constants.exludedCommTypes.indexOf(el.code) === -1)
  )
  const relationshipTypes = useSelector((state) => state.relationshipTypes.data)

  const relationshipLocations = useSelector((state) =>
    state.locations.data.map((value, index) => ({
      name: value.location,
      key: index
    }))
  )

  const relationshipProvinces = useSelector((state) =>
    state.locations.data.map((value, index) => ({
      name: value.province,
      key: index
    }))
  )

  const roles = useSelector((state) => state.roles.data)
  const currencies = useSelector((state) => state.currencies.data)

  const [filterLabel, setFilterLabel] = useState(labels.LIST_INITIAL)

  const [checkedItems, setCheckedItems] = useState([])

  const [clonedRelationshipId, setClonedRelationshipId] = useState()

  const [searchObject, setSearchObject] = useState({
    ...baseSearchObject
  })

  const tableUtils = TableUtils
  const tableName = 'cora-relationships-subject'

  const filterTypes = {
    DEFAULT: {
      searchField: 'DEFAULT',
      label: labels.LIST_OF_RELATIONSHIPS
    },
    OPEN_ONLY: {
      searchField: 'OPEN_ONLY',
      label: labels.RELATIONSHIPS_ONLY_OPEN
    },
    CLOSED_ONLY: {
      searchField: 'CLOSED_ONLY',
      label: labels.LIST_OF_RELATIONSHIPS_CLOSED
    },
    CANCELED_AND_COMMUNICATED: {
      searchField: 'CANCELED_AND_COMMUNICATED',
      label: labels.LIST_OF_RELATIONSHIPS_CANCELED_AND_COMMUNICATED
    },
    INCLUDED_IN_NEXT_COMMUNICATION: {
      searchField: 'INCLUDED_IN_NEXT_COMMUNICATION',
      label: labels.RELATIONSHIPS_TO_SEND
    },
    BY_MISSING_IN_CONSISTENCY: {
      searchField: 'BY_MISSING_IN_CONSISTENCY',
      label: labels.PHOTOGRAPHY_RESULTS
    }
  }

  useEffect(() => {
    reloadRelationships()
    setExpired(isExpired())
  }, [])

  useEffect(() => {
    setExpired(isExpired())
  }, [auth])

  const isExpired = () => {
    if (auth?.isAuth) {
      return !IsLicenseValid(auth.customer?.customerApplications, APPLICATION_CORA)
    }
  }

  useEffect(() => {
    if (expired) history.push(`/app/cora/dashboard/`)
  }, [expired])

  const searchRelationships = (newSearchObject) => {
    const criteria = Object.values(filterTypes).find((e) => e.label === filterLabel)
    newSearchObject = {
      ...newSearchObject,
      criteria: newSearchObject.criteria ? newSearchObject.criteria : criteria?.searchField
    }
    setLoading(true)
    return actions.getRelationshipsBySubjectId(props.subjectId, newSearchObject).finally(() => {
      setLoading(false)
    })
  }

  const reloadRelationships = () => {
    searchRelationships(searchObject).then((data) => {
      setNewRelationships(data?.content || [], data)
    })
  }

  const setNewRelationships = (content, res) => {
    setRelationships(content)
    setSearchObject({ ...baseSearchObject, totalElements: res?.totalElements })
  }

  const handleFilterChange = (filter) => {
    setFilterLabel(filter.label)
    setLoading(true)
    const newSearchObject = {
      ...baseSearchObject,
      pageSize: searchObject.pageSize,
      criteria: filter.searchField
    }
    setSearchObject(newSearchObject)
    searchRelationships(newSearchObject).then((data) => {
      setNewRelationships(data.content, data)
      setLoading(false)
    })
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: labels.RELATIONSHIP,
      handleClickConfirm: handlerDeleteRelationship
    })
  }

  const handlerDeleteRelationship = () => {
    let ids = []
    for (const checkedItem of checkedItems) {
      if (checkedItem) {
        ids.push(parseInt(checkedItem))
      }
    }
    setLoading(true)
    actions.deleteCoraRelationship(ids, props.subjectId).then(
      () => {
        notifySuccess(labels.REMOVED_SUCCESSFULLY)
        reloadRelationships()
        setLoading(false)
        setCheckedItems([])
      },
      (e) => PopupError({ text: e.title || labels.GENERIC_ERROR })
    )
  }

  const handlerUpdateEndDate = () => {
    let ids = []
    for (const checkedItem of checkedItems) {
      if (checkedItem) {
        let relationshipChecked = relationships.find((rel) => rel.id === parseInt(checkedItem))
        ids.push(relationshipChecked.id)
      }
    }

    if (ids.length > 0) {
      setLoading(true)
      actions
        .updateCoraRelationshipEndDate({ ids: ids, endDate: endDateUpload }, props.subject?.id)
        .then(
          () => {},
          (errors) => {
            setLoading(false)
            PopupError({ text: labels.GENERIC_ERROR })
          }
        )
        .then(() => {
          reloadRelationships()
        })
        .then(() => {
          resetCheckBox()
          setLoading(false)
        })
    }
  }

  const handlerDontSendCommunication = () => {
    let checkedIds = []
    for (const checkedItem of checkedItems) {
      if (checkedItem) {
        checkedIds.push(parseInt(checkedItem))
      }
    }
    setLoading(true)
    actions
      .removeNextCommunication(checkedIds)
      .then(
        () => {
          setLoading(false)
        },
        (errors) => {
          setLoading(false)
          PopupError({ text: labels.GENERIC_ERROR })
        }
      )
      .then(() => {
        reloadRelationships()
      })
      .then(() => {
        resetCheckBox()
        setLoading(false)
      })
  }

  const handlerUpdateCommType = (communicationTypeId) => {
    if (props.subject?.coraSubjectSid) {
      let ids = []
      for (const checkedItem of checkedItems) {
        if (checkedItem) {
          let relationshipChecked = relationships.find((rel) => rel.id === parseInt(checkedItem))
          ids.push(relationshipChecked.id)
        }
      }
      setLoading(true)
      actions
        .updateCoraRelationshipCommunicationType(
          { ids: ids, communicationTypeId: communicationTypeId },
          props.subject.id
        )
        .then(
          () => {
            setLoading(false)
          },
          () => {
            setLoading(false)
            PopupError({ text: labels.GENERIC_ERROR })
          }
        )
        .then(() => {
          reloadRelationships()
        })
        .then(() => {
          resetCheckBox()
          setLoading(false)
        })
    } else {
      PopupError({
        text: labels.ERROR_NECESSARY_SID_SUBJECT,
        labels
      })
    }
  }

  const resetCheckBox = () => {
    setCheckedItems([])
    setDisabled(true)
  }

  const setDropdownCommType = () => {
    return communicationTypes.map((option, index) => (
      <Dropdown.Item key={index} eventKey={option.id}>
        {option.code + '-' + option.description}
      </Dropdown.Item>
    ))
  }

  const callDownloadPdfListService = () => {
    setLoading(true)
    let companyName =
      props.subject?.personType === Constants.PNF
        ? props.subject?.companyName
        : props.subject?.lastName + ' ' + props.subject?.firstName

    let relationshipIds = []
    for (const [key, value] of checkedItems) {
      if (value) {
        relationshipIds.push(key)
      }
    }
    setSpinner(true)
    actions
      .downloadRelationshipPdf(relationshipIds, props.subjectId, searchObject)
      .then(
        (response) => {
          setSpinner(false)
          if (response.length > 0) {
            downloadFile(base64ToArrayBuffer(response), companyName + '_RELATIONSHIPS.pdf')
          }
        },
        (error) => {
          setSpinner(false)
          PopupError({ text: labels.ERROR_DOWNLOAD_PDF })
        }
      )
      .then(() => setLoading(false))
  }

  const headerComponent = () => {
    return (
      <div className="mb-2">
        <div className="row header-btn-relationship align-items-center">
          <>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <Dropdown
                  onSelect={(eventKey) => {
                    handleFilterChange(filterTypes[eventKey])
                  }}>
                  <DropdownButton
                    title={filterLabel}
                    bsPrefix={'dropdown-menu-green btn-fill m-0 me-1 pe-2'}>
                    <Dropdown.Item
                      eventKey={'DEFAULT'}
                      active={filterLabel === filterTypes.DEFAULT.label}
                      onSelect={() => handleFilterChange(filterTypes.DEFAULT)}>
                      {labels.LIST_OF_RELATIONSHIPS}
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey={'OPEN_ONLY'}
                      active={filterLabel === filterTypes.OPEN_ONLY.label}
                      onSelect={() => handleFilterChange(filterTypes.OPEN_ONLY)}>
                      {labels.RELATIONSHIPS_ONLY_OPEN}
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey={'CLOSED_ONLY'}
                      active={filterLabel === filterTypes.CLOSED_ONLY.label}
                      onSelect={() => handleFilterChange(filterTypes.CLOSED_ONLY)}>
                      {labels.CLOSED_RELATIONSHIPS}
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey={'CANCELED_AND_COMMUNICATED'}
                      active={filterLabel === filterTypes.CANCELED_AND_COMMUNICATED.label}
                      onSelect={() => handleFilterChange(filterTypes.CANCELED_AND_COMMUNICATED)}>
                      {labels.CANCELED_RELATIONSHIPS}
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey={'INCLUDED_IN_NEXT_COMMUNICATION'}
                      active={filterLabel === filterTypes.INCLUDED_IN_NEXT_COMMUNICATION.label}
                      onSelect={() =>
                        handleFilterChange(filterTypes.INCLUDED_IN_NEXT_COMMUNICATION)
                      }>
                      {labels.RELATIONSHIPS_TO_SEND}
                    </Dropdown.Item>
                    <Dropdown.Item
                      eventKey={'BY_MISSING_IN_CONSISTENCY'}
                      active={filterLabel === filterTypes.BY_MISSING_IN_CONSISTENCY.label}
                      onSelect={() => handleFilterChange(filterTypes.BY_MISSING_IN_CONSISTENCY)}>
                      {labels.PHOTOGRAPHY_RESULTS}
                    </Dropdown.Item>
                  </DropdownButton>
                </Dropdown>
                <div className="btn-group" role="group" aria-label="action buttons">
                  <div className="me-1">
                    <StarTooltip text={labels.IMPORT}>
                      <button
                        type="button"
                        className={`btn btn-outline-primary w-100 m-0 me-1`}
                        onClick={(e) => setShowImportModal(true)}>
                        <i className="thx-file-import thx-icon" />
                        {window.innerWidth > Constants.BREAKPOINT_MD && (
                          <span className="ps-2">{labels.IMPORT}</span>
                        )}
                      </button>
                    </StarTooltip>
                  </div>
                  <div className="me-1">
                    <StarTooltip text={labels.PRINT_RELATIONSHIPS}>
                      <button
                        type="button"
                        className={`btn btn-outline-primary w-100 ${relationships?.length === 0 && 'disabled'}`}
                        disabled={relationships?.length === 0}
                        onClick={() => callDownloadPdfListService()}>
                        <i className="thx-pdf thx-icon margin-top-2px" />
                        {window.innerWidth > Constants.BREAKPOINT_MD && (
                          <span className="ps-2">{labels.PRINT_RELATIONSHIPS}</span>
                        )}
                      </button>
                    </StarTooltip>
                  </div>
                </div>
                <div className="btn-group" role="group" aria-label="action buttons">
                  <div className="me-1">
                    <button
                      type="button"
                      className={`btn btn-outline-primary m-0 ${disabled && 'disabled'}`}
                      disabled={disabled}
                      onClick={(e) => handlerDontSendCommunication()}>
                      {window.innerWidth > Constants.BREAKPOINT_LG ? (
                        <i className="thx-exclude thx-icon me-2" />
                      ) : (
                        ''
                      )}
                      {labels.EXCLUDE_FROM_COMMUNICATION}
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      className={`btn btn-outline-primary m-0 ${disabled && 'disabled'}`}
                      onClick={() => openPopupDelete()}
                      disabled={disabled}>
                      <i className="thx-trash thx-icon me-2" />
                      {labels.DELETE}
                    </button>
                  </div>
                  <div>
                    <DatePicker
                      onChange={(date) => setEndDateUpload(date)}
                      selected={endDateUpload}
                      customInput={<DatePickerCustomInput disabled={disabled} labels={labels} />}
                      locale="it"
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      showMonthDropdown
                      disabled={disabled}
                      shouldCloseOnSelect={false}>
                      <div className="text-center">
                        <button
                          type="button"
                          className={`btn btn-outline-primary m-2  `}
                          onClick={() => handlerUpdateEndDate()}>
                          {labels.CONFIRM + ': ' + formatDateForDisplay(endDateUpload)}
                        </button>
                      </div>
                    </DatePicker>
                  </div>
                  <Dropdown onSelect={(option) => handlerUpdateCommType(option)}>
                    <Dropdown.Toggle
                      as={CommunicationToggle}
                      variant="success"
                      id="dropdown-comm-type">
                      {disabled}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>{setDropdownCommType()}</Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {/* <ButtonRelationshipModal
            subject={props.subject}
            subjectId={props.subjectId}
            relationshipTypes={relationshipTypes}
            relationshipLocations={relationshipLocations}
            relationshipProvinces={relationshipProvinces}
            communicationTypes={communicationTypes}
            currencies={currencies}
            roles={roles}
            onClone={setClonedRelationshipId}
            getRelationships={reloadRelationships}
          /> */}
              <button type="button" className="btn btn-primary" onClick={() => setId()}>
                <i className={`thx-plus thx-icon ${window.innerWidth < 1600 ? '' : 'me-2'}`} />
                {window.innerWidth < 1600 ? '' : labels.NEW_RELATIONSHIP}
              </button>
            </div>
          </>
        </div>
      </div>
    )
  }

  const header = [
    tableUtils.composeHeader({
      fieldName: 'completeName',
      orderingName: 'completeName',
      displayedName: labels.REGISTRY,
      maxWidth: '1.7fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'fiscalCode',
      displayedName: labels.FISCAL_CODE,
      maxWidth: '1.3fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'startDate',
      type: fieldTypes.DATE,
      displayedName: labels.START_DATE,
      maxWidth: '1fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'endDate',
      type: fieldTypes.DATE,
      displayedName: labels.END_DATE,
      maxWidth: '1fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'relationshipTypes',
      type: fieldTypes.SELECTION,
      options: relationshipTypes || [],
      displayedName: labels.RELATIONSHIP,
      maxWidth: '1.5fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'comment',
      displayedName: labels.NOTE,
      searchable: true,
      maxWidth: '1.2fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'identifier',
      displayedName: labels.UNIQUE_CODE,
      searchable: true,
      maxWidth: '2.7fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'communicationTypes',
      type: fieldTypes.SELECTION,
      options: communicationTypes || [],
      displayedName: labels.TO_SEND,
      maxWidth: '2fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'lastCommunicationError',
      displayedName: labels.ERRORS,
      maxWidth: '1.2fr'
    }),
    tableUtils.composeHeader({
      fieldName: 'codError',
      displayedName: labels.ERROR_CODE,
      maxWidth: '1.3fr'
    })
  ]

  const prepareRows = (newRelations = []) => {
    const list = Array.isArray(newRelations) ? newRelations : []
    return list.map((c) => {
      return tableUtils.composeRow({
        id: c.id,
        rowData: c,
        cellData: {
          completeName: tableUtils.composeCell({
            fieldName: 'completeName',
            fieldValue: c.completeName
          }),
          fiscalCode: tableUtils.composeCell({
            fieldName: 'fiscalCode',
            fieldValue: c.fiscalCode
          }),
          startDate: tableUtils.composeCell({
            fieldName: 'startDate',
            fieldValue: c.startDate
          }),
          province: tableUtils.composeCell({
            fieldName: 'endDate',
            fieldValue: c.endDate
          }),
          relationshipType: tableUtils.composeCell({
            fieldName: 'relationshipTypes',
            fieldValue: c.relationshipType
          }),
          comment: tableUtils.composeCell({
            fieldName: 'comment',
            fieldValue: c.comment
          }),
          identifier: tableUtils.composeCell({
            fieldName: 'identifier',
            fieldValue: c.identifier
          }),
          communicationType: tableUtils.composeCell({
            fieldName: 'communicationTypes',
            fieldValue: c.coraCommunicationType
          }),
          lastCommunicationError: tableUtils.composeCell({
            fieldName: 'lastCommunicationError',
            fieldValue: c.lastCommunicationError
          }),
          codError: tableUtils.composeCell({
            fieldName: 'codError',
            fieldValue: c.codError
          })
        }
      })
    })
  }

  const setNewCheckedItems = (list = []) => {
    setDisabled(list.length === 0)
    setCheckedItems(list)
  }

  const getRowClass = (rowData) => {
    if (rowData.id === clonedRelationshipId) {
      return rowData.id !== id ? 'bg-green-row' : 'bg-green-row-with-id'
    } else if (rowData.lastCommunicationError) {
      return rowData.id !== id ? 'bg-yellow-row' : 'bg-yellow-row-with-id'
    } else if (rowData.includedInNextCommunication) {
      return rowData.id !== id ? 'bg-pink-row' : 'bg-pink-row-with-id'
    }
  }

  // const onHide = () => {
  //   setId(undefined)
  //   reloadRelationships()
  // }

  const onClickRow = (row) => {
    setId(row.id)
  }

  useEffect(() => {
    setRows(prepareRows(relationships))
    setId(id ? id : relationships?.[0]?.id)
  }, [relationships])

  return (
    <div>
      {spinner && <PageSpinner text={labels.SPINNER_TITLE} />}
      {headerComponent()}
      {showImportModal && (
        <MultiImportModal
          show={showImportModal}
          onHide={() => setShowImportModal(false)}
          components={[
            () => (
              <button
                className="btn btn-primary btn-lg me-2"
                onClick={() => setShowSubjectImportModal(true)}>
                {labels.SUBJECT_FROM_CORATU}
              </button>
            ),
            () => (
              <button
                className="btn btn-primary btn-lg me-2"
                onClick={() => setShowSubjectImportModal(true)}>
                {labels.RELATIONSHIPS_FROM_COMMUNICATION}
              </button>
            ),
            () => (
              <button
                className="btn btn-primary btn-lg me-2"
                onClick={() => setShowRelationshipImportModal(true)}>
                {labels.RELATIONSHIPS_FROM_CSV}
              </button>
            ),
            () => (
              <button
                className="btn btn-primary btn-lg me-2"
                onClick={() => {
                  setShowImportBalanceModal(true)
                  setShowImportModal(false)
                }}>
                {labels.BALANCES_FROM_CSV}
              </button>
            ),
            () => (
              <button
                className="btn btn-primary btn-lg me-2"
                onClick={() => setShowImportConsistencyModal(true)}>
                {labels.ALIGNMENT_RELATIONSHIPS_TO_LAST_PHOTOGRAPHY}
              </button>
            )
          ]}
        />
      )}
      {showSubjectImportModal && (
        <ImportDataModal
          subjectId={props.subjectId}
          show={showSubjectImportModal}
          onSuccess={() => {
            reloadRelationships()
            setShowSubjectImportModal(false)
          }}
          onClose={() => {
            setShowSubjectImportModal(false)
          }}
        />
      )}
      {showImportConsistencyModal && (
        <ImportConsitencyRelationshipsForm
          show={showImportConsistencyModal}
          onHide={() => setShowImportConsistencyModal(false)}
          onSuccess={() => {
            reloadRelationships()
            setShowImportConsistencyModal(false)
          }}
        />
      )}
      {showRelationshipImportModal && (
        <CoraImportRelationsModal
          subject={props.subject}
          show={showRelationshipImportModal}
          onClose={() => setShowRelationshipImportModal(false)}
          onSuccess={() => {
            reloadRelationships()
            setShowRelationshipImportModal(false)
          }}
        />
      )}
      {showImportBalanceModal && (
        <YearlyBalanceImportModal
          subjectId={props.subjectId}
          hideButton={true}
          onClose={() => {
            setShowImportBalanceModal(false)
            setShowImportModal(false)
          }}
          getYearlyBalance={() => {
            props.onImportedBalances()
          }}
        />
      )}
      <StarTable
        id={id}
        version="1.1"
        headerColumns={header}
        checkedItems={checkedItems}
        rows={rows}
        formOnBottom={true}
        rowClass={getRowClass}
        getOffset={getOffset}
        tableContainerWidth={getTableContainerWidth()}
        perPageOptions={[10, 20, 50, 100, 250, 500]}
        tableConfigurationKey={tableName}
        searchObjectPrototype={searchObject}
        setSearchObject={setSearchObject}
        searchCallBack={(searchObject) => {
          setId()
          return searchRelationships(searchObject)
        }}
        onExecutedSearch={(content, res) => setNewRelationships(content, res)}
        usePagination={true}
        withCheckBoxes={true}
        onClickCheck={setNewCheckedItems}
        onClickRow={onClickRow}
        formComponent={RelationshipForm}
        formComponentProps={{
          disabled: expired || loading,
          formOnBottom: true,
          id: id,
          subjectId: props.subjectId,
          subject: props.subject,
          relationshipLocations: relationshipLocations,
          relationshipProvinces: relationshipProvinces,
          communicationTypes: communicationTypes,
          currencies: currencies,
          roles: roles,
          setLoading: setLoading,
          onClone: setClonedRelationshipId,
          getRelationships: reloadRelationships,
          close: (newId) => {
            setId(newId || id ? id : undefined)
            reloadRelationships()
          }
        }}
        // dialogComponent={
        //   id !== null &&
        //   id !== undefined && (
        //     <ModalRelationship
        //       show={id !== null && id !== undefined}
        //       id={id}
        //       subjectId={props.subjectId}
        //       subject={props.subject}
        //       relationshipLocations={relationshipLocations}
        //       relationshipProvinces={relationshipProvinces}
        //       communicationTypes={communicationTypes}
        //       currencies={currencies}
        //       roles={roles}
        //       onClone={setClonedRelationshipId}
        //       getRelationships={reloadRelationships}
        //       onHide={onHide}
        //     />
        //   )
        // }
      />
    </div>
  )
}
