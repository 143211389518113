import { useState } from 'react'
import { useSelector } from 'react-redux'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// MODAL
import Modal from 'react-bootstrap/Modal'

// BUTTON
import { BtnEmpty } from '../../../shared/BtnEmpty'

import { PopupError } from '../../../shared/popups/PopupError'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { UploadFileLabel } from '../../../shared/form/UploadFileLabel'
import { notifySuccess } from '../../../shared/Utility'

export function ArcoSubjectImportDocumentModal(props) {
  const [dataFile, setDataFile] = useState([])
  const [errorDataFile, setErrorDataFile] = useState({ isValid: true, msg: '' })
  const [loadingSpinner, setLoadingSpinner] = useState(false)

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const closeModal = () => {
    props.onHide()
  }

  const checkFileName = (name) => {
    const nameSplit = name.split('.').pop().toLowerCase()
    switch (nameSplit) {
      case 'ariw':
      case 'ari':
      case 'arc':
      case 'arcw':
        return true
      default:
        return false
    }
  }

  const handleUploadFile = (val) => {
    if (isEmpty(val)) {
      setErrorDataFile({ isValid: false, msg: labels.REQUIRED_FIELD })
    }
    let files = []
    for (let i = 0; i < val.target.files.length; i++) {
      if (!checkFileName(val.target.files[i].name)) {
        setErrorDataFile({ isValid: false, msg: labels.INVALID_FILE_EXTENSION_OR_NAME })
      } else {
        setErrorDataFile({ isValid: true, msg: '' })
        files.push(val.target.files[i])
      }
    }
    if (files.length > 0) setDataFile(files)
  }

  const handleSendDataFile = () => {
    let formData = new FormData()
    if (dataFile?.length > 0) {
      for (let i = 0; i < dataFile.length; i++) {
        formData.append('files', dataFile[i])
      }
    } else {
      setErrorDataFile({ isValid: false, msg: labels.PLEASE_SELECT_AN_ARI_FILE })
      return
    }

    const getTextError = (url, fileName) => {
      return lang === 'it'
        ? `   <div>Cliccare sulla riga del soggetto e verificare le operazioni importate</div>
                    <div>
                        <a href="${url}" download="${fileName}">
                            <button class="${'btn btn-primary mt-3'}">
                                Controlla il file LOG
                            </button>
                        </a>
                    </div>
                  `
        : `   <div>Click on the row of the subject and check the imported operations</div>
                    <div>
                        <a href="${url}" download="${fileName}">
                            <button class="${'btn btn-primary mt-3'}">
                                Check the LOG file
                            </button>
                        </a>
                    </div>
                `
    }

    setLoadingSpinner(true)
    actions
      .importARIWFile(formData)
      .then(
        (res) => {
          if (res) {
            const responseArray = res.split(/\r?\n/g)
            if (responseArray.length > 1) {
              const url = window.URL.createObjectURL(new Blob([responseArray.join('\r\n')]))
              const link = document.createElement('a')
              link.href = url
              const fileName = `${responseArray[0]}.txt`

              PopupSuccess({
                title: labels.IMPORT_DATA_COMPLETED_CORRECTLY,
                text: getTextError(url, fileName)
              })
            } else {
              notifySuccess(labels.SAVED_SUCCESSFULLY)
            }
          }
        },
        (err) => {
          if (err.errorKey === 'FileSizeTooBig') {
            PopupSuccess({
              title: labels.FILE_UPLOADED_SUCCESSFULLY,
              text: labels.THE_COMPLETION_OF_THE_OPERATION_WILL_BE_NOTIFIED_VIA_MAIL_WITH_EVENTUAL_LOGS
            })
          } else if (err?.errorKey === 'MaxSubject') {
            let arco_link =
              lang === 'it'
                ? '<a title="Acquista su Arco" href="https://www.starsoluzioni.it/product/arco-antiriciclaggio/" >ARCO</a>'
                : '<a title="Buy on Arco" href="https://www.starsoluzioni.it/product/arco-antiriciclaggio/" >ARCO</a>'
            let html =
              lang === 'it'
                ? `<p>Acquistare nuovi soggetti nella pagina, cliccare qui ${arco_link}</p>`
                : `<p>Buy new subjects on the page, click here ${arco_link}</p>`

            PopupError({ text: err?.title, second_title: html })
          } else if (err?.title !== null) {
            PopupError({ text: err?.title })
          } else {
            PopupError({ text: labels.ERROR_IMPORTING_THE_SUBJECT })
          }
        }
      )
      .then(() => {
        setLoadingSpinner(false)
        props.getSubjects()
        closeModal()
      })
  }

  const isEmpty = (val) => {
    return val === '' || val == null
  }

  return (
    <>
      <Modal
        backdrop="static"
        show={props.show}
        onHide={closeModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className="bar"></div>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{labels.IMPORT_FILE}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loadingSpinner ? <PageSpinner /> : ''}
          <div className="image-upload text-start mt-3">
            <UploadFileLabel forLabel={'dataFile'} label={'Carica file'} />

            <input
              id={`dataFile`}
              type="file"
              name={`dataFile`}
              multiple
              onChange={(e) => handleUploadFile(e)}
            />
            <div className="certificatiLoaded text-start">
              {!errorDataFile.isValid ? (
                <div className="certificatiLoaded-error">{errorDataFile.msg}</div>
              ) : (
                <div>
                  {dataFile.length > 0 &&
                    dataFile.map((file, index) => {
                      return (
                        <div key={index}>
                          <i className="thx-check thx-icon me-2" />
                          {file.name}
                        </div>
                      )
                    })}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-content-end mt-0">
            <BtnEmpty text={labels.CANCEL} classCustom="float-start" handlerOnClick={closeModal} />
            <button
              disabled={!errorDataFile.isValid || !dataFile}
              onClick={handleSendDataFile}
              type="button"
              className="btn btn-primary px-4">
              {labels.CONFIRM}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}
