import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axiosService from '../../../../services/axios-service'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'
import { Route, useRouteMatch } from 'react-router-dom'

// DETAIL
import { ArcoRegistrations } from '../relationship-arco/NewArcoRegistrations'

// TABLE
import { ArcoSubjectList } from './NewArcoSubjectList'

// MODALS
// import { ArcoSubjectModal } from './NewArcoSubjectModal'
import { ArcoSubjectImportDocumentModal } from './NewArcoSubjectImportDocumentModal'
import { ArcoSubjectExportModal } from './NewArcoSubjectExportModal'
import { ArcoTablesModal } from './NewArcoTablesModal'

// COMPONENTS
import { PopupDelete } from '../../../shared/popups/PopupDelete'
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupSuccess } from '../../../shared/popups/PopupSuccess'
import { LicenseExpiredPopup } from '../../../shared/auth/license/LicenseExpiredPopup'
import { PopupSubjectLicense } from '../../../shared/popups/PopupSubjectLicense'
import { PageSpinner } from '../../../shared/spinner/PageSpinner'
import { CheckRemainingSubjects, IsLicenseValid } from '../../../shared/auth/license/LicenseChecker'
import { SubjectTotalAndCallToAction } from '../../../shared/SubjectTotalAndCallToAction'
import { notifySuccess } from '../../../shared/Utility'

export const ArcoSubject = () => {
  const dispatch = useDispatch()

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [loading, setLoading] = useState(false)
  const [subjects, setSubjects] = useState([])
  const [subject, setSubject] = useState({})
  const [checkedItems, setCheckedItems] = useState([])
  const [emailCustomer, setEmailCustomer] = useState('')
  const [showTableModal, setShowTableModal] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)
  const [subjectSummary, setSubjectSummary] = useState(null)
  const [customerApplication, setCustomerApplication] = useState('')
  const [expired, setExpired] = useState(false)

  const [resetForm, setResetForm] = useState(false)

  const auth = useSelector((state) => state.auth)
  const subjectTypes = useSelector((state) => state.arcoSubjectTypes.data)

  const { path } = useRouteMatch()

  const [relationshipId, setRelationshipId] = useState(null)

  const locations = useSelector((state) =>
    state.locations?.data.map((location, index) => ({
      name: location.location,
      key: index
    }))
  )

  useEffect(() => {
    getSubjectTypes()
    getLocations()
    getCountries()
    getCurrentUser()
    getSubjectSummary()
  }, [])

  useEffect(() => {
    if (expired) LicenseExpiredPopup({})
  }, [expired])

  useEffect(() => {
    setExpired(isExpired() || subjectSummary?.available < 0)
    const application = Constants.APPLICATIONS.find(
      (a) => a.description === Constants.APPLICATION_ARCO
    )
    const customerApplication = auth?.customer?.customerApplications?.find(
      (ca) => ca?.application?.id === application?.id
    )
    setCustomerApplication(customerApplication)
  }, [auth])

  const isExpired = () => {
    if (auth?.isAuth) {
      return !IsLicenseValid(auth.customer?.customerApplications, Constants.APPLICATION_ARCO)
    }
  }

  const getSubjects = (searchObject) => {
    actions.searchArcoSubjects(searchObject).then((res) => {
      if (subjectSummary?.available < 0) {
        PopupSubjectLicense(
          {
            total: subjectSummary?.total,
            used: subjectSummary.used,
            name: Constants.APPLICATION_ARCO
          },
          lang
        )
      }
      setSubjects(res?.content || res?.data)
    })
  }

  const getCurrentUser = () => {
    const axiosInstance = axiosService.getInstance()
    axiosInstance.get(`/api/common/users/current`).then((res) => {
      if (res) {
        setEmailCustomer(res.data.email)
      }
    })
  }

  const getSubjectTypes = () => {
    dispatch(actions.getAllArcoSubjectTypes())
  }

  const getSubjectSummary = () => {
    CheckRemainingSubjects(
      Constants.APPLICATION_ARCO,
      true,
      (subjectSummary) => {
        setSubjectSummary(subjectSummary)
      },
      labels,
      lang
    )
  }

  const getLocations = () => {
    dispatch(actions.getAllLocations())
  }

  const getCountries = () => {
    dispatch(actions.getAllCountries())
  }

  const openPopupDelete = () => {
    PopupDelete({
      operazione: labels.SELECTED_SUBJECTS,
      handleClickConfirm: deleteArcoSubject
    })
  }

  const deleteArcoSubject = () => {
    const promises = []
    checkedItems.forEach((id) => {
      setLoading(true)
      promises.push(actions.deleteArcoSubject(id))
    })
    Promise.all(promises)
      .then(() => {
        setCheckedItems([])
        getSubjects()
        notifySuccess(labels.REMOVED_SUCCESSFULLY)
        setLoading(false)
      })
      .catch(() => {
        PopupError({ text: labels.ERROR_OCCURRED_DURING_OPERATION })
        setLoading(false)
      })
  }

  const closeTableModal = () => {
    setShowTableModal(false)
  }

  const showPopupManageTable = (_subject) => {
    setShowTableModal(true)
    setSubject(_subject)
  }

  const checkSubject = (list = []) => {
    setCheckedItems(list)
  }

  const actionsComponent = () => {
    return (
      <div className="col-12 ps-0">
        <div className="btn-group" role="group" aria-label="action buttons">
          <button
            type="button"
            disabled={Object.keys(checkedItems).length === 0}
            className={`btn btn-outline-primary ${Object.keys(checkedItems).length === 0 ? 'disabled' : ''}`}
            onClick={() => openPopupDelete()}>
            <i className="thx-trash thx-icon me-2" />
            {labels.DELETE}
          </button>
          <ArcoSubjectExportModal
            emailCustomer={emailCustomer}
            disabled={Object.keys(checkedItems).length === 0}
            checkedItems={checkedItems}
            setCheckedItems={() => setCheckedItems([])}
          />
          <button
            type="button"
            data-tip={labels.IMPORT_SUBJECTS_TO_YOUR_LIST}
            className="btn btn-outline-primary"
            disabled={expired || subjectSummary?.available <= 0}
            onClick={() => setShowImportModal(true)}>
            <i className="thx-file-import thx-icon me-2" />
            {labels.IMPORT}
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      {loading && <PageSpinner />}
      <Route key={path} path={`${path}/registrations/:id`}>
        <ArcoRegistrations setId={setRelationshipId} disabled={subjectSummary?.available < 0} />
      </Route>
      <div className={relationshipId ? 'd-none' : 'table-custom'}>
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <SubjectTotalAndCallToAction
              total={subjectSummary?.total}
              remaining={subjectSummary?.available}
              used={subjectSummary?.used}
              licenseType={customerApplication?.licenseType}
              customerApplication={customerApplication}
              language={lang}
            />

            <div className="d-flex text pe-0 align-items-center justify-content-end">
              <div className="col d-flex justify-content-end">
                {/* <ArcoSubjectModal
                  disabled={disableNewSubjectButtons || expired || subjectSummary?.available < 0}
                  subject={subject}
                  locations={locations}
                  subjectTypes={subjectTypes}
                  showPopupManageTable={showPopupManageTable}
                  getSubjects={getSubjects}
                /> */}
                <button
                  type="button"
                  className="btn btn-primary mb-1"
                  onClick={() => setResetForm(!resetForm)}
                  disabled={expired || subjectSummary?.available < 0}>
                  <i className="thx-plus thx-icon me-2" />
                  {labels.NEW_SUBJECT}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="tabs single">
          <div className="table-custom mt-1">
            <div className="tab-content tab-rel">
              <ArcoSubjectList
                disabled={expired || subjectSummary?.available < 0}
                resetForm={resetForm}
                subjects={subjects}
                checkedItems={checkedItems}
                locations={locations}
                subjectTypes={subjectTypes}
                checkSubject={checkSubject}
                actionsComponent={actionsComponent()}
                showPopupManageTable={showPopupManageTable}
                setSubjects={setSubjects}
                getSubjects={getSubjects}
              />
            </div>
          </div>
        </div>

        {showTableModal && (
          <ArcoTablesModal
            showTableModal={showTableModal}
            closeTableModal={closeTableModal}
            subject={subject}
            subjectTypes={subjectTypes}
            disabled={expired || subjectSummary?.available < 0}
          />
        )}
        {showImportModal && (
          <ArcoSubjectImportDocumentModal
            getSubjects={getSubjects}
            show={showImportModal}
            onHide={() => setShowImportModal(false)}
          />
        )}
      </div>
    </>
  )
}
