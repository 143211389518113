import { useState } from 'react'
import { useSelector } from 'react-redux'
import { saveSid, handleChangeCerts } from '../../SidCertsHandler'
import { cloneDeep } from 'lodash'
import * as Constants from '../../../../config/Constants'
import * as Utility from '../../Utility'
import * as actions from '../../../../actions/index'
import getText from '../../../shared/i18n/labels'

// MODAL
import ModalPasswordBits from './ModalPasswordBits'
import { ModalPecParameters } from './ModalPecParameters'
import { Col, Modal } from 'react-bootstrap'

// FORM
import { BwmInput } from '../BwmInputB5'

// COMPONENTS
import { StarTooltip } from '../../tooltips/Tooltip'
import { PageSpinner } from '../../spinner/PageSpinner'
import { LicenseExpiredPopup } from '../../auth/license/LicenseExpiredPopup'
import { PopupError } from '../../popups/PopupError'
import { PopupSuccess } from '../../popups/PopupSuccess'
import { PopupConfirm, titleColors } from '../../popups/PopupConfirm'

function SidForm(props) {
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [isPecModalOpen, setPecModalOpen] = useState(false)
  const [openModalPasswordBits, setOpen] = useState(false)
  const [loading, setLoading] = useState('')
  const [certificateDto, setCertificateDto] = useState()
  const [signatureCertificateDto, setSignatureCertificateDto] = useState()
  const [type, setType] = useState('password')

  const nameApplication = props.nameApplication

  /**
   * Questa funzione serve per capire se attivare il pulsante VERIFICA.
   */
  //condizioni per abilitare il pulsante:
  //- i certificati devono essere stati caricati
  //- il codice fiscale, numero sid e password devono essere valorizzati
  //- la licenza deve essere valida
  const shouldEnableVerifyButton =
    Utility.isNotEmpty(props.coraSubjectSid?.sidNumber) &&
    Utility.isNotEmpty(props.coraSubjectSid?.password) &&
    Utility.isNotEmpty(props.fiscalCode) &&
    !props.disabled &&
    props.coraSubjectSid?.certificate1 &&
    props.coraSubjectSid?.certificate1.includes('UTEC.P12') &&
    props.coraSubjectSid?.certificate2 &&
    props.coraSubjectSid?.certificate2.includes('UTEF.P12')

  const shouldEnableLoadButton =
    Utility.isNotEmpty(props.coraSubjectSid?.sidNumber) &&
    Utility.isNotEmpty(props.coraSubjectSid?.password) &&
    Utility.isNotEmpty(props.fiscalCode)

  props.setIsCertSidOpened(!!props.coraSubjectSid?.sidNumber)

  const onClickIconPsw = () => {
    let x = document.getElementById(`password${props.id || 0}`)
    if (x !== null) {
      if (x.type === 'password') {
        setType('text')
        x.type = 'text'
      } else {
        setType('password')
        x.type = 'password'
      }
    }
  }

  /**
   * verifica, i certificati caricati contro la password fornita nel form
   * se il SID non è ancora stato salvato, viene passato anche il file  UTEC.P12 per la verifica
   * se il SID è già stato salvato, lo aggiorna con i nuovi dati(bits, date di scadenza/emissione)
   */
  const verifySidCertificate = async () => {
    const id = props.coraSubjectSid?.id
    const password = props.coraSubjectSid?.password
    const files = props.certificateFiles
    const response = await props.checkSid(props.coraSubjectSid.sidNumber)
    if (!response) return
    if (Utility.isNotEmpty(id)) {
      actions
        .postSendFileToVerifySidCertificateById(nameApplication, id, password, props.fiscalCode)
        .then(
          (answer) => onCorrectRequest(answer),
          (err) => errRequest(err)
        )
    } else if (files.length > 0 && files.length < 4) {
      actions
        .postSendFileToVerifySidCertificate(nameApplication, files, password, props.fiscalCode)
        .then(
          (answer) => {
            onCorrectRequest(answer)
          },
          (err) => errRequest(err)
        )
    }
  }

  /**
   * al caricamento, viene effettuata automaticamente una verifica.
   * Il savataggio dei file sarà subordinata alla verifica con esito positivo
   * @param {*} e evento
   */
  const onChangeCertificateFiles = async (e) => {
    const password = props.coraSubjectSid?.password
    const files = cloneDeep(Array.from(e.target.files))
    handleCertificateFilesCustom(files)
    e.target.value = ''
    const response = await props.checkSid(props.coraSubjectSid.sidNumber)
    if (!response) return
    actions
      .postSendFileToVerifySidCertificate(nameApplication, files, password, props.fiscalCode)
      .then(
        (answer) => {
          //se il corasubjectSid ha un id, verifico se il certificato caricato sia scaduto
          //se sì, lo carico nello storico
          //se no, lo salvo
          const checkCertificate = !(
            Utility.isEmpty(props.coraSubjectSid.certificate1) ||
            props.coraSubjectSid.certificate1 === 'UTEC.P12'
          )
          const certificateDto = answer[0]
          const isNotValid = new Date(certificateDto.endDate) < new Date()
          if (props.coraSubjectSid.id && isNotValid && checkCertificate) {
            actions
              .loadExpiredCertificate(
                props.coraSubjectSid.sidNumber,
                nameApplication,
                password,
                files,
                certificateDto
              )
              .then(
                (res) => {
                  //è necessario resettare i file e la password
                  if (res.id) {
                    props.setCertificateFiles([])
                    props.handleChangeSid(res.password, 'errorPassword', labels.REQUIRED_FIELD)
                  }
                  PopupConfirm({
                    title: labels.CERTIFICATES_UPLOADED_SUCCESSFULLY,
                    titleColor: titleColors.SUCCESS,
                    showCancelButton: false,
                    component: () => (
                      <div>
                        {labels.CERTIFICATES_JUST_UPLOADED_HAVE_EXPIRED} (
                        {Utility.formatDateForDisplay(certificateDto.endDate)})<br />
                        {labels.CERTIFICATES_JUST_UPLOADED_HAVE_EXPIRED_TEXT}
                        <br />
                        {labels.CERTIFICATES_JUST_UPLOADED_HAVE_EXPIRED_TEXT_2}
                        <br />
                      </div>
                    )
                  })
                },
                (err) => PopupError({ text: err.title ? err.title : labels.GENERIC_ERROR })
              )
          } else {
            const _coraSubjectSid = onCorrectRequest(answer)
            handleChangeCerts(
              files,
              (value) => props.setErrorCertificates(value),
              (value) => props.setCoraSubjectSid(value),
              _coraSubjectSid,
              props.subject?.coraSubjectSid,
              props.defaultEmail,
              () => {},
              labels
            )
          }
        },
        (err) => errRequest(err)
      )
  }

  const onClickExportCertificateFiles = async () => {
    const id = props.coraSubjectSid?.id
    if (!id) {
      return
    }
    const response = await props.checkSid(props.coraSubjectSid.sidNumber)
    if (!response) return
    actions
      .exportCertificateFiles(id)
      .then(
        (res) => {
          if (res) {
            Utility.downloadFile(res.data, 'certificati.zip')
            Utility.notifySuccess(labels.CERTIFICATES_DOWNLOADED)
          }
        },
        (err) => PopupError({ text: labels.GENERIC_ERROR })
      )
      .then(() => setLoading(false))
  }

  /**
   * Questa funzione aggiorna il sid con i dati appena ottenuti dalla verifica dei certificati
   * @param {array} answer la risposta data dalla chiamata rest
   */
  const onCorrectRequest = (answer) => {
    let _coraSubjectSid = {}
    answer.forEach((element) => {
      if (element.certificateType === 'CERTIFICATO') {
        setCertificateDto(element)
        _coraSubjectSid = {
          ...props.coraSubjectSid,
          encryptionBits: element.econdingBits || null,
          endDate: element.endDate ? new Date(element.endDate) : null,
          expirationDate: element.endDate ? new Date(element.endDate) : null,
          startDate: element.startDate ? new Date(element.startDate) : null
        }
        props.handleChangeSidValues(_coraSubjectSid)
      } else if (element.certificateType === 'FIRMA') {
        setSignatureCertificateDto(element)
      }
    })
    setOpen(true)
    return _coraSubjectSid
  }

  /**
   * Questa funzione deve ritornare un popup e nel farlo deve capire che errore dare
   * Essendo che non c'è la certezza che title abbia un valore si fa questo controllo e
   * e si mette un valore piu generico casomai non c'è niente di piu descrittivo dal
   * backend
   * @param {*} err L'errore dato nel fare la chiamata REST
   */
  const errRequest = (err) => {
    if (err?.title) {
      if (err.errorKey === 'InvalidLicense') {
        LicenseExpiredPopup()
      } else {
        PopupError({ text: err.title })
      }
    } else {
      PopupError({ text: labels.GENERIC_ERROR })
    }
  }

  const datesText =
    props.coraSubjectSid?.startDate &&
    props.coraSubjectSid?.expirationDate &&
    `${new Date(props.coraSubjectSid?.startDate).toLocaleDateString()} - ${new Date(props.coraSubjectSid?.expirationDate).toLocaleDateString()}`

  const hideModal = () => {
    if (props.coraSubjectSid.id) {
      saveSid(
        undefined,
        props.coraSubjectSid,
        props.coraSubjectSid.fiscalCode,
        props.coraSubjectSid.application,
        () => props.reloadList(),
        () => PopupError(),
        () => setOpen(false)
      )
    } else {
      setOpen(false)
    }
  }

  const closePecModal = () => {
    setPecModalOpen(false)
  }

  const onSaveMailParameters = (subjectSid) => {
    props.handleChangeSidValues(subjectSid)

    if (Utility.isNotEmpty(subjectSid?.id)) {
      saveSid(
        undefined,
        subjectSid,
        subjectSid.fiscalCode,
        subjectSid.application,
        () => {
          closePecModal()
        },
        (errors) => PopupError({ text: errors.detail ? errors.detail : labels.GENERIC_ERROR })
      )
    } else {
      closePecModal()
    }
  }

  const handleCertificateFilesCustom = (val) => {
    /*to manage the case in which the field 'certificateFiles' is not yet completed
           (if ConfigurationSID is not opened)*/
    props.setIsCertSidOpened(true)
    if (Utility.isNotEmpty(val)) {
      props.setCertificateFiles(val)
    }
  }

  const onClickOpenDialogAutomaticPecManagment = async () => {
    const response = await props.checkSid(props.coraSubjectSid.sidNumber)
    if (!response) return
    if (Utility.isEmpty(props.coraSubjectSid?.id)) {
      PopupConfirm({
        titleColor: titleColors.ALERT,
        text: labels.SET_PEC_PARAMETERS_DESCRIPTION_2,
        showCancelButton: false
      })
    } else {
      setPecModalOpen(true)
    }
  }

  return (
    <div className="">
      {loading && <PageSpinner />}
      <div className="d-flex  mb-2">
        <div>
          <StarTooltip
            text={
              window.innerWidth <= Constants.BREAKPOINT_LG && labels.SET_UP_AUTOMATIC_PEC_MANAGEMENT
            }>
            <button
              disabled={props.disabled}
              className={`btn btn-primary`}
              onClick={() => onClickOpenDialogAutomaticPecManagment()}>
              {window.innerWidth > Constants.BREAKPOINT_LG ? (
                labels.SET_UP_AUTOMATIC_PEC_MANAGEMENT
              ) : (
                <i className="thx-settings thx-icon" />
              )}
            </button>
          </StarTooltip>
        </div>
        <div className="col d-flex justify-content-end align-self-center">
          <span className="image-upload">
            <label htmlFor={`certificateFiles${props.id || 0}`}>
              <StarTooltip
                text={!shouldEnableLoadButton && labels.SET_PEC_PARAMETERS_DESCRIPTION_3}>
                <div
                  disabled={props.disabled}
                  className={`btn btn-outline-primary text-small ${!shouldEnableLoadButton || props.disabled ? 'disabled' : ''}`}>
                  <i className="thx-upload thx-icon me-2" />
                  {labels.LOAD}
                </div>
              </StarTooltip>
            </label>
            <input
              id={`certificateFiles${props.id}`}
              type="file"
              name={`certificateFiles${props.id}`}
              multiple
              accept=".p12,.ks"
              disabled={Utility.isEmpty(props.coraSubjectSid?.password) || props.disabled}
              onChange={(e) => onChangeCertificateFiles(e)}
            />
          </span>

          <StarTooltip text={!shouldEnableVerifyButton && labels.SET_PEC_PARAMETERS_DESCRIPTION_4}>
            <button
              title={labels.VERIFY_CERTIFICATES}
              disabled={!shouldEnableVerifyButton || props.disabled}
              className="btn btn-outline-primary me-2 ms-2 text-small"
              onClick={() => verifySidCertificate()}>
              <i className="thx-check thx-icon me-2" />
              {labels.VERIFY}
            </button>
          </StarTooltip>
          <button
            className="btn btn-outline-primary text-small"
            title={labels.EXPORT_CERTIFICATES}
            disabled={!(props.coraSubjectSid?.id && shouldEnableVerifyButton) || props.disabled}
            onClick={() => onClickExportCertificateFiles()}>
            <i className="thx-export thx-icon me-2 mt-1" />
            {labels.EXPORT}
          </button>
        </div>
      </div>
      {props.errors?.errorCertificates && !props.errors.errorCertificates.isValid && (
        <div className="row align-items-center justify-content-end text-danger me-2">
          <strong>{props.errors.errorCertificates.msg}</strong>
        </div>
      )}
      <div className="row mt-2 align-items-center">
        <div className={`${props.sizes.sidNumber} d-flex pe-2 mb-2`}>
          <BwmInput
            disabled={props.disabled}
            className={'form-control'}
            maxLength={8}
            label={`${labels.SID_NUMBER}`}
            autoComplete="off"
            name="sidNumber"
            value={props.coraSubjectSid?.sidNumber}
            error={props.errors?.errorSidNumber?.msg}
            touched={props.errors?.errorSidNumber?.isValid === false}
            onChange={(e) => props.handleChangeSid(e, 'errorSidNumber', labels.REQUIRED_FIELD)}
          />
        </div>
        <div className={`${props.sizes.password} pe-2 mb-2`}>
          <div className="d-flex">
            <BwmInput
              disabled={props.disabled}
              id={`password${props.id || 0}`}
              type={'password'}
              autoComplete="off"
              className={'form-control'}
              label={
                window > Constants.BREAKPOINT_MD
                  ? `${labels.PASSWORD_PROTECTION}`
                  : `${labels.PASSWORD}`
              }
              placeholder={labels.MAX_20_CHARACTERS}
              maxLength={20}
              name="password"
              value={props.coraSubjectSid?.password}
              error={props.errors?.errorPassword?.msg}
              touched={props.errors?.errorPassword?.isValid === false}
              onChange={(e) => props.handleChangeSid(e, 'errorPassword', labels.REQUIRED_FIELD)}
              Icon={() => (
                <div
                  className={`d-flex align-self-center ${props.errors?.errorPassword?.isValid === false ? 'me-4' : ''}`}
                  onClick={(e) => onClickIconPsw(e, 'loginPsw')}>
                  <i
                    className={`thx-icon bg-primary ${type === 'text' ? 'thx-eye' : 'thx-eye-off'}`}
                  />
                </div>
              )}
            />
          </div>
        </div>
        <div className={`${props.sizes.expirationDate} mb-2`}>
          <BwmInput
            disabled
            label={`${labels.VALIDITY_CERTIFICATES}`}
            className={'form-control'}
            name="expirationDate"
            value={datesText}
            dataTip={datesText}
          />
        </div>
        {openModalPasswordBits && (
          <Modal
            show={openModalPasswordBits}
            centered
            aria-labelledby="contained-modal-title-vcenter"
            onHide={hideModal}
            size="lg">
            <div className="bar"></div>
            <Modal.Header closeButton>
              <Modal.Title>{labels.VERIFY_CERTIFICATES}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ paddingLeft: '27px', paddingTop: '70px' }}>
              <div className="d-flex">
                <Col>
                  <ModalPasswordBits
                    certificateDto={certificateDto}
                    fiscalCode={props.fiscalCode}
                  />
                </Col>

                <Col>
                  <ModalPasswordBits
                    certificateDto={signatureCertificateDto}
                    fiscalCode={props.fiscalCode}
                  />
                </Col>
              </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
              <button className="btn btn-primary " onClick={hideModal}>
                {labels.CLOSE_AND_SAVE}
              </button>
            </Modal.Footer>
          </Modal>
        )}
        <ModalPecParameters
          coraSubjectSid={props.coraSubjectSid}
          show={isPecModalOpen}
          onClose={closePecModal}
          afterSave={(subjectSid) => onSaveMailParameters(subjectSid)}
        />
      </div>
    </div>
  )
}

export default SidForm
