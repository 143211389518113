import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isNotEmpty } from '../../../shared/Utility'
import { endOfDay, isBefore, startOfDay } from 'date-fns'
import * as Utility from '../../../shared/Utility'
import * as Constants from '../../../../config/Constants'
import * as actions from '../../../../actions'
import getText from '../../../shared/i18n/labels'

import { registerLocale } from 'react-datepicker'
import it from 'date-fns/locale/it'
import en from 'date-fns/locale/en-US'

// FORM
import BalanceForm from './BalanceForm'
import { DelegateForm } from './DelegateForm'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import { DateField } from '../../../shared/form/DateFieldB5'
import { ProvinceField } from '../../../shared/form/ProvinceFieldB5'
import { BwmCheckbox } from '../../../shared/form/BwmCheckboxB5'
import { BwmSelect } from '../../../shared/form/BwmSelectB5'
import { BwmInput } from '../../../shared/form/BwmInputB5'
import { AutoCompileCustom } from '../../../shared/form/AutoCompileCustomB5'
import ErrorListAlert from '../../../shared/form/ErrorListAlert'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

// COMPONENTS
import { Tabs } from '../../../shared/Tabs'

import '../../../../styles/autocomplete.css'
import 'react-datepicker/dist/react-datepicker.css'

import CodiceFiscale from 'codice-fiscale-js'
import { RankedDropdownList } from '../../../shared/form/RankedDropdownList'
import { PopupError } from '../../../shared/popups/PopupError'
import { PopupConfirm, titleColors } from '../../../shared/popups/PopupConfirm'

/* eslint-disable */
const defaultSize = (size = null) => ({
  relationshipType: Utility.getSize(size, { default: 'w-35', lg: 'w-18', md: 'w-5', additionalClass: 'flex-grow-1' }),
  startDate:  Constants.dateSize(size, 13),
  endDate: Constants.dateSize(size, 13),
  cab: Constants.cabSize(size),
  coraCommunicationType: Utility.getSize(size, { additionalClass: 'min-w-42ch' }),
  includedInNextCommunication: Utility.getSize(size, { default: '10', md: 'w-8' }),
  identifier: Utility.getSize(size, { default: 'w-18', additionalClass: 'flex-grow-1' }),
  'tab-rel': {
    leftSide: Utility.getSize(size, { default: 'w-55' }),
    personType: Utility.getSize(size, { default: 'w-100' }),
    legalPersonType: Utility.getSize(size, { default: 'w-50', lg: 'w-65' }),
    naturalPersonType: Utility.getSize(size, { default: 'w-50', lg: 'w-65' }),
    fiscalCode: Constants.fiscalCodeSize(size),
    companyName: Utility.getSize(size, { default: 'w-60', additionalClass: 'flex-grow-1' }),
    location: Utility.getSize(size, { default: 'w-50' }),
    province: Constants.provinceSize(size),
    lastName: Utility.getSize(size, { default: 'w-40', additionalClass: 'flex-grow-1' }),
    firstName: Utility.getSize(size, { default: 'w-40', additionalClass: 'flex-grow-1' }),
    gender: Constants.genderSize(size),
    birthDate: Constants.dateSize(size),
    description: Utility.getSize(size, { default: 'w-100' }),
    note: Utility.getSize(size, { default: 'w-100' }),
  },
  'tab-tit': {
    checkbox: Utility.getSize(size, { default: 'w-5' }),
    role: Utility.getSize(size, { default: 'w-20' }),
    completeName: Utility.getSize(size, { default: 'w-40', md: 'w-32' }),
    fiscalCode: Utility.getSize(size, { default: 'w-18', md: 'w-26' }),
    birthDate: Utility.getSize(size, { default: 'w-20' }),
    location: Utility.getSize(size, { default: 'w-24' }),
    province: Utility.getSize(size, { default: 'w-10' }),
    startDate: Utility.getSize(size, { default: 'w-14' }),
    endDate: Utility.getSize(size, { default: 'w-14' })
  },
  'tab-saldi': {
    checkbox: Utility.getSize(size, { default: 'w-5' }),
    year: Utility.getSize(size, { default: 'w-8' }),
    amount1: Utility.getSize(size, { default: 'w-8' }),
    amount2: Utility.getSize(size, { default: 'w-8' }),
    amount3: Utility.getSize(size, { default: 'w-8' }),
    amount4: Utility.getSize(size, { default: 'w-8' }),
    amount5: Utility.getSize(size, { default: 'w-14' }),
    stock: Utility.getSize(size, { default: 'w-8' }),
    currency: Utility.getSize(size, { default: 'w-8' }),
    nature: Utility.getSize(size, { default: 'w-22' }),
    result: Utility.getSize(size, { default: 'w-8' })
  }
})
/* eslint-enable */

const getSize = () => {
  if (window.innerWidth <= Constants.BREAKPOINT_MD) return defaultSize('md')
  if (window.innerWidth <= Constants.BREAKPOINT_LG) return defaultSize('lg')
  return defaultSize()
}

export const RelationshipForm = (props) => {
  const [initialValues, setInitialValues] = useState({})
  const [showErrorAlert, setShowErrorAlert] = useState(false)
  const [loading, setLoading] = useState(false)

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  registerLocale(lang, lang === 'it' ? it : en)

  const [activeTab, setActiveTab] = useState('tab-rel')

  const relationshipTypes = useSelector((state) => state.relationshipTypes.data)
  const rankedrelationshipTypes = useSelector((state) => state.rankedRelationshipTypes.data)

  const [touched, setTouched] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (props.setLoading) props.setLoading(loading)
  }, [loading])

  const setNewInitialValues = (data = {}) => {
    setTouched(false)
    if (data?.relationshipType?.code === '98') {
      data.endDate = null
    }
    data.relationshipType =
      data.relationshipType || rankedrelationshipTypes[0] || relationshipTypes[0]

    setInitialValues({
      id: data.id,
      startDate: data.startDate ? new Date(data.startDate) : null,
      endDate: data.endDate ? new Date(data.endDate) : null,
      relationshipType: {
        id: data.relationshipType?.id,
        code: data.relationshipType?.code,
        description: data.relationshipType?.description
      },
      coraSubject: { id: props.subjectId },
      coraRole: { id: '1' },
      cab: Constants.codRelTypeCab.indexOf(data?.relationshipType?.code) !== -1 ? data.cab : '',
      description: data?.relationshipType?.code === '99' ? data.description : '',
      personType: data?.personType || Constants.PNF,
      fiscalCode: data.fiscalCode || '',
      companyName: data.companyName || '',
      location: data.location || '',
      province: data.province || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      gender: data.gender || 'FEMALE',
      birthDate: data.birthDate ? new Date(data.birthDate) : null,
      comment: data.comment || '',
      identifier: data.identifier || '',
      includedInNextCommunication: data.includedInNextCommunication !== false,
      coraCommunicationType: {
        id: data.coraCommunicationType?.id || '1'
      },
      codError: data.codError,
      idResult: data.idResult || null,
      lastCommunicationError: data.lastCommunicationError,
      createDate: data.createDate,
      modifyDate: data.modifyDate,
      coraRelationDelegates: data.coraRelationDelegates || [],
      canceledAndCommunicated: data.canceledAndCommunicated,
      showDescription: data.relationshipType?.code === '99',
      showCab: Constants.codRelTypeCab.indexOf(data.relationshipType?.code) !== -1,
      endDateDisabled: data?.relationshipType?.code === '98',
      errorFiscalCode: false,
      errorStartDate: checkDelegates(data.coraRelationDelegates || [], data.startDate)
    })
  }

  const retrieveSubject = () => {
    setActiveTab('tab-rel')
    setLoading(true)
    if (props.subjectId && props.id) {
      actions.getCoraRelationshipBySubjectIdAndId(props.subjectId, props.id).then((data) => {
        actions.getDelegatesByRelationshipId(props.subjectId, props.id).then((delegates) => {
          setNewInitialValues({ ...data, coraRelationDelegates: delegates })
        })
        setLoading(false)
      })
    } else {
      setNewInitialValues()
      setLoading(false)
    }
  }

  useEffect(() => {
    retrieveSubject()
  }, [props.id])

  const sameMonthYear = (startDate, endDate) => {
    return (
      startDate.getMonth() === endDate.getMonth() &&
      startDate.getFullYear() === endDate.getFullYear()
    )
  }

  const canCloseReport = (endDateDisabled, commType) => {
    return !(endDateDisabled && commType === 2)
  }

  const checkDelegates = (coraRelationDelegates, val) => {
    let errorStartDate = false
    if (coraRelationDelegates?.length > 0) {
      coraRelationDelegates.forEach((delegate) => {
        if (delegate.startDate && isBefore(new Date(delegate.startDate), startOfDay(val))) {
          errorStartDate = true
        }
      })
    }
    return errorStartDate
  }

  const validationSchema = Yup.object().shape({
    startDate: Yup.date(labels.INVALID_DATE)
      .required(labels.REQUIRED_FIELD)
      .test(
        'is-valid-date',
        labels.INVALID_DATE,
        (value) => !value || Utility.isValidDate(new Date(value))
      ),
    endDate: Yup.date(labels.INVALID_DATE)
      .nullable()
      .min(Yup.ref('startDate'), labels.END_DATE_NOT_BEFORE_START_DATE)
      .test('same-month-year', labels.INVALID_END_DATE_FOR_NEW_RELATIONSHIP, function (value) {
        const { startDate, coraCommunicationType } = this.parent
        if (!startDate) return false
        if (coraCommunicationType?.id === '1' && value) {
          return sameMonthYear(new Date(startDate), new Date(value))
        }
        return true
      })
      .test('can-close', labels.RELATIONSHIP_CLOSED_NOT_ALLOWED_FOR_TYPE_98, function (value) {
        const { endDateDisabled, coraCommunicationType } = this.parent
        return canCloseReport(endDateDisabled, coraCommunicationType?.id)
      })
      .test(
        'is-valid-date',
        labels.INVALID_DATE,
        (value) => !value || Utility.isValidDate(new Date(value))
      ),
    cab: Yup.string().when('showCab', (data, schema) => {
      if (data[0]) {
        return schema.required(labels.REQUIRED_FIELD)
      }
      return
    }),
    description: Yup.string().when('showDescription', (data, schema) => {
      if (data[0]) {
        return schema.required(labels.REQUIRED_FIELD)
      }
      return
    }),
    personType: Yup.string().required(labels.SELECT_PERSON_TYPE),
    companyName: Yup.string().when('personType', {
      is: (val) => val === Constants.PNF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    firstName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    lastName: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    gender: Yup.string().when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) => schema.required(labels.REQUIRED_FIELD),
      otherwise: (schema) => schema.notRequired()
    }),
    birthDate: Yup.date(labels.INVALID_DATE).when('personType', {
      is: (val) => val === Constants.PF,
      then: (schema) =>
        schema
          .required(labels.REQUIRED_FIELD)
          .test(
            'is-valid-date',
            labels.INVALID_DATE,
            (value) => !value || Utility.isValidDate(new Date(value))
          ),
      otherwise: (schema) => schema.notRequired()
    }),
    fiscalCode: Yup.string().nullable(),
    location: Yup.string().required(labels.REQUIRED_FIELD),
    province: Yup.string().required(labels.REQUIRED_FIELD),
    relationshipType: Yup.object().shape({
      id: Yup.string().required(labels.REQUIRED_FIELD),
      code: Yup.string(),
      description: Yup.string()
    }),
    coraCommunicationType: Yup.object().shape({
      id: Yup.string().required(labels.REQUIRED_FIELD)
    }),
    errorFiscalCode: Yup.boolean().test(
      'errorFiscalCode',
      labels.INVALID_FISCAL_CODE,
      (value) => !value
    ),
    errorStartDate: Yup.boolean().test(
      'errorStartDate',
      labels.CORA_RELATIONSHIP_START_DATE_ERROR,
      (value) => !value
    )
  })

  const formErrorsTabMapping = [
    {
      errorKey: 'fiscalCode',
      errorLabel: labels.FISCAL_CODE
    },
    {
      errorKey: 'firstName',
      errorLabel: labels.FIRST_NAME
    },
    {
      errorKey: 'lastName',
      errorLabel: labels.LAST_NAME
    },
    {
      errorKey: 'companyName',
      errorLabel: labels.COMPANY_NAME
    },
    {
      errorKey: 'location',
      errorLabel: labels.LOCATION
    },
    {
      errorKey: 'province',
      errorLabel: labels.PROVINCE
    },
    {
      errorKey: 'gender',
      errorLabel: labels.GENDER
    },
    {
      errorKey: 'birthDate',
      errorLabel: labels.BIRTH_DATE
    },
    {
      errorKey: 'coraCommunicationType.id',
      errorLabel: labels.COMMUNICATION_TYPE
    },
    {
      errorKey: 'cab',
      errorLabel: labels.CAB
    },
    {
      errorKey: 'startDate',
      errorLabel: labels.START_DATE
    },
    {
      errorKey: 'errorStartDate',
      errorLabel: labels.START_DATE
    },
    {
      errorKey: 'endDate',
      errorLabel: labels.END_DATE
    },
    {
      errorKey: 'description',
      errorLabel: labels.DESCRIPTION
    }
  ]

  const dispatch = useDispatch()

  useEffect(() => {
    setIsMounted(true)
    if (!relationshipTypes?.length) {
      dispatch(actions.getRelationshipTypes())
    }
    dispatch(actions.getRankedRelationshipTypes(props.subjectId))

    return () => {
      setIsMounted(false)
    }
  }, [props.subjectId])

  // i rapporti cancellati e comunicati in precedenza devono tornare visibili sulla lista principale in caso di modifica
  useEffect(() => {
    if (initialValues.id) {
      setInitialValues({
        ...initialValues,
        personType: initialValues.personType || Constants.PNF,
        canceledAndCommunicated: false
      })
    }
  }, [
    initialValues.startDate,
    initialValues.endDate,
    initialValues.cab,
    initialValues.description,
    initialValues.personType,
    initialValues.fiscalCode,
    initialValues.companyName,
    initialValues.location,
    initialValues.lastName,
    initialValues.firstName,
    initialValues.gender,
    initialValues.birthDate,
    initialValues.comment,
    initialValues.includedInNextCommunication,
    initialValues.identifier,
    initialValues.relationshipType,
    initialValues.coraCommunicationType
  ])

  const getErrors = (errors, personType) => {
    if (personType === Constants.PF) formErrorsTabMapping[4].errorLabel = labels.BIRTH_PLACE
    else formErrorsTabMapping[4].errorLabel = labels.LEGAL_SEAT
    return Utility.extractErrors(errors, formErrorsTabMapping)
  }

  return (
    isMounted && (
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {({ values, errors }) => (
          <Form className={props.formOnBottom ? 'p-2 form-on-bottom border-top' : ''}>
            {/* Mostra l'errore in un overlay che rimane fisso in cima solo se showErrorAlert è true */}
            {showErrorAlert && errors && touched && (
              <div className={props.formOnBottom ? 'cora-relationship-form-on-bottom-error' : ''}>
                <ErrorListAlert
                  errors={getErrors(errors, values.personType)}
                  hide={() => setShowErrorAlert(false)}
                />
              </div>
            )}
            <FormBody
              {...props}
              loading={loading}
              initialValues={initialValues}
              touched={touched}
              activeTab={activeTab}
              relationshipTypes={relationshipTypes}
              rankedrelationshipTypes={rankedrelationshipTypes}
              setTouched={setTouched}
              setLoading={setLoading}
              checkDelegates={checkDelegates}
              setNewInitialValues={setNewInitialValues}
              setActiveTab={setActiveTab}
              setShowErrorAlert={setShowErrorAlert}
              retrieveSubject={retrieveSubject}
            />
          </Form>
        )}
      </Formik>
    )
  )
}

const FormBody = (props) => {
  const { values, errors, setValues, validateForm } = useFormikContext()

  const [sizesClass, setSizesClass] = useState(getSize())

  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const FIRST_TAB = labels.RELATIONSHIP
  const SECOND_TAB = labels.TITLE_AND_DELEGATE
  const THIRD_TAB = labels.ANNUAL_BALANCES

  const [disableIdentifierInput, setDisableIdentifierInput] = useState(true)
  const delayedCommunicationDate = useSelector((state) => state.delayedCommunicationDate)

  useEffect(() => {
    setDisableIdentifierInput(true)
    setValues(props.initialValues)
  }, [props.initialValues])

  useEffect(() => {
    setSizesClass(getSize())
  }, [window.innerWidth])

  const handlePersonTypeClick = (val) => {
    if (props.disabled) return
    const form = Object.assign({}, values)
    form.personType = val
    setValues(form)
  }

  const handleInputAutocompile = (location) => {
    const form = Object.assign({}, values)
    if (!location || location === '') {
      form.location = location
      setValues(form)
    }
    if (form.province?.toUpperCase() === 'EE') {
      form.location = location?.toUpperCase() || ''
      form.province = form.province?.toUpperCase() || ''
      return
    }
    actions.getLocation(location?.toUpperCase()).then(
      (loc) => {
        if (loc) {
          form.province = loc.province
          form.location = location?.toUpperCase() || ''
          setValues(form)
        }
      },
      (errors) => {
        console.log(errors)
      }
    )
    form.location = location?.toUpperCase() || ''
    setValues(form)
  }

  const handlerChangeRelationshipType = (val) => {
    const form = Object.assign({}, values)
    const relationshipType = props.relationshipTypes.find(
      (el) => el.id?.toString() === val?.toString()
    )
    const cod = relationshipType?.code
    form.relationshipType = relationshipType
    resetTypeDependentFields(setValues, values)

    form.endDateDisabled = false
    form.showDescription = false
    form.showCab = false

    if (Constants.codRelTypeCab.indexOf(cod) !== -1) {
      form.showCab = true
    } else if (cod === '98') {
      form.endDate = null
      form.endDateDisabled = true
    } else if (cod === '99') {
      form.showDescription = true
    }
    setValues(form)
  }

  const saveAndSetRelationship = (newValues, onSuccess = () => {}) => {
    const formData = Object.assign(newValues, {})
    delete formData.showCab
    delete formData.errorFiscalCode
    delete formData.showDescription
    delete formData.endDateDisabled
    props.setLoading(true)
    actions.createCoraRelationship(formData).then(
      (res) => {
        if (res?.constructor === Object) props.setNewInitialValues(res)
        Utility.notifySuccess(labels.SAVED_SUCCESSFULLY)
        onSuccess()
        props.getRelationships(props.subjectId)
        close(formData.id)
        props.setLoading(false)
      },
      (errors) => {
        if (errors.errorKey && errors.title) {
          PopupError({ text: errors.title })
        } else {
          PopupError({ text: labels.GENERIC_ERROR })
        }
        props.setLoading(false)
        return false
      }
    )
  }

  const updateRelationship = (formData, onSuccess) => {
    formData.lastCommunicationError = null
    delete formData.endDateDisabled
    delete formData.showDescription
    delete formData.showCab
    delete formData.errorFiscalCode
    delete formData.coraRelationDelegates
    props.setLoading(true)
    actions.updateCoraRelationship(formData).then(
      () => {
        Utility.notifySuccess(labels.SAVED_SUCCESSFULLY)
        onSuccess()
        close(formData.id)
        props.setLoading(false)
      },
      (errors) => {
        if (errors.errorKey && errors.title) {
          PopupError({ text: errors.title })
        } else {
          PopupError({ text: labels.GENERIC_ERROR })
        }
        props.setLoading(false)
      }
    )
  }

  const close = (retrieve = false, id) => {
    props.setTouched(false)
    if (retrieve) props.retrieveSubject()
    if (props.close) props.close(props.subjectId ? null : id)
  }

  const prepareClonedRelationship = () => {
    let cloned = values
    let cleanDelegates = []
    for (let delegate of values.coraRelationDelegates || []) {
      cleanDelegates.push({
        ...delegate,
        id: null
      })
    }

    cloned.id = null
    actions.createCoraRelationship(values).then(
      (res) => {
        if (cleanDelegates.length > 0) {
          let promises = []
          for (let index = 0; index < cleanDelegates.length; index++) {
            const delegate = cleanDelegates[index]
            delegate.coraRelationship = { id: res.id }
            promises.push(actions.createCoraDelegate(delegate))
          }
          Promise.all(promises).then(() => {
            Utility.notifySuccess(labels.RELATIONSHIP_CLONED_SUCCESSFULLY)
            props.getRelationships(props.subjectId)
            close()
            props.onClone(res.id)
          })
        } else {
          props.getRelationships(props.subjectId)
          Utility.notifySuccess(labels.RELATIONSHIP_CLONED_SUCCESSFULLY)
          props.getRelationships(props.subjectId)
          close()
          props.onClone(res.id)
        }
      },
      (err) => {
        PopupError({ text: labels.GENERIC_ERROR })
      }
    )
  }

  const handlerChange = (val, name) => {
    const form = Object.assign({}, values)
    if (name === 'includedInNextCommunication') {
      form.includedInNextCommunication = val
    } else if (name === 'coraCommunicationType') {
      val = val && val !== '' ? parseInt(val.trim()) : null
      form.coraCommunicationType = { id: val }
      form.includedInNextCommunication = true
    }
    setValues(form)
  }

  const handlerChangeEndDate = (val) => {
    const form = Object.assign({}, values)
    form.endDate = val
    setValues(form)
    handleEndDateLogic(val, setValues, values)
  }

  const resetTypeDependentFields = () => {
    const form = Object.assign({}, values)
    form.showCab = false
    form.cab = null
    form.showDescription = false
    form.endDateDisabled = false
    setValues(form)
  }

  const handleEndDateLogic = (endDate) => {
    const form = Object.assign({}, values)
    let today = isNotEmpty(delayedCommunicationDate)
      ? new Date(delayedCommunicationDate)
      : new Date()
    //datemin = inizio mese precedente; datemax:fine mese precedente
    let endDateEndOfDay = endOfDay(endDate) //utilizzo endOfDay di date-fns per prevenire casistiche in cui l'ora di endDate sia successiva all'ra di dateMax
    let dateMin = endOfDay(new Date(today.getFullYear(), today.getMonth() - 1, 1))
    let dateMax = endOfDay(new Date(today.getFullYear(), today.getMonth(), 0))
    // Se la data selezionata è tra datemin e datemax-> tipo comunicazione 5-Chiusura nei termini
    if (endDateEndOfDay >= dateMin && endDateEndOfDay <= dateMax) {
      form.coraCommunicationType = { id: '2' }
      form.includedInNextCommunication = true
      setValues(form)
    } else if (endDateEndOfDay < dateMin) {
      //se la data selezionata è precedente al mese precedente -> tipo comunicazione 2=chiusura tardiva
      form.coraCommunicationType = { id: '3' }
      form.includedInNextCommunication = true
    }
    form.endDate = endDate
    setValues(form)
  }

  const testForSimilarCoraRelationship = (form, onConfirm = () => {}, onCancel = () => {}) => {
    return actions.testForSimilarCoraRelationship(form, props.subjectId).then((res) => {
      if (res) {
        PopupConfirm({
          titleColor: titleColors.ALERT,
          title: labels.POTENTIALLY_DUPLICATE_RELATIONSHIP_DETECTED,
          component: () => {
            return (
              <>
                <div className="row">
                  <div className="col text-center">
                    <strong>{labels.SAME_START_DATE_SAME_SUBJECT_SAME_RELATIONSHIP_TYPE}</strong>
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center">
                    {labels.REMEMBER_TO_CLOSE_OR_UPDATE_RELATIONSHIPS}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center">
                    <strong>{labels.IF_RELATIONSHIP_NOT_DUPLICATED_PRESS_CONFIRM}</strong>
                  </div>
                </div>
              </>
            )
          },
          confirm: labels.CLOSE_WITHOUT_SAVING,
          cancel: labels.CONFIRM,
          handleClickConfirm: onConfirm,
          onClickCancel: onCancel
        })
      }
    })
  }

  const handleChangeStartDate = (val) => {
    const form = Object.assign({}, values)
    form.errorStartDate = props.checkDelegates(values.coraRelationDelegates, val)
    form.startDate = val
    setValues(form)
  }

  const handleOnlyFiscalCode = async (val, update = true) => {
    const form = Object.assign({}, values)
    try {
      const baseRegistry = await actions.getBaseRegistryPersonByFiscalCode(val)
      if (isNotEmpty(baseRegistry)) {
        if (baseRegistry.personType === Constants.PNF && update) {
          form.companyName = baseRegistry?.companyName || form.companyName
          form.location = baseRegistry?.location?.location || form.location
          form.province = baseRegistry?.location?.province || form.province
        } else if (baseRegistry?.personType === Constants.PF && update) {
          form.lastName = baseRegistry?.lastName || form.lastName
          form.firstName = baseRegistry?.firstName || form.firstName
          form.birthDate = baseRegistry?.birthDate
            ? new Date(baseRegistry?.birthDate)
            : form.birthDate
          form.gender = baseRegistry?.gender || form.gender
          form.location = baseRegistry?.location?.location || form.location
          form.province = baseRegistry?.location?.province || form.province
        }
        form.errorFiscalCode = false
        setValues(form)
        return false
      }
      form.errorFiscalCode = true
      setValues(form)
      return true
    } catch (e) {
      if (form.personType === Constants.PF) {
        try {
          const cf = new CodiceFiscale(val).toJSON()
          if (cf && update) {
            form.birthDate = cf?.birthday ? new Date(cf?.birthday) : null
            form.gender = cf?.gender === 'M' ? 'MALE' : form.gender
            form.location = cf?.birthplace.toUpperCase() || form.location
            form.province = cf?.birthplaceProvincia || form.province
          }
          form.errorFiscalCode = false
          setValues(form)
          return false
        } catch (e) {
          form.errorFiscalCode = true
          setValues(form)
          return true
        }
      }
    }
  }

  const handleFiscalCode = async (val, update = true) => {
    const form = Object.assign({}, values)
    try {
      await handleOnlyFiscalCode(val, update)
    } finally {
      form.fiscalCode = val
      await testForSimilarCoraRelationship(form, close)
    }
  }

  const onTabChange = async (tab, isChange) => {
    if (isChange) {
      const errors = await validateForm(values)
      if (Object.keys(errors).length > 0) {
        props.setTouched(true)
        props.setShowErrorAlert(true)
        return false
      } else {
        props.setActiveTab(tab)
        props.setShowErrorAlert(false)
        if (!values?.id) {
          saveAndSetRelationship(values)
          return true
        }
        return true
      }
    }
  }

  const handleFormSubmit = async () => {
    const errorFiscalCode = await handleOnlyFiscalCode(values.fiscalCode, false)
    const form = Object.assign({}, values)
    form.errorFiscalCode = errorFiscalCode
    const errors = await validateForm(form) // Validazione dei valori
    setValues(form)
    props.setTouched(true)
    if (Object.keys(errors).length > 0) {
      props.setShowErrorAlert(true) // Mostra l'alert degli errori
    } else {
      props.setShowErrorAlert(false)
      const newValues = Object.assign({}, values)

      newValues.idResult = props.idResult
      if (newValues.personType === Constants.PF) {
        newValues.companyName = null
      } else if (newValues.personType === Constants.PNF) {
        newValues.gender = null
        newValues.firstName = null
        newValues.lastName = null
        newValues.birthDate = null
      }
      if (!newValues.showDescription) newValues.description = null
      if (!newValues.showCab) newValues.cab = null
      if (!newValues?.id) {
        saveAndSetRelationship(newValues, close)
      } else {
        updateRelationship(newValues, close)
      }
    }
  }

  return (
    <div>
      <div className="cora-form-height">
        <input
          defaultValue={values?.identifier || ''}
          className="form-control"
          name="identifier"
          hidden={true}
        />

        <div className="row">
          <div className={`${sizesClass.relationshipType} pe-2`}>
            <RankedDropdownList
              disabled={props.disabled}
              name="relationshipType"
              label={`${labels.RELATIONSHIP_TYPE}*`}
              list={props.relationshipTypes}
              rankedElements={props.rankedrelationshipTypes}
              selectedValue={values.relationshipType?.id}
              error={errors.relationshipType}
              touched={props.touched}
              onChange={(e) => handlerChangeRelationshipType(e.target.value)}
            />
          </div>
          <div className={`${sizesClass.startDate} pe-2`}>
            <DateField
              disabled={props.disabled}
              label={`${labels.START_DATE}*`}
              date={values.startDate}
              error={errors.startDate || errors.errorStartDate}
              touched={props.touched}
              onChange={(date) => handleChangeStartDate(date)}
            />
          </div>
          {!values.endDateDisabled && (
            <div className={`${sizesClass.endDate} pe-2`}>
              <DateField
                disabled={props.disabled || values.endDateDisabled}
                label={`${labels.END_DATE}`}
                date={values.endDate}
                error={errors.endDate}
                touched={props.touched}
                onChange={(date) => handlerChangeEndDate(date)}
              />
            </div>
          )}
          {values.showCab && (
            <div className={`${sizesClass.cab} pe-2`}>
              <BwmInput
                disabled={props.disabled}
                name="cab"
                label="CAB*"
                className="form-control"
                maxLength="5"
                value={values.cab}
                error={errors.cab}
                touched={props.touched}
                onChange={(e) =>
                  Utility.handleChangeValue(e.target.value, 'cab', values, setValues)
                }
              />
            </div>
          )}
          <div className={`${sizesClass.coraCommunicationType} pe-2`}>
            <BwmSelect
              disabled={props.disabled}
              options={props?.communicationTypes || []}
              name="coraCommunicationType"
              label={`${labels.COMMUNICATION_TYPE}*`}
              className="form-control"
              showCode={true}
              value={values.coraCommunicationType?.id}
              error={errors.coraCommunicationType}
              touched={props.touched}
              onChange={(e) => handlerChange(e.target.value, 'coraCommunicationType')}
            />
          </div>
          <div
            className={`${sizesClass.includedInNextCommunication} d-flex align-items-center pt-1 ${window.innerWidth < Constants.BREAKPOINT_LG ? 'ps-0' : 'ps-1'}`}>
            <BwmCheckbox
              disabled={props.disabled}
              label={labels.INCLUDE_IN_NEXT_COMMUNICATION}
              name={`includedInNextCommunication_${values?.id}`}
              className="form-control"
              checked={values.includedInNextCommunication}
              error={errors.includedInNextCommunication}
              touched={props.touched}
              onChange={(e) => handlerChange(e.target.checked, 'includedInNextCommunication')}
            />
          </div>

          {values?.identifier && (
            <div className={`${sizesClass.identifier} ps-2`}>
              <BwmInput
                name="identifier"
                label={`${labels.UNIQUE_CODE}`}
                className="form-control"
                Icon={
                  disableIdentifierInput ? () => <i className="thx-unlock thx-icon" /> : undefined
                }
                iconTip={disableIdentifierInput ? labels.CLICK_TO_ENABLE_CHANGES : ''}
                maxLength="50"
                value={values.identifier}
                error={errors.identifier}
                touched={props.touched}
                disabled={disableIdentifierInput}
                onIconClick={() => disableIdentifierInput && setDisableIdentifierInput(false)}
                onChange={(e) =>
                  Utility.handleChangeValue(
                    e.target.value,
                    'identifier',
                    values,
                    setValues,
                    true,
                    true
                  )
                }
              />
            </div>
          )}
        </div>
        {values.canceledAndCommunicated && (
          <div className="justify-content-center pt-2">
            <div className="col text-danger" style={{ fontSize: '1.1rem' }}>
              <strong>{labels.LAST_COMMUNICATION_CANCELLATION}</strong>
            </div>
          </div>
        )}
        <div className="row mt-2">
          <div className="tab-content relationship border-radius border col-12">
            <Tabs activeTab={props.activeTab} className="pt-0" setActiveTab={onTabChange}>
              <div id="tab-rel" label={FIRST_TAB}>
                <div className={`${sizesClass['tab-rel'].personType} ms-2 my-2 mb-1 d-flex`}>
                  <div className="input-group d-flex justify-content-between pe-3">
                    <div className="d-flex">
                      <div className="inputRadio me-5">
                        <label
                          disabled={props.disabled}
                          className={`form-check-label d-flex align-items-center ${props.disabled ? 'pointer-events-none' : ''}`}
                          htmlFor={`pType1${values?.id}`}
                          onClick={() => handlePersonTypeClick(Constants.PNF)}>
                          <input
                            disabled={props.disabled}
                            className="form-check-input pe-1 me-2 mt-0"
                            style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                            type="radio"
                            checked={values.personType === Constants.PNF}
                            onChange={() => {}}
                          />
                          {labels.LEGAL_PERSON}
                        </label>
                      </div>
                      <div className="inputRadio">
                        <label
                          disabled={props.disabled}
                          className={`form-check-label d-flex align-items-center ${props.disabled ? 'pointer-events-none' : ''}`}
                          htmlFor={`pType2${values?.id}`}
                          onClick={() => handlePersonTypeClick(Constants.PF)}>
                          <input
                            disabled={props.disabled}
                            className="form-check-input pe-1 me-2 mt-0"
                            style={{ width: '1.2em', height: '1.2em', boxShadow: 'none' }}
                            type="radio"
                            checked={values.personType === Constants.PF}
                            onChange={() => {}}
                          />
                          {labels.PHYSICAL_PERSON}
                        </label>
                      </div>
                    </div>
                    <div>
                      {values?.id && (
                        <div className="ms-5">
                          <span>
                            <b>ID</b>: {values?.id}
                          </span>
                          {values?.codError && (
                            <span className={'text-red ms-4'}>
                              <b>{labels.COD_ERROR}</b>: {values?.codError}
                            </span>
                          )}
                          <span className="ms-4">
                            <span className="me-1">
                              <b>{labels.ID_RESULT}</b>:
                            </span>
                            <input
                              className={'input-esit'}
                              value={values.idResult}
                              type={'text'}
                              onChange={(e) =>
                                Utility.handleChangeValue(
                                  e.target.value,
                                  'idResult',
                                  values,
                                  setValues
                                )
                              }
                            />
                          </span>
                          <span className="ms-4">
                            <b>{window.innerWidth > 1100 ? labels.DATE_CREATE : labels.CREATED}</b>:{' '}
                            {values?.createDate
                              ? Utility.formatDateForDisplay(values?.createDate)
                              : '--/--/--'}
                          </span>
                          <span className="ms-4">
                            <b>
                              {window.innerWidth > 1100 ? labels.DATE_LAST_MODIFY : labels.MODIFIED}
                            </b>
                            :{' '}
                            {values.modifyDate
                              ? Utility.formatDateForDisplay(values.modifyDate)
                              : '--/--/--'}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row p-1 pt-1 ps-2">
                  <div
                    id="infoPnf"
                    className={`${sizesClass['tab-rel'].leftSide} ${values.personType === Constants.PNF ? '' : ' d-none'}`}>
                    <div className="row">
                      <div className={`${sizesClass['tab-rel'].fiscalCode} pe-2 mb-2`}>
                        <BwmInput
                          disabled={props.disabled}
                          name="fiscalCode"
                          label={`${labels.FISCAL_CODE}`}
                          className="form-control"
                          maxLength="16"
                          value={values.fiscalCode}
                          error={errors.fiscalCode || errors.errorFiscalCode}
                          touched={props.touched}
                          onBlur={(e) => handleFiscalCode(e.target.value)}
                          onChange={(e) =>
                            Utility.handleChangeValue(
                              e.target.value,
                              'fiscalCode',
                              values,
                              setValues,
                              true,
                              true
                            )
                          }
                        />
                      </div>
                      <div className={`${sizesClass['tab-rel'].companyName} mb-2 pe-2`}>
                        <BwmInput
                          disabled={props.disabled}
                          name="companyName"
                          label={`${labels.COMPANY_NAME}*`}
                          className="form-control font-weight-bold font-size-big"
                          maxLength="60"
                          value={values.companyName}
                          error={errors.companyName}
                          touched={props.touched}
                          onChange={(e) =>
                            Utility.handleChangeValue(
                              e.target.value,
                              'companyName',
                              values,
                              setValues,
                              true
                            )
                          }
                        />
                      </div>
                      <div className={`${sizesClass['tab-rel'].location} pe-2`}>
                        <AutoCompileCustom
                          disabled={props.disabled}
                          label={`${labels.LEGAL_SEAT}*`}
                          id="location"
                          filter={props.relationshipLocations}
                          value={values.location}
                          error={errors.location}
                          touched={props.touched}
                          handleInputAutocompile={(e) => handleInputAutocompile(e)}
                        />
                      </div>
                      <div className={`${sizesClass['tab-rel'].province}`}>
                        <ProvinceField
                          disabled={props.disabled}
                          label={`${labels.PROVINCE}*`}
                          placeholder={`${labels.EE_FOR_FOREIGN_COUNTRY}`}
                          province={values.province}
                          error={errors.province}
                          touched={props.touched}
                          onChange={(e) =>
                            Utility.handleChangeValue(
                              e.target.value,
                              'province',
                              values,
                              setValues,
                              true,
                              true
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="infoPf"
                    className={`${sizesClass['tab-rel'].legalPersonType} ${values.personType === Constants.PF ? '' : ' d-none'}`}>
                    <div className="form-row col-12 mb-2">
                      <div className={`${sizesClass['tab-rel'].fiscalCode} pe-2`}>
                        <BwmInput
                          disabled={props.disabled}
                          name="fiscalCode"
                          label={`${labels.FISCAL_CODE}`}
                          className="form-control"
                          maxLength="16"
                          value={values.fiscalCode}
                          error={errors.fiscalCode || errors.errorFiscalCode}
                          touched={props.touched}
                          onBlur={(e) => handleFiscalCode(e.target.value)}
                          onChange={(e) =>
                            Utility.handleChangeValue(
                              e.target.value,
                              'fiscalCode',
                              values,
                              setValues,
                              true,
                              true
                            )
                          }
                        />
                      </div>

                      <div className={`${sizesClass['tab-rel'].lastName} pe-2`}>
                        <BwmInput
                          disabled={props.disabled}
                          name="lastName"
                          label={`${labels.LAST_NAME}*`}
                          className="form-control font-weight-bold font-size-big"
                          maxLength="26"
                          value={values.lastName}
                          error={errors.lastName}
                          touched={props.touched}
                          onChange={(e) =>
                            Utility.handleChangeValue(
                              e.target.value,
                              'lastName',
                              values,
                              setValues,
                              true
                            )
                          }
                        />
                      </div>
                      <div className={`${sizesClass['tab-rel'].firstName} pe-2`}>
                        <BwmInput
                          disabled={props.disabled}
                          name="firstName"
                          label={`${labels.FIRST_NAME}*`}
                          className="form-control font-weight-bold font-size-big"
                          maxLength="25"
                          value={values.firstName}
                          error={errors.firstName}
                          touched={props.touched}
                          onChange={(e) =>
                            Utility.handleChangeValue(
                              e.target.value,
                              'firstName',
                              values,
                              setValues,
                              true
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="form-row col-12">
                      <div className={`${sizesClass['tab-rel'].gender} pe-2`}>
                        <BwmSelect
                          disabled={props.disabled}
                          options={Constants.genderList}
                          name="gender"
                          allowEmpty={false}
                          label={`${labels.GENDER}*`}
                          className="form-control"
                          value={values.gender}
                          error={errors.gender}
                          touched={props.touched}
                          onChange={(e) =>
                            Utility.handleChangeValue(
                              e.target.value,
                              'gender',
                              values,
                              setValues,
                              true
                            )
                          }
                        />
                      </div>
                      <div className={`${sizesClass['tab-rel'].birthDate} pe-2`}>
                        <DateField
                          disabled={props.disabled}
                          name={'birthDate'}
                          label={`${labels.BIRTH_DATE}*`}
                          customOptions={{ maxDate: new Date() }}
                          date={values.birthDate}
                          error={errors.birthDate}
                          touched={props.touched}
                          onChange={(date) =>
                            Utility.handleChangeValue(date, 'birthDate', values, setValues)
                          }
                        />
                      </div>
                      <div className={`${sizesClass['tab-rel'].location} pe-2`}>
                        <AutoCompileCustom
                          disabled={props.disabled}
                          label={`${labels.BIRTH_PLACE}*`}
                          id="location"
                          filter={props.relationshipLocations}
                          value={values.location}
                          error={errors.location}
                          touched={props.touched}
                          handleInputAutocompile={(e) => handleInputAutocompile(e)}
                        />
                      </div>
                      <div className={`${sizesClass['tab-rel'].province}`}>
                        <ProvinceField
                          disabled={props.disabled}
                          label={`${labels.PROVINCE}*`}
                          placeholder={`${labels.EE_FOR_FOREIGN_COUNTRY}`}
                          province={values.province}
                          error={errors.province}
                          touched={props.touched}
                          onChange={(e) =>
                            Utility.handleChangeValue(
                              e.target.value,
                              'province',
                              values,
                              setValues,
                              true,
                              true
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <FloatingLabel
                      title={labels.NOTE}
                      className={`${sizesClass['tab-rel'].note}`}
                      label={`${labels.NOTE}`}>
                      <textarea
                        disabled={props.disabled}
                        name="comment"
                        className={'form-control custom-text-area'}
                        rows={values.showDescription ? 2 : 4}
                        cols={12}
                        value={values.comment}
                        maxLength={255}
                        onChange={(e) =>
                          Utility.handleChangeValue(e.target.value, 'comment', values, setValues)
                        }
                      />
                    </FloatingLabel>
                    {values.showDescription && (
                      <div className={`${sizesClass['tab-rel'].description} mt-2`}>
                        <BwmInput
                          disabled={props.disabled}
                          name="description"
                          label={`${labels.DESCRIPTION}*`}
                          className="form-control"
                          maxLength="24"
                          value={values.description}
                          error={errors.description}
                          touched={props.touched}
                          onChange={(e) =>
                            Utility.handleChangeValue(
                              e.target.value,
                              'description',
                              values,
                              setValues,
                              true
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div id="tab-tit" className="d-none" label={SECOND_TAB}>
                <DelegateForm
                  sizes={sizesClass['tab-tit']}
                  disabled={props.disabled}
                  key={props.index}
                  relationship={values}
                  startDate={values.startDate}
                  endDate={values.endDate}
                  relationshipProvinces={props.relationshipProvinces}
                  relationshipLocations={props.relationshipLocations}
                  roles={props.roles}
                  subjectId={props.subjectId}
                  getRelationships={props.getRelationships}
                  updateDelegates={(delegatesArr) => {
                    values.coraRelationDelegates = delegatesArr
                    values.canceledAndCommunicated = false
                    setValues(values)
                  }}
                />
              </div>
              <div id="tab-saldi" className="d-none" label={THIRD_TAB}>
                <BalanceForm
                  sizes={sizesClass['tab-saldi']}
                  disabled={props.disabled}
                  key={props.index}
                  relationship={values}
                  currencies={props.currencies}
                  subjectId={props.subjectId}
                  onSave={() => {
                    values.canceledAndCommunicated = false
                    setValues(values)
                  }}
                />
              </div>
            </Tabs>
          </div>
        </div>
      </div>
      <div className="form-row mt-2 row align-item-center">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <button
              disabled={props.disabled}
              type="button"
              className="btn btn-outline-primary me-4"
              onClick={() => close(true)}>
              <i className="thx-cancel thx-icon me-2" />
              {labels.CANCEL}
            </button>
            {/* Vista sulla destra */}
            {props.paginationComponent && (
              <div className="d-flex justify-content-end">{props.paginationComponent}</div>
            )}
            {window.innerWidth > Constants.BREAKPOINT_LG && (
              <div className="ms-4">
                <b className="me-2">*</b>
                <span>{labels.REQUIRED_FIELD_FOR_FORM_COMPLETION}</span>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <button
              disabled={props.disabled}
              type="button"
              className={'btn btn-outline-primary me-2'}
              hidden={!props.subjectId}
              onClick={() => prepareClonedRelationship()}>
              <i className="thx-copy thx-icon me-2" />
              {labels.CLONE_RELATIONSHIP}
            </button>
            <button
              disabled={props.disabled}
              type="submit"
              onClick={() => handleFormSubmit()}
              className="btn btn-primary">
              <i className="thx-floppy-disk thx-icon me-2" />
              {labels.SAVE}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
