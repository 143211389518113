import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { sendEncryptedCredentialsToAdmin } from '../../actions'
import getText from '../shared/i18n/labels'

// FORM
import { BwmInput } from '../shared/form/BwmInputB5'

// COMPONENTS
import { PopupError } from '../shared/popups/PopupError'
import { InfoBox } from '../shared/InfoBox'
import { notifySuccess } from '../shared/Utility'

export function AssistanceLogin() {
  const auth = useSelector((state) => state.auth)
  const lang = useSelector((state) => state.language.language)
  const labels = getText(lang)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [type, setType] = useState('password')

  const onClickIconPsw = (e, id) => {
    e.preventDefault()
    let x = document.getElementById(id)
    if (x !== null) {
      if (x.type === 'password') {
        x.type = 'text'
        setType('text')
      } else {
        x.type = 'password'
        setType('password')
      }
    }
  }

  const handleSubmit = () => {
    sendEncryptedCredentialsToAdmin(password).then(
      (res) => {
        notifySuccess(labels.SAVED_SUCCESSFULLY)
      },
      (err) => {
        PopupError({ text: labels.ERROR_SENDING_DATA })
      }
    )
  }

  const handlerPassword = (value) => {
    setPassword(value)
  }

  useEffect(() => {
    setUsername(auth?.customer?.user?.login)
  }, [auth])

  return (
    <div className="login mt-4">
      <div className="content-login">
        <div className="body-login p-3">
          <div className="form-login">
            <p className="benvenuto-inserisci">
              <b>{labels.SEND_CREDENTIALS_TO_ASSISTANCE}</b>
            </p>
            <div className="mt-3 mb-5">
              <div className="col-12 mb-3">
                <BwmInput
                  name="username"
                  type="text"
                  label="Email"
                  className="form-control"
                  value={username}
                  disabled
                />
              </div>
              <div className="col-12 mb-4">
                <BwmInput
                  id="loginPsw"
                  name="password"
                  type="password"
                  label="Password"
                  className="form-control"
                  value={password}
                  Icon={() => (
                    <div
                      className="d-flex align-self-center"
                      onClick={(e) => onClickIconPsw(e, 'loginPsw')}>
                      <i
                        className={`thx-icon bg-primary ${type === 'text' ? 'thx-eye' : 'thx-eye-off'}`}
                      />
                    </div>
                  )}
                  onChange={(e) => handlerPassword(e.target.value)}
                />
              </div>
              <div className="col-12">
                <InfoBox
                  additionalClasses={'align-self-center'}
                  simplePointsArr={[
                    {
                      title: '',
                      text: labels.SEND_CREDENTIALS_TO_ASSISTANCE_INFO
                    }
                  ]}
                />
              </div>
            </div>
            <div className="w-100">
              <button
                disabled={!password}
                className="btn btn-primary btn-lg w-100"
                onClick={handleSubmit}>
                {labels.SEND}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
